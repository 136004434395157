.add-product-container {
}
.stationary-modal {
    .checkbox-row {
        .ant-checkbox-group {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            font-variant: tabular-nums;
            line-height: 1.5715;
            list-style: none;
            font-feature-settings: "tnum", "tnum";
            display: flex;
            flex-direction: column;
            justify-content: center;
            row-gap: 10px;
        }
    }
    .ant-modal-body {
        padding: 24px;
        font-size: 14px;
        line-height: 1.5715;
        word-wrap: break-word;
        height: 260px;
        overflow: scroll;
    }
}
.price-states-container {
    width: 64%;
    // height: 469px;
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 4px;
    // padding-bottom: 40px;
    .ant-form-item {
        margin: 13px;
    }
    .price-states-form-row {
        input {
            width: 100%;
            min-height: 40px;
            background: #f7f7f7;
            border-radius: 5px;
            font-weight: 400;
            font-size: 14px;
            line-height: 128%;
            color: #211f1f;
            border: 1px solid #f7f7f7;
        }
    }
}

.state-price-col {
    width: auto;
    height: 45px;

    background: #f3f6f9;
    border-radius: 4px 4px 0px 0px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */

    letter-spacing: 0.03em;

    color: #707485;
    display: flex;
    align-items: center;
}
