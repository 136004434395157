@import "../../commonStyles/media-query.scss";
.navbar {
  background-image: url(../../assests/images/head-bg.svg);
  background-size: cover;
  background-color: white;
  padding-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  // display: flex;
  // align-items: center;

  @include responsive(small) {
    width: 100%;
  }
  button {
    //margin: 13px 22px;
    background: transparent;
    border: none;
  }
  .header {
    .header-content {
      padding: 0 2.5rem 0 2.5rem;
      @include responsive(small) {
        padding: 0 0.5rem 0 0.5rem;
      }
      .ant-row {
        @include responsive(tablet) {
          justify-content: center;
        }
      }
    }
  }
}
.title-container {
  align-items: center;

  button {
    border: none;
  }
  :hover {
    background-color: transparent;
  }
}

// .ant-drawer-content-wrapper {
//   .ant-drawer-content {
//     background: #ebf1fa;
//     ul {
//       background: #ebf1fa;
//     }
//   }
// }
@media (min-width: 992px) {
  // .menu {
  //   display: none;
  // }
}
.logo {
  height: 32px;
  // margin-left: 1rem;
}

.menu-drawer {
  .ant-drawer-content {
    background: #ebf1fa;
    box-shadow: inset -4px 2px 4px rgb(99 126 165 / 15%);

    ul {
      border-bottom: 2px solid rgb(216, 220, 234);
      background: #ebf1fa;
      li {
        margin: 17px 0;
        padding-left: 20px !important;
        .ant-menu-title-content {
          margin-left: 35px;
        }
      }
    }
  }
  .ant-menu-inline .ant-menu-item::after {
    border-right: none !important;
  }
}

.menu {
  background-color: transparent;
}

.ant-menu-item-selected {
  background-color: none !important;
}
