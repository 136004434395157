@import "../../../commonStyles//button.scss";

.product-container {
  .search-field {
    border-top: 1px solid #dddddd;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .product-name-col {
    display: flex;
    align-items: center;
    img {
      width: 50px;
      height: 50px;
      border: 1px solid;
      margin-right: 15px;
    }
  }
  .btn-add-category {
    width: 141px;
    height: 36px;

    background: #2e77d0;
    border-radius: 5px;
    color: #ffffff;
  }
  .category-table-container {
    overflow-x: scroll;
    background-color: white;
    box-shadow: 0px 1px 4px rgb(107, 107, 107, 0.25);
    border-radius: 2px;
  }
  .category-container-form {
  }
  .ant-col-16 {
    // display: block;
    flex: 0 0 66.66666667%;
    max-width: 111.666667% !important;
  }

  .category-container-publish {
    .ant-form-vertical .ant-form-item-row {
      flex-direction: row;
    }
  }
  // .ant-form-vertical .ant-form-item-row {
  //     display: flex;
  //     flex-direction: row;
  //     flex-wrap: nowrap;
  // }
  .filter-input {
    height: 40px;
    width: 100%;
    background: #f7f7f7;
    border-radius: 5px;
    .ant-select-selector,
    Input,
    textarea {
      width: 100%;
      min-height: 39px;
      background: #f7f7f7;
      border-radius: 5px;
      font-weight: 400;
      font-size: 14px;
      line-height: 128%;
      color: #211f1f;
      border: 1px solid #f7f7f7;
    }
  }
  .ant-switch-checked {
    background: #2cac66 !important;
  }
}

.tooltip-background {
  border-radius: 50%;
  background: #a2cbfd;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .text-style {
    font-size: 8px;
    line-height: 9px;
    font-weight: 500;

    color: #212529;
  }
}
