.attachment-container{
    .ant-carousel{
        .slick-slider{
            .slick-dots{
                li{
                    button{
                        background: black;
                    }
                }
            }
        }
    }
}