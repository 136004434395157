@import "../../../commonStyles/media-query.scss";
// .hub-table-container {
//     padding: 20px;
//     background: #f8f9fa;
//     border-radius: 8px;
//   }
  
//   .styled-table .ant-table-thead > tr > th {
//     background: #1890ff !important;
//     color: white !important;
//     text-align: center;
//   }
  
//   .styled-table .ant-table-tbody > tr > td {
//     text-align: center;
//     vertical-align: middle;
//   }
  


  .hub-table-container {
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(107, 107, 107, 0.25);
    border-radius: 5px;
    overflow-x: scroll;
    .filter-select {
      .ant-select-selector {
        height: 39px !important;
      }
    }
    .new-header {
      font-weight: 700;
      font-size: 16px;
      line-height: 32px;
      letter-spacing: -0.25px;
      color: #061624;
      padding: 1rem;
      border-bottom: 1px solid #dddddd;
      // display: flex;
      // justify-content: space-between;
      // align-items: center;
      .right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .ant-btn {
          margin-left: 10px;
          display: flex;
          align-items: center;
          background: #ffffff;
          border: 1px solid #2e77d0;
          border-radius: 4px;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: #2e77d0;
  
          img {
            margin-right: 5px;
          }
        }
      }
    }
    .custom-switch {
      transition: all 0.3s ease-in-out;
    }
    
    .switch-active .ant-switch {
      background-color: #52c41a !important; /* Green */
    }
    
    .switch-inactive .ant-switch {
      background-color: #ff4d4f !important; /* Red */
    }
  }