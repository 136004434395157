@import "../../../commonStyles/font.scss";
.container {
  background-color: white;
  // height: 750px;
  margin: 30px;
  padding-bottom: 30px;
  .print-btn {
    width: 800px;
    margin: auto;
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
    cursor: pointer;
    .ant-btn-default {
      height: 30px;
      background: #ffffff;
      border: 1px solid #2e77d0;
      border-radius: 4px;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #2e77d0;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        margin-right: 9px;
      }
    }
  }
  .container-header {
    font-weight: 700;
    font-size: 16px;
    padding: 10px;
    color: #061624;
  }
  .container-header-line {
    width: 100%;
    height: 0px;
    left: 104px;
    top: 175px;
    border: 1px solid #dddddd;
  }
  .container-flex {
    display: flex;
    justify-content: center;
    padding-top: 0;
    .container-contain {
      margin-top: 20px;
      width: 800px;
      // height: 674px;
      left: 320px;
      top: 198px;

      background: #ffffff;
      border: 1px solid #dddddd;
      .container-contain-div {
        padding: 20px 20px;
        .container-contain-pringIcon {
          text-align: end;
        }
      }
      .container-contain-table {
        padding: 0 20px;
        margin-bottom: 3%;
        .ant-col-24 {
          margin-bottom: 15px;
        }
        .ant-table-title {
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #212529;
        }
        .ant-table-thead {
          .ant-table-cell {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            /* identical to box height, or 171% */
            color: #212529;
          }
        }
        .non-dox-child {
          .ant-table-tbody {
            .d-flex {
              display: flex;
              flex-direction: column;
            }
          }
        }
        .dox-recept-table {
          .ant-table-tbody {
            .dox-flex {
              display: flex;
              flex-direction: column;
            }
          }
        }
      }
    }
    .container-contain-dateandtime {
      font-weight: 500;
      font-size: 13px;
      padding: 30px 20px;
      letter-spacing: -0.25px;
      display: flex;
      align-items: center;

      color: #000000;
      .container-contain-dateandtime-heading {
        font-weight: 400;
        font-size: 13px;

        /* identical to box height, or 185% */

        color: #212529;
      }
      .container-contain-dateandtime-button {
        background: #f3faff;
        border: 1px solid #c6dbed;
        border-radius: 3px;
        font-weight: 500;
        font-size: 13px;
        letter-spacing: -0.25px;

        color: #2e77d0;
      }
    }
  }
}

.address {
  padding: 0px 20px;

  .address-header {
    font-weight: 500;
    font-size: 13px;
    /* identical to box height, or 154% */

    letter-spacing: -0.25px;

    color: #000000;
  }
  .address-desc {
    font-weight: 400;
    font-size: 13px;
    /* or 154% */
    padding-left: 15px;
    letter-spacing: -0.25px;

    color: #666666;
  }
}
