.ant-input-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 0px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-image: none;
  border-radius: 5px;
  transition: all 0.3s;
  display: inline-flex;
  background: #f7f7f7;
}
