.account-address-container .account-address-container-box {
  background: #fbfbfb;
  border-radius: 5px;
  box-shadow: 0 1px 4px hsla(0, 0%, 42%, 0.25);
}

.credit-container {
  .global-form {
    .global-form-row .ant-form-item {
      margin-bottom: 5px !important;
    }

    // .ellipsis {
    //   white-space: nowrap;
    //   overflow: hidden;
    //   text-overflow: ellipsis;
    //   display: block;
    // }

    .global-form-row .ant-select-selector,
    .global-form-row Input,
    .global-form-row textarea {
      width: 100%;
      min-height: 35px;
      // background: #f7f7f7;
      // border-radius: 5px;
      font-weight: 400;
      font-size: 12px;
      line-height: 128%;
      color: #211f1f;
      // border: 1px solid #f7f7f7;
      border-radius: 5px;

      background: white;
      border: 1px solid #919191;
    }

    .ant-input-affix-wrapper {
      input {
        border: none;
      }
      background: white;
      border: 1px solid #919191;
    }

    .ant-input-affix-wrapper > .ant-input:not(textarea) {
      background: white;
    }
  }
  .ant-form-vertical .ant-form-item-label,
  .ant-col-24.ant-form-item-label,
  .ant-col-xl-24.ant-form-item-label {
    padding: 0px 0 0px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }

  .ant-form-item-label > label {
    position: relative;
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    height: 32px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 13px;
  }

  .ant-empty-normal {
    margin: 10px;
  }
}

.account-tab-container {
  // .global-form .global-form-row Input {
  //     font-size: 12px;
  //     min-height: 35px;
  // }
  // .ant-form-item-label > label {
  //     position: relative;
  //     display: inline-flex;
  //     align-items: center;
  //     max-width: 100%;
  //     height: 32px;
  //     color: rgba(0, 0, 0, 0.85);
  //     font-size: 12px;
  // }
  // .ant-select-selection-item {
  //     flex: 1 1;
  //     overflow: hidden;
  //     font-weight: normal;
  //     white-space: nowrap;
  //     text-overflow: ellipsis;
  //     font-size: 12px;
  // }
  // .global-form .global-form-row .ant-form-item-explain-error {
  //     font-size: 12px;
  // }
  // .ant-form-vertical .ant-form-item-label,
  // .ant-col-24.ant-form-item-label,
  // .ant-col-xl-24.ant-form-item-label {
  //     padding: 0 0 2px;
  // }
}

.upload_and_dragger {
  .one-row {
    display: flex;
    margin-left: 6px;
    .ant-select-single {
      margin-right: 10px;
      width: 300px;
      height: 40px;
      .ant-select-selector {
        display: flex;
        align-items: center;
        height: 40px;
        background: #f7f7f7;
        border-radius: 5px;
      }
      .ant-select-arrow {
        // top: 28%;
      }
    }
    .dragger {
      width: 30%;
      height: 40px;
      background: #f7f7f7;
      border: 1px dashed #dddddd;
      border-radius: 5px;
      .ant-upload {
        padding: 0;
      }
      .vline {
        border-left: 1px solid #cecece;
      }
      .ant-col-17 {
        p {
          text-align: left;
        }
      }
    }
    .ant-upload-list {
      display: flex;
      // position: relative;
      // right: 310px;
      flex-wrap: wrap;
      width: 590px;
      .ant-upload-list-item {
        width: 100px;
        height: 80px;
        background: #fdfdfd;
        border: 1px solid #e1e1e1;
        border-radius: 5px;
        margin-right: 15px;
        .ant-upload-list-item-card-actions-btn {
          margin-bottom: 50px;
        }
        .ant-upload-list-item-name {
          padding-top: 55px;
          z-index: 2;
          margin-left: -56px;
          cursor: pointer;
        }
        .ant-upload-list-item-thumbnail {
          margin-left: 16px;
        }
        .ant-upload-list-item-done
          .ant-upload-list-item-list-type-picture
          :focus {
          border: 2px solid #1890ff !important;
        }
      }
    }
    .ant-tooltip-inner {
      display: none;
    }
    .ant-tooltip-arrow {
      display: none;
    }
  }
}

.dashboard-container-card-div {
  margin-top: 13px;
  width: 100%;
  box-shadow: 0px 0px 1px 1px rgba(107, 107, 107, 0.06);
  border-radius: 10px;
  border-top: 40px;
  border-left: 40px;
  border-right: 40px;
  background-color: #f4f8ff;
}

.eway-Child-Active {
  background-color: #e8fff3;
  color: #172360;
  border: 1px solid #50cd89;
  border-radius: 5px;
  width: 100%;
}
.eway-Child-InActive {
  background-color: #f3f6fa;
  color: #172360;
  border: 1px solid #dfe3ea;
  border-radius: 5px;
  width: 100%;
}

.card-container {
  .ant-card-small > .ant-card-body {
    padding: 11px 19px;
  }

  .ant-upload-list-picture .ant-upload-list-item:focus {
    border-color: 2px solid !important;
  }
}
