.lcr-container-cntr {
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(107, 107, 107, 0.25);
    border-radius: 5px;
    overflow-x: scroll;

    .ant-picker {
        padding: 8px;
        border-radius: 5px;
    }
    .header {
        font-weight: 700;
        font-size: 16px;
        line-height: 32px;
        letter-spacing: -0.25px;
        color: #061624;
        padding: 1rem;
        border-bottom: 1px solid #dddddd;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .right {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .ant-btn {
                margin-left: 10px;
                display: flex;
                align-items: center;
                background: #ffffff;
                border: 1px solid #2e77d0;
                border-radius: 4px;
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 1px;
                text-transform: uppercase;
                color: #2e77d0;

                img {
                    margin-right: 5px;
                }
            }
        }
    }
}
// .fitlerDesing {
//     // border-radius: 10px;
//     // padding: 10px 10px 10px 10px;
//     .ant-table-filter-dropdown {
//         border-radius: 10px;
//         padding: 0px 0px 0px 0px;
//     }
// }

.ant-table-filter-dropdown {
    border-radius: 10px;
    padding: 15px;
}
.bg-col-container {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    width: 100px;
    max-width: 100px; /* Adjust width as needed */
    // border: 1px solid #ddd;
    // padding: 5px;
}
