@import "../../../commonStyles/media-query.scss";
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: black !important;
}
.ant-pagination-prev {
  padding: 0;
  border: none;
  border-radius: 5px;
}
.ant-pagination-next {
  padding: 0;
  border: none;
  border-radius: 5px;
}
.bagging-shipment {
  .lcr-table-container {
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(107, 107, 107, 0.25);
    border-radius: 5px;
    padding-top: 1rem;
    .ant-table {
      overflow-x: scroll;
    }
    .align-right {
      justify-content: right;
    }
  }
}
.lcr-table-list-container {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(107, 107, 107, 0.25);
  border-radius: 5px;
  padding-top: 1rem;
  .align-right {
    justify-content: right;
  }
}

.print-lcr-container {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(107, 107, 107, 0.25);
  border-radius: 5px;
  padding-bottom: 2rem;
  .heading-lcr {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #061624;
    border-bottom: 1px solid #dddddd;
  }
  p {
    margin: 0;
  }
  .lcr-report-wrapper {
    .download-btn {
      display: flex;
      justify-content: right;
      align-items: center;
      border: none;
      width: 70%;
      margin: auto;
      padding: 30px 0;
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 91px;
        height: 30px;
        background: #ffffff;
        border: 1px solid #2e77d0;
        border-radius: 4px;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #2e77d0;
        img {
          margin-right: 5px;
        }
      }
    }
    .lcr-report {
      margin: auto;
      width: 70%;
      height: 1008px;
      background: #ffffff;
      border: 1px solid #dddddd;
      hr {
        border: 1px solid #dddddd;
      }
      .ant-table-wrapper {
        max-width: 95%;
        margin: auto;
        border-radius: 0%;
        th {
          border-top: 2px solid #dddddd;
          border-bottom: 2px solid #dddddd;
        }
      }
    }
  }
}

.lcr-modal-wrapper {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(107, 107, 107, 0.25);
  border-radius: 5px;
  .bag-details-box {
    border: 1px solid;
    width: 100%;
    height: 400px;
  }
  .eway-update {
    border: 1px solid #dddddd;
    border-radius: 10px;
  }
  .divider {
    margin: inherit;
  }
  .ant-modal-content {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(193, 193, 193, 0.25);
    border-radius: 10px;
  }
  .ant-modal-header {
    border-radius: 10px;
  }
  .ant-select-selector {
    height: 40px !important;
    background: #f7f7f7 !important;
    border-radius: 5px !important;
    border: none !important;
  }
  .ant-select-selection-item {
    background: #f7f7f7 !important;
  }
  .ant-input {
    height: 40px !important;
    background: #f7f7f7 !important;
    border-radius: 5px !important;
    border: none !important;
  }
  .ant-picker {
    width: 100%;
    height: 40px !important;
    background: #f7f7f7 !important;
    border-radius: 5px !important;
    border: none !important;
  }
  .ant-modal-body {
    padding-bottom: 0;
  }
  .bagOutscanCont {
    border: 1px solid #dddddd;
    border-radius: 10px;
    margin-bottom: 10px;
    .table-head {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      border-top: 1px solid #dddddd;
      border-bottom: 1px solid #dddddd;
      margin-bottom: 5px;
      background: #ebf1fa;
    }
    .table-col-wrap {
      height: 140px;
      overflow-y: scroll;
      .table-column {
        padding: 10px;
        p {
          margin: 0;
        }
        img {
          margin-left: 5px;
        }
      }
    }
  }
  .bagOutscanContView {
    border: 1px solid #dddddd;
    border-radius: 10px;
    margin-bottom: 10px;
    .table-head {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      border-top: 1px solid #dddddd;
      border-bottom: 1px solid #dddddd;
      margin-bottom: 5px;
      background: #ebf1fa;
    }
    .table-col-wrap {
      overflow-y: scroll;
      .table-column {
        padding: 10px;
        p {
          margin: 0;
        }
        img {
          margin-left: 5px;
        }
      }
    }
  }
}
.timepicker-modal {
  margin-top: 100px;
  .ant-modal-body {
    .ant-picker {
      width: 250px;
      border-radius: 5px;
    }
  }
}

.trip-schedule-modal-wrapper {
  width: 65% !important;
  margin-top: 100px;
  .card {
    border-radius: 5px;
    border: 0.5px solid rgba(0, 0, 0, 0.05);
    margin-top: 10px;
    padding: 5px;
    &:hover {
      filter: brightness(98%);
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      transition: 0.5s;
    }
  }
  .ant-select {
    .ant-select-selection-item {
      background: #f5f5f5 !important;
    }
  }
  .ant-picker-input > input[disabled] {
    color: rgba(0, 0, 0, 0.85);
  }
}

.cancel-bt {
  // border-radius: 4px;
  padding: 0 1rem;
  font-size: 12px;
  font-weight: 600;
  height: 35px;
  // border: 1px solid #2e77d0;
  background: #fff;
  color: #2e77d0;
  min-width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 1px;
}

.disabled-div {
  pointer-events: none;
  cursor: not-allowed;
}
.consolidated-tab-cntr .ant-picker {
  padding: 8px;
  border-radius: 5px;
}
