.formIcon {
  margin: auto;
  margin-bottom: 25px;
}

.form-lables {
  margin-top: 10px;
  margin-bottom: 60px;
}

.right {
  margin-top: 0px;
}

.ant-form-item {
  margin-bottom: 6px;
}
