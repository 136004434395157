.lead-gen-list-container {
}

.lead-list-status {
  border-radius: 5px;
  text-align: center;
  padding: 7px 0;
}

.send-mail-container {
  border-radius: "20px";

  .ant-input-suffix {
    color: #6c6c6c;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
}
.send-mail-input {
  border: none;
  border-bottom: 1px solid #dddddd;
}
