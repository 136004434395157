@import "../../../commonStyles//button.scss";

.category-container {
    .btn-add-category {
        width: 141px;
        height: 36px;

        background: #2e77d0;
        border-radius: 5px;
        color: #ffffff;
        margin-bottom: 5px;
    }
    .category-table-container {
        padding: 25px 20px;
        background-color: white;
        box-shadow: 0px 1px 4px rgb(107, 107, 107, 0.25);
        border-radius: 2px;
    }
    .category-container-form {
    }
    .ant-col-16 {
        // display: block;
        flex: 0 0 66.66666667%;
        max-width: 111.666667% !important;
    }

    .category-container-publish {
        .ant-form-vertical .ant-form-item-row {
            flex-direction: row;
        }
    }
    // .ant-form-vertical .ant-form-item-row {
    //     display: flex;
    //     flex-direction: row;
    //     flex-wrap: nowrap;
    // }

    .ant-switch-checked {
        background: #2cac66 !important;
    }
}
