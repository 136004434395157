.billing-configuration {
    .billing-configuration-layout {
        width: 100%;
        // height: 1601px;
        // padding-bottom: 10%;
        flex-shrink: 0;
        // fill: #fff;
        // filter: drop-shadow(0px 1px 4px rgba(107, 107, 107, 0.25));
        background-color: #fff;
    }
    .ant-switch-checked {
        background-color: #2cac66;
    }
    .billing-configuration-container {
        width: 80%;
        .billing-configuration-container-box {
            border-radius: 5px;
            border: 1px solid #ddd;
            background: #fff;
            // width: 848px;
            // height: 196px;
            margin-top: 10px;
            .billing-configuration-container-box-header {
                border-radius: 5px 5px 0px 0px;
                border: 0px solid #ddd;
                background: #f3f6f9;
                width: 100%;
                height: 50px;
                // flex-shrink: 0;
                color: #707485;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                display: flex;
                align-items: center;
                align-self: flex-start;
                padding-left: 9px;
            }
            .billing-configuration-container-box-container {
                .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
                    display: inline-block;
                    margin-right: 4px;
                    color: #ff4d4f;
                    font-size: 14px;
                    font-family: SimSun, sans-serif;
                    line-height: 1;
                    content: "*";
                    display: none;
                }
                // padding: 40px 0;
            }
            .billing-configuration-container-box-label {
                color: #061624;
                font-size: 14px;
                font-weight: 400;
            }
        }
        .billing-configuration-container-piece-heading {
            color: #202124;

            font-size: 15px;
            font-weight: 600;
        }
        .billing-configuration-container-piece-label {
            color: #202124;

            font-size: 14px;
            font-weight: 600;
            line-height: 128%;
        }
        .vertical-line {
            border-left: 1px solid #dddddd;
            height: 100%;
        }
    }
}
.line {
    margin: 5px 0;
    height: 1px;
    background: repeating-linear-gradient(90deg, #ddd 0 5px, #0000 0 7px);
    width: 100%;
    /*5px red then 2px transparent -> repeat this!*/
}
