.packeting-guide-container {
  .master-title-heading {
    color: #061624;
    font-weight: 700;
    font-size: 18px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    span {
      padding-left: 0.5rem;
    }
  }
  .packeting-guid-table {
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(107, 107, 107, 0.25);
    border-radius: 5px;
    .ant-pagination {
      margin-right: 13px;
    }
    .packeting-button-grp {
      display: flex;
      justify-content: flex-end;
      padding: 16px;
      .ant-space-vertical {
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
      .ant-space-item {
        .ant-input-affix-wrapper {
          width: 375px !important;
        }
        .ant-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            margin-right: 9px;
          }
        }
        .ant-btn-default {
          height: 34px;
          background: #ffffff;
          border: 1px solid #2e77d0;
          border-radius: 4px;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          text-transform: uppercase;
          color: #2e77d0;
        }
        .ant-btn-primary {
          height: 35px;
          background: #2e77d0;
          border-radius: 4px;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: #ffffff;
        }
      }
    }
  }
  padding: 2rem;
  .ant-table {
    .ant-table-thead {
      background: #f3f6f9;
      .ant-table-cell {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.25px;
        color: #707485;
      }
    }
    .ant-table-tbody {
      border-bottom: 1px solid #e9ebec;
      .ant-table-cell {
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: -0.25px;
        color: #212529;
        .edit-button {
          border: none;
          background: none;
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .packeting-count-wrapper {
        // width: 200px;
        display: flex;
        align-items: center;
        .ant-row {
          overflow: hidden;
        }
        .packeting-count {
          padding: 0;
          width: 22px;
          height: 22px;
          border-radius: 50%;
          background: #a2cbfd;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          font-size: 8px;
          line-height: 9px;
          letter-spacing: -0.25px;
          color: #212529;
          margin-left: 10px;
        }
      }
    }
  }
}

.packet-config-modal {
  .max-pkt-container {
    margin-bottom: 35px;
  }
  .ant-row {
    justify-content: center;
    .ant-form-item {
      .ant-form-item-label {
        width: 275px;
        text-align: left;
        font-weight: 400;
        font-size: 14px;
        line-height: 128%;
        color: #061624;
      }
    }
  }
  .footer-btn {
    padding-bottom: 0;
    .btn-one {
      width: 130px;
      height: 40px;
    }
    .btn-two {
      width: 100px;
      height: 40px;
      margin-left: 10px;
    }
  }

  .ant-modal-footer {
    .ant-btn-default {
      width: 140px;
      height: 40px;
      border: 1px solid #2e77d0;
      border-radius: 4px;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #2e77d0;
    }
    .ant-btn-primary {
      width: 140px;
      height: 40px;
      background: #2e77d0;
      border-radius: 4px;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #ffffff;
    }
  }
}

.packeting-guid-list-modal {
  .ant-modal-body {
    padding-left: 0;
    padding-right: 0;
    .ant-table {
      height: 250px;
      overflow: scroll;
    }
  }
}
.edit-packeting-container {
  .ant-row {
    margin-top: 10px;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
.edit-packet-mapping-modal {
  width: 900px !important;
}
