@import "../../../commonStyles/media-query.scss";
@import "../../../commonStyles/font.scss";
@import "../../../commonStyles/button.scss";
.steps-container-cp {
  font-family: $default-font-family;
  font-style: $default-font-style;
  width: 94%;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(107, 107, 107, 0.25);
  border-radius: 5px;
  margin: 0 auto 20px auto;
  @include responsive(small) {
    font-size: 16px;
    width: 90%;
  }

  .steps {
    width: 90%;
    margin: auto;
    padding-top: 46px;
    padding-bottom: 25px;
    background: white;
    @include responsive(small) {
      flex-direction: row;
      margin: 0;
      width: 100%;
    }
    .ant-steps {
      background-color: white;
    }
    .ant-steps-item-custom {
      @include responsive(small) {
        width: 20%;
      }
    }
    .ant-steps-item-container {
      @include responsive(small) {
        display: flex;
        flex-direction: column;
      }
      .ant-steps-item-tail::after {
        width: 120%;
        @include responsive(small) {
          width: 65px !important;
        }
      }
      .ant-steps-item-tail {
        margin-left: 45px;
        @include responsive(small) {
          position: absolute;
          top: -23px;
          left: 71px;
          width: 1px;
          height: 41%;
          padding: 38px 0 6px;
          margin-left: 0px;
        }
      }
      .ant-steps-item-content {
        .ant-steps-item-title {
          @include responsive(small) {
            width: 57px;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            letter-spacing: -0.25px;
            color: #374e62;
          }
        }
      }
      .ant-steps-item-title {
        @include responsive(small) {
        }
      }
    }
    .ant-steps-item-content .ant-steps-item-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 32px;
      letter-spacing: -0.25px;
      // color: #374e62;
    }
    .ant-steps-item-custom {
      svg {
        path {
          fill: #b5b6b7;
        }
        circle {
          fill: white !important;
          // stroke: none;
          //   stroke: 1px red solid !important;
        }
      }
    }
    .ant-steps-item-active,
    .ant-steps-item-finish {
      // color: red;
      border: none;
      svg {
        path {
          fill: white;
        }
        circle {
          fill: #2e77d0 !important;
          // stroke: none;
        }
      }
    }
  }
  .steps-content {
    .mainForm {
      // max-height: 600px;
      // overflow: scroll;
      text-align: center;
      // background-color: #fafafa;
      border-radius: 2px;
      // margin-top: 54px;
      justify-content: center;
      // width: 1100px;
      margin: 0px auto 20px auto;
      @include responsive(tablet) {
        width: 100%;
        flex-direction: column;
        align-items: center;
      }
    }
    @include responsive(small) {
      width: 100%;
    }
  }

  .steps-action {
    display: none;
    height: 80px;
    // border: 1px solid #dedede;
    border-left: hidden;
    border-bottom: hidden;
    border-right: hidden;
    padding: 19px 0 19px 0;
    display: flex;
    justify-content: flex-end;
    // background-color: #fafafa;
    @include responsive(small) {
      justify-content: space-between;
      padding: 19px 20px 19px 20px;
    }
  }
  //   .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  //     background: red !important;
  //   }
}

.pincodeInput {
  // border: 5px solid red;
  // width: 400px;
  height: 40px;
  // margin-top: 4px;

  background: #f7f7f7;
  border-radius: 5px;
}

.header-with-bottom-line {
  .header-with-bottom-line-text {
    font-weight: 700;
    font-size: 16px;

    color: #061624;
  }
  .header-with-bottom-line-border {
    border: 1px solid #dddddd;
    margin-bottom: 5px;
  }
}
.basic-details-conten {
  padding: 20px 0;
  // .ant-picker-small {
  //   padding: 0px 0px 0px;
  // }
}

.basic-details-view {
  // width: 849px;
  height: auto;

  background: #fafafa;
  border: 1px solid #ededed;
  padding: 0px 0px 5px 44px;
  border-radius: 5px;
  margin: 10px 0;
  .basic-details-img {
    width: 68%;
    height: 172px;

    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 5px;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .miscellaneous-details-img {
    width: 68%;
    height: 262px;

    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 5px;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cp-address-img {
    width: 68%;
    height: 306px;
    left: 334px;
    top: 690px;

    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
  }
  .delivery-details-img {
    width: 68%;
    height: 204px;

    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 5px;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .basic-details-container {
    // display: flex;
    // flex-direction: column;
    row-gap: 30px;
    padding: 30px 0 12px 0;
    // height: 100px;
    // border: 1px solid red;
    .basic-details-name {
      font-weight: 400;
      font-size: 14px;

      color: #333333;
    }
    .basic-details-hub {
      font-weight: 500;
      font-size: 14px;
      line-height: 128%;
      max-width: 200px;
      word-wrap: break-word;

      color: #202124;
    }
  }
}
.formFooter {
  background: #fafafa;
  border: 1px solid #ededed;
  border-radius: 5px;
  margin-bottom: 20px;
}
.ant-input[disabled] {
  background-color: #e6e6e6 !important;
  border-color: #e6e6e6 !important;
  color: black;
}

.button-container1 {
  border: 1px solid #dedede;
  border-bottom: none;
  border-left: none;
  border-right: none;
  .steps-action .back-button {
    color: #2e77d0;
    margin-right: 8px;
    border: 1px solid #2e77d0;
    border-radius: 4px;
    width: 140px;
    height: 40px;
  }
  .steps-action .onbord-button {
    color: #2e77d0;
    margin-right: 8px;
    border: 1px solid #2e77d0;
    border-radius: 4px;
    width: 157px;
    height: 40px;
  }
  .steps-action1 .next-button {
    background-color: #2e77d0;
    color: white !important;
  }
  .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
  }
}

.form-contener {
  background: white;
  padding: 0 0 0 0 !important;
}

.form-contener-inner {
  padding: 1% 10%;
}

.form-contener-priview {
  margin: 10px 0;
}

.firm-container {
  padding: 0 10%;
}

.personal-checkbox {
  font-weight: 500;
  font-size: 14px;
  line-height: 128%;
  /* identical to box height, or 18px */

  color: #000000;
}

.view-details-header {
  // width: 80%;
  height: 35px;

  background: #f5faff;
  border: 1px solid #bfdcff;
  border-radius: 5px;
  .view-details-header-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    /* identical to box height, or 200% */

    letter-spacing: -0.25px;

    color: #061624;
    padding-left: 17px;
  }
}

.view-basic-details {
  padding: 10px 20px;
  .view-basic-details-contain {
    // width: 900px;
    height: 171px;
    left: 313px;
    top: 329px;

    background: #fafafa;
    border: 1px solid #ededed;
    border-radius: 5px;
  }
  .view-basic-details-header-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.25px;

    color: #061624;
  }
}
