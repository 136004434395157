.pending-drs-container{
    .header-pending{
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
        color: #000000;
        margin-left: 15px;
    }
    .count{
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
        color: #FFFFFF;
        margin-left: 5px;
    }
        background: #FFFFFF;
        box-shadow: 0px 1px 4px rgba(107, 107, 107, 0.25);
        border-radius: 2px;
        .ant-table{
            overflow-x: scroll;
        }
        .ant-picker-range{
            background: #FAFAFA !important;
            border-radius: 5px !important;
            margin-left: 0.5rem;
        }
        .ant-tag-green{
            width: 100px;
            background: #4EAB4C;
            border-radius: 3px;
            color: #FFFFFF;
            text-align: center;
        }
        .ant-tag-volcano{
            width: 100px;
            color: #FFFFFF;
            text-align: center;
            background: #F66C6C;
            border-radius: 3px;
        }
}