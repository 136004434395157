.master-card-container {
  background: #ffffff;
  box-shadow: 0px 0px 1px 1px rgba(107, 107, 107, 0.06);
  border-radius: 10px;
  padding: 0.8rem 0.2rem 0 0.2rem;
  margin: 1.5rem 0;
  height: 85%;
  :hover{
    box-shadow: none !important;
    transform: scale(1) !important;
  }
  
  .img-wrapper {
    text-align: center;
    img {
      height: 80px;
      width: 80px;
      margin: 0.5rem 0 0 0;
    }
  }
  .div {
    text-align: center;
    text-transform: capitalize;
    color: #061624;
    font-weight: 500;
    margin: 1rem 0 0 0;
    font-size: 14px;
  }
}

.master-card-col :hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  transition: 0.3s;
  //transform: scale(1.01);
}
.master-card-container-disbale { 
  background: #F0F0F0;
}