@import "../../../commonStyles/color.scss";
.template-container {
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(107, 107, 107, 0.25);
    border-radius: 5px;
    overflow: scroll;
    // height: 86vh;
    // margin: 0 20px;
    .ant-select-selection-overflow-item {
        width: 100% !important;
    }
    .ant-select-selection-item {
        display: flex;
        justify-content: space-between;
    }
    .ant-select-multiple .ant-select-selection-item {
        box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px;
        border-radius: 5px;
        background: #fff;
    }
    .ant-form-item {
        margin-bottom: 15px;
    }
    .create-card {
        padding: 5rem 10rem;
    }
    .ant-form-item-label label {
        color: #484848 !important;
        font-weight: 500 !important;
    }
    .booking-details-container .form-inputs {
        margin-top: 0;
        width: 100%;
        // padding-right: 32px;
    }
    .booking-details-container .form-inputs .ant-select-selector,
    .booking-details-container .form-inputs Input {
        width: 100%;
        min-height: 40px;
        background: #f7f7f7;
        border-radius: 5px;
        font-weight: 400;
        font-size: 14px;
        line-height: 128%;
        color: #211f1f;
        border: 1px solid #f7f7f7;
    }
    .ant-picker {
        padding: 0 !important;
    }
    .ant-picker-input {
        padding: 0 10px !important;
        border-radius: 5px !important;
    }
    .ant-picker-clear {
        margin-right: 10px !important;
    }
    .ant-picker-status-error .ant-picker-input {
        border: 1px solid #ff4d4f !important;
    }
    .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
            .ant-pagination-size-changer
        )
        .ant-select-selector {
        background: #f7f7f7 !important;
    }
    .span-color {
        color: $dark-blue-color;
        font-weight: 500;
    }
    .span-color-tbl {
        color: $dark-grey-color;
        font-weight: 700;
    }
    thead tr .ant-table-cell {
        border-right: 1px solid #dddddd !important;
        font-weight: 700 !important;
        color: #707485 !important;
    }
    tr .ant-table-cell {
        color: #212529 !important;
        font-weight: 400 !important;
    }
    .ant-pagination-item-link {
        border-color: #fff;
    }
    .ant-table-row {
        border-left: 1px solid #dddddd !important;
        border-bottom: 1px solid #dddddd !important;
    }
    thead tr .ant-table-row-expand-icon-cell {
        border: none !important;
    }
    .test {
        width: 19% !important;
    }
    .ant-select-selector {
        display: flex !important;
        align-items: center !important;
    }
    thead tr .ant-table-cell {
        border-right: none !important;
    }
    textarea.ant-input {
        max-width: 100%;
        height: auto;
        min-height: 32px;
        line-height: 1.5715;
        vertical-align: bottom;
        transition:
            all 0.3s,
            height 0s;
        border: none;
        border-radius: 5px;
        // min-height: 40px;
        background: #f7f7f7;
    }
}
.rate-card-tab-container {
    margin-top: -1.5rem;
}
.rate-card-tab-container .ant-tabs-nav {
    margin: 5px 15px 0 15px !important;
}
.review-allocate {
    background: #ffffff;
    border: 1px solid #2e77d0;
    border-radius: 4px;
    color: #2e77d0;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
        padding: 0 5px;
    }
}
.cash-rate-pannel .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px 16px 0 16px;
}
.ant-collapse {
    border-radius: 5px !important;
    border: 1px solid #f7f7f7 !important;
}
.ant-collapse-header {
    background: #f7f7f7 !important;
    padding: 10px 16px !important;
}
.ant-collapse-content {
    border-top: 1px solid #f7f7f7 !important;
    border-bottom: 1px solid #f7f7f7 !important;
}
.ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid #e9e6e6;
}
.template-name {
    text-transform: capitalize !important;
}
.template-name:first-letter {
    text-transform: uppercase !important;
}
.booking-details-container .form-inputs .ant-form-item-explain-error {
    padding: 4px !important;
}
