.recent-booking-container {
  .ant-table-thead .ant-table-cell {
    background-color: #f3f6f9;
    // font-family: $default-font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    /* identical to box height */
    text-decoration: none !important;
    letter-spacing: -0.25px;
    color: #4a4b4d;
    padding: 5px 10px;
  }

  .ant-table-tbody {
    .ant-table-cell {
      font-size: 12px;
    }
  }
}
