.exception-report-action-btn {
  .ant-select-selection-item {
    font-weight: 500;
    color: grey;
  }
}

.exception-report-action-dis-btn {
  .ant-select-selection-item {
    font-weight: bold;
    // color:;
  }
}
