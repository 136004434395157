.pincode-mapping-container {
  .hover-icons {
    transition: transform 0.2s;
    margin-bottom: 0;
  }
  .hover-icons:hover {
    transform: scale(1.05);
  }
  .edit-hover-icons {
    transition: transform 0.2s;
    margin-bottom: 0;
  }
  .edit-hover-icons:hover {
    transform: scale(1.3);
  }
  .heading {
    margin: 30px 30px 0 30px;
    display: flex;
    align-items: center;
    span {
      font-weight: 700;
      font-size: 18px;
      line-height: 32px;
      letter-spacing: -0.25px;
      color: #061624;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin-left: 5px;
    }
  }
  .pincode-mapping-wrapper {
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(107, 107, 107, 0.25);
    border-radius: 5px;
    // margin: 30px;
    margin-top: 6px;
    .search-container {
      padding: 15px 25px 15px 25px;
      display: flex;
      justify-content: space-between;
      .ant-input-affix-wrapper {
        width: 477px !important;
        height: 35px !important;
      }
      .buttons {
        display: flex;
        .ant-btn {
          background: #ffffff;
          border: 1px solid #2e77d0;
          border-radius: 4px;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          text-transform: uppercase;
          color: #2e77d0;
          display: flex;
          align-items: center;
          margin-right: 10px;
          height: 35px;
          img {
            margin-right: 5px;
          }
        }
      }
    }
    .ant-table-thead {
      .ant-table-selection-column {
        // padding-left: 19px !important;
      }
      img {
        margin-right: 5px;
      }
      th {
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.25px;
        color: #707485;
      }
      .ant-table-cell-fix-right {
        align-items: center;
      }
      // .ant-table-cell {
      //     display: flex;
      //     align-items: center;
      // }
    }
    .ant-table-tbody {
      .edit-button {
        border: none;
        background: none;
        width: 24px;
        height: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      td {
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        color: #212529;
      }
      .ant-table-selection-column {
        // padding-left: 19px !important;
      }
      .pincode-count-wrapper {
        display: flex;
        align-items: center;
        .text {
          width: 91px;
          height: 15px;
          overflow: hidden;
        }
        .ant-tooltip-content {
          .ant-tooltip-inner {
            background: #ffffff;
            border-radius: 2px;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: -0.25px;
            color: #212529;
            width: 101px;
          }
        }
        .pin-code-count {
          width: 22px !important;
          height: 22px !important;
          background: #a2cbfd;
          border: 0.5px solid #9cb7d8;
          border-radius: 50%;
          font-weight: 500;
          font-size: 8px;
          line-height: 9px;
          letter-spacing: -0.25px;
          color: #212529;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          margin-left: 4px;
        }
      }
      .ant-table-cell-fix-right {
        text-align: center;
      }
    }
    .ant-pagination-next {
      margin-right: 2%;
    }
  }
}

.pincode-mapping-modal {
  width: 619px !important;
  height: 324px;
  margin-top: 70px;
  .ant-modal-header {
    border-bottom: 1px solid #dedede;
    .ant-modal-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #202124;
    }
  }
  .ant-modal-content {
    width: 100%;
  }
  .ant-modal-body {
    height: 250px;
    overflow: scroll;
    .ant-table-wrapper {
      overflow-y: scroll;
      .ant-table-thead {
        display: none;
      }
      .ant-table-tbody {
        .ant-table-cell {
          border: 1px solid #dddddd;
        }
      }
    }
  }
  .pincode-mapping-modal-container {
    display: flex;
    .pincode-data {
    }
  }
}
