.scan-container {
  // width: 630px;
  // height: 500px;

  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(107, 107, 107, 0.25);
  border-radius: 5px;
  margin: 20px;
  // overflow-y: scroll;
  // overflow-x: hidden;
  .scan-container-header {
    font-weight: 700;
    font-size: 16px;
    padding: 10px;
    color: #061624;
    display: flex;
    justify-content: space-between;
    .scan-container-header-backArrow {
      padding: 10px;
      cursor: pointer;
    }
    .scan-container-header-packetContainer {
      font-weight: 500;
      font-size: 14px;
      /* identical to box height, or 171% */

      color: #424242;
      .scan-item {
      }
      .scan-item-val {
        width: 40px;
        height: 34px;

        padding: 5px 10px;
        margin: 10px;
        background: #f3f9fe;
        border-radius: 7px;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;

        color: #2e77d0;
      }
    }
  }
  .scan-container-header-line {
    width: 100%;
    height: 0px;

    border-bottom: 1px solid #dddddd;
  }
  .scan-container-div {
    padding: 5px;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .scan-container-div-childern {
    bottom: 0;
    right: 0;
  }
}

.manifestCard-container {
  // width: 100%;

  width: 100%;
  height: 156px;

  padding: 10px 10px 0 10px;
  background: #ffffff;
  border-radius: 5px 5px 0px 0px;
  // inset: 20px;
  // padding: 10px;
  position: relative;
  .manifestCard-container-row {
    padding: 10px 5px;
    margin-bottom: 35px;
  }
  .manifestCard-container-firstRow {
    padding: 10px 5px;
  }
  .manifestCard-container-secondRow {
    padding: 10px 0px;
    margin: 0 0 30px 0;
    .manifestCard-container-secondRow-footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: #f8f3ed;
      padding: 0 10px;
    }
  }

  .manifestCard-container-text {
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: -0.25px;
    padding-bottom: 5px;

    color: #979797;
  }
  .manifestCard-container-number {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.25px;

    color: #212529;
  }
  .packetContents {
    gap: 5px;
    margin: auto;
  }
}
.PacketContents-editionalCount {
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: -0.25px;
  padding-top: 5px;
  color: #4c4d52;
}
.PacketContents-number {
  background: #f1f7fe;
  border-radius: 2px;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: -0.25px;
  padding: 5px;
  color: #4c4d52;
}
.packet-contents-row {
  gap: 6px;
  margin: auto;
}
@media (900px <= width <= 1280px) {
  .PacketContents-number {
    background: #f1f7fe;
    border-radius: 2px;
    font-weight: 500;
    font-size: 9px;
    line-height: 13px;
    letter-spacing: -0.25px;
    padding: 5px;
    color: #4c4d52;
  }
}
.skyblue {
  background: #ffffff;
  margin: 5px;

  border-radius: 4px;
  align-self: center;
  border: 2px solid #9ac0ee;
  border-left-width: 10px;
  border-bottom-left-radius: 5px;
  // inset: 20px;
  // padding: 10px;
  position: relative;
}

.greens {
  background: #ffffff;
  margin: 5px;

  border-radius: 4px;
  align-self: center;
  border: 2px solid #5cc988;
  border-left-width: 10px;
  border-bottom-left-radius: 5px;
  // inset: 20px;
  // padding: 10px;
  position: relative;
}

.yello {
  background: #ffffff;
  margin: 5px;

  border-radius: 4px;
  align-self: center;
  border: 2px solid #daa351;
  border-left-width: 10px;
  border-bottom-left-radius: 5px;
  // inset: 20px;
  // padding: 10px;
  position: relative;
}

.navigate-button {
  background: #2e77d0;
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;

  letter-spacing: 1px;
  text-transform: uppercase;

  color: #ffffff;
}

// .reactangle-yello {
//   box-sizing: border-box;
//   /* position: absolute; */
//   width: 100%;
//   height: 188px;
//   background: #daa351;
//   border-radius: 4px;
//   padding: 1px;
//   margin: 5px;
// }

// .reactangle-greens {
//   box-sizing: border-box;
//   /* position: absolute; */
//   width: 100%;
//   height: 188px;
//   background: #5cc988;
//   border-radius: 4px;
//   padding: 1px;
//   margin: 5px;
// }

// .reactangle-skyblue {
//   box-sizing: border-box;
//   /* position: absolute; */
//   width: 100%;
//   height: 188px;
//   background: #9ac0ee;
//   border-radius: 4px;
//   padding: 1px;
//   margin: 5px;
// }

.reactangle-yello {
  box-sizing: border-box;
  /* position: absolute; */
  width: 94%;
  height: 188px;
  background: #daa351;
  border-radius: 4px;
  padding: 1px;
  margin: 5px;
}

.reactangle-greens {
  box-sizing: border-box;
  /* position: absolute; */
  width: 94%;
  height: 188px;
  background: #5cc988;
  border-radius: 4px;
  padding: 1px;
  margin: 5px;
}

.reactangle-skyblue {
  box-sizing: border-box;
  /* position: absolute; */
  width: 98%;
  height: 188px;
  background: #9ac0ee;
  border-radius: 4px;
  padding: 1px;
  margin: 5px;
}
.reactangle2 {
  /* box-sizing: border-box; */

  /* position: relative; */
  float: left;
  width: 97%;
  height: 186px;

  background: #f8f3ed;
  border-radius: 4px;
  float: right;
}

.reactangle3 {
  width: 100%;
  height: 156px;

  padding: 10px;
  background: #ffffff;
  border-radius: 5px 5px 0px 0px;
}

.packetId {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: -0.25px;

  color: #979797;
}
.packetNumber {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.25px;

  color: #212529;
}
@media (900px <= width <= 1280px) {
  .packetId {
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 13px;
    letter-spacing: -0.25px;

    color: #979797;
  }
  .packetNumber {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: -0.25px;

    color: #212529;
  }
}
.firstRow {
  display: flex;
  flex-direction: row;
  row-gap: 5px;
  justify-content: space-between;
  column-gap: 2%;
  padding: 5px 0 5px 0;
  .first-row-img {
    padding-bottom: 5px;
  }
}
.secondRow {
  display: flex;
  flex-direction: row;
  padding: 10px 0 0 0;
  column-gap: 10px;
}
.packetContent {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: -0.25px;

  color: #979797;
}
.packetSubNumber {
  display: flex;
  justify-content: space-evenly;
  font-weight: 500;
  font-size: 11px;
  flex-wrap: nowrap;
}

.packetfooter {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 3px 10px 0 10px;
}

.EwayBill {
  color: #212529;
  font-weight: 500;
  font-size: 12px;
  padding: 2px 0 0 0;
}

.packetContent {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  padding: 5px 0 0 5px;
}

.mini-reactangle-skyblue {
  box-sizing: border-box;
  /* position: absolute; */
  width: 97%;
  height: 188px;
  background: #9ac0ee;
  border-radius: 4px;
  padding: 1px;
}

.mini-reactangle-greens {
  box-sizing: border-box;
  /* position: absolute; */
  width: 97%;
  height: 188px;
  background: #5cc988;
  border-radius: 4px;
  padding: 1px;
}

.mini-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 16px;
}

.mini-container-div {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  padding-right: 16px;
}

.mini-reactangle1 {
  float: left;
  width: 97%;
  height: 186px;

  background: #f9f9f9;
  border-radius: 4px;
  float: right;
}

.mini-reactangle2 {
  float: left;
  width: 100%;
  height: 154px;

  background: #ffffff;
  border-radius: 4px;
  float: right;
  padding: 10px;

  .manifestCard-container-row {
    padding: 10px 5px;
    margin-bottom: 35px;
  }
  .manifestCard-container-text {
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: -0.25px;
    padding-bottom: 5px;

    color: #979797;
  }
  .manifestCard-container-number {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.25px;

    color: #212529;
  }
  @media (900px <= width <= 1280px) {
    .manifestCard-container-text {
      font-weight: 400;
      font-size: 9px;
      line-height: 13px;
      letter-spacing: -0.25px;
      padding-bottom: 5px;

      color: #979797;
    }
    .manifestCard-container-number {
      font-weight: 500;
      font-size: 10px;
      line-height: 14px;
      letter-spacing: -0.25px;

      color: #212529;
    }
  }
}
