@import "../../../../commonStyles/button.scss";
@import "../../../../commonStyles/media-query.scss";

.expanded-hub-screen {
  .expanded-hub-wrapper {
    padding: 10px 16px 10px 16px !important;
    height: auto !important;
    overflow: scroll !important;
    h2 {
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0.03em;
      color: #000000;
      margin-bottom: 1.5em;
    }
    .expanded-hub-header {
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0.03em;
      color: #000000;
      margin-bottom: 10px;
    }
    hr {
      margin-bottom: 25px;
    }
    .expanded-hub {
      .expanded-hub-lable {
        font-weight: 400;
        font-size: 12px;
        line-height: 100%;
        color: #555555;
        margin-bottom: 6px;
      }
      .expanded-hub-value {
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        color: #202124;
        margin-bottom: 16px;
        width: 200px;
        word-wrap: break-word;
      }
    }
  }
}
.hub-onboard-buttons {
  display: flex;
}
.gstin-style {
  .ant-input-affix-wrapper {
    background: #f7f7f7 !important;
    border-radius: 5px !important;
    border: none;
  }
}
.download-btn {
  @include BackButton(90, 12px, 700);
}
