.master-search-container {
  background: #ffffff;
  box-shadow: 0px 0px 1px 1px rgba(107, 107, 107, 0.06);
  border-radius: 10px;
  padding: 1rem;
  text-transform: capitalize;
  color: #061624;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  >span{
    font-size: 18px;
  }
}
