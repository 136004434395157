.fc-toolbar-chunk {
  display: flex !important;
  align-items: center;
  margin: 1rem 1rem 0 1rem !important;
}
.fc-toolbar-title {
  font-size: 1rem !important;
}
.fc .fc-button {
  padding: 8px !important;
}
.fc .fc-button-primary {
  background-color: #2e77d0;
  border-color: #2e77d0 !important;
}
.fc .fc-button-primary:hover {
  background-color: #2d64a8;
  border-color: #2d64a8 !important;
}

.fc .fc-button-primary:not(:disabled).fc-button-active {
  background-color: #2e77d0 !important;
  border-color: #2e77d0 !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.trip-title {
  // background: #86a6af;
  p {
    color: #333333;
  }
}
.fc .fc-highlight {
  background: #fff;
}
.fc-event {
  background: #deeff4 !important;
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.trip-details-popover {
  width: 600px;
}
.line {
  height: 120px;
  border-right: 2px solid #dddddd;
  width: 18px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: -0.3rem;
}
.light-color {
  color: #999999;
}
.route-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 120px;
}
.route-name-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
}
.route-name-driver {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
}
.vehicle-title {
  margin-top: -0.6rem;
  color: #444444;
}
.vehicle-list-div {
  height: 100vh;
  overflow: scroll;
  padding: 3px;
  margin-top: 5px;
}
.vehicle-div {
  border-radius: 5px;
  border: 0.5px solid rgba(0, 0, 0, 0.05);
  margin-top: 10px;
  padding: 5px;
  &:hover {
    filter: brightness(98%);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    transition: 0.5s;
  }
}
.create-calendar-class {
  .fc-day-past {
    background-color: #f5f5f7 !important;
  }
}
.create-schedule-btn {
  margin-right: 1.01rem;
  position: absolute;
  z-index: 1;
  margin-top: 1.1rem;
}

.ant-progress-inner {
  background-color: #e7e7e7;
}
