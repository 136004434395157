@use "sass:math";

/* font-size */
$base-font-size: 10px;
$base-font-weight: 400;
$default-font-color: white;
$default-font-style: "normal";
$default-font-family: Roboto;

$font-weight-bold: bold;

@mixin fontStyle($base-font-size, $base-font-weight, $font-clr) {
  color: $font-clr;
  font-size: $base-font-size;
  font-weight: $base-font-weight;
}
