@import "../../commonStyles/app.scss";
@import "../../commonStyles/font.scss";

.cardFlex {
  box-shadow: 0px 0px 1px 1px rgba(107, 107, 107, 0.06) !important;
  border-radius: 10px !important;
  // background: #ffffff;
  // box-shadow: 0px 1px 4px rgba(107, 107, 107, 0.25);
  // border-radius: 20px;
  // width: 100%;
  // border: 2px solid red;
  // height: 200px;
  .ant-card-body {
    padding: 0;
  }
  .ant-card-head {
    padding: 0px 13px;
  }
  .cardFlex-icon {
    margin-right: 5px;
  }

  margin: 0 0 20px 0;
  @include NormalCard();
  width: 100%;
  .totalDay {
    font-family: $default-font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    /* identical to box height, or 200% */

    letter-spacing: -0.25px;

    color: #061624;
  }
  .dayTotalWrapper {
    border: 1px solid #ebf1fa;
    border-radius: 6px;
    margin: 18px 27px;
  }
  .dayTotalInnerBox {
    width: 100%;
    height: 92px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-weight: 400;
    padding-top: 15px;
    .dayTotalInnerBoxRuppesText {
      font-size: 28px;
      line-height: 32px;

      display: flex;
      align-items: center;

      color: #2e77d0;
    }
    .dayTotalInnerBoxText {
      font-weight: 400;
      font-size: 14px;
      line-height: 32px;
      /* identical to box height, or 229% */

      letter-spacing: -0.25px;

      color: #585b5f;
    }
  }

  .cardFlex1 {
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: space-around;
    width: 100%;
    cursor: pointer;
    padding: 20px 20px 20px 26px;

    .chart_para {
      // margin-left: 4rem;
      font-weight: 600;
      font-size: 13px;
      // padding-left: 20px;
      // height: 130px;
      display: flex;
      margin-top: 10px;
      align-items: center;
      justify-content: center;
      // line-height: 32px;
      /* identical to box height, or 200% */

      letter-spacing: -0.25px;

      color: #061624;
      .arrowClass {
        margin-left: 9px;
      }
    }
    @media only screen and (min-width: 700px) and (max-width: 900px) {
      width: 100%;
    }
  }
  .accountImageCard {
    background: #f8fbff;
    border: 1px solid #ebf1fa;
    border-radius: 12px;
    // width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
  }
}
.global-form .ant-picker-status-error .ant-picker-input {
  border: none !important;
}
