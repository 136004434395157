@import "../../commonStyles/media-query.scss";

.address {
  padding: 0 !important;
}
.service {
  .left-card {
    border-radius: 4px 0 0 4px !important;
  }
  .right-card {
    border-radius: 0 4px 4px 0 !important;
  }
}
.payment {
  margin-top: 20px !important;
  .left-card {
    border-radius: 4px 0 0 4px !important;
  }
  .right-card {
    border-radius: 0 4px 4px 0 !important;
  }
}

.left-card {
  border-radius: 4px 0 0 4px !important;
}
.right-card {
  border-radius: 0 4px 4px 0 !important;
}
.address {
  .ant-col {
    width: 100%;
    .ant-row {
      width: 100%;
      margin: 20px 0 20px 0;
      .card-one {
        float: right;
      }
      .card {
        // width: 100%;
        margin: 0 !important;
        // margin: 20px 0 20px 0 !important;
        width: 98%;
        @include responsive(small) {
          width: 100%;
        }
      }
    }
  }
}
.booking-summary {
  height: 90vh;
  .payment {
    .frieght-class {
      display: flex;
      width: 100%;
      p {
        width: 50%;
      }
      p:last-child {
        text-align: right;
      }
    }
  }
  .ant-modal-content {
    @include responsive(tablet) {
      margin-top: 25px;
    }
    .ant-modal-body {
      padding: 0;
      .modal-content {
        height: 88vh;
        overflow: scroll;
        overflow-x: unset;
        padding: 24px;
        .card {
          .last {
            border-bottom: none;
            margin-bottom: 0%;
          }
          .right-last {
            visibility: hidden;
          }
        }
      }
    }
  }
}

.ant-modal-close-icon {
  background: #f5f8fd;
  border: 1px solid #b0c0dc;
  border-radius: 3px;
  color: black;
  margin-left: -10px;
}

#frieght {
  background-color: #fbee78;
  color: black;

  border-radius: 5px 0 0 5px;
}
#frieghtValue {
  background-color: #fbee78;
  color: black;
  border-radius: 0 5px 5px 0;
}

.service-summary-nondox {
  width: 100%;
  margin-top: 20px;
  td {
    background: #ffffff;
    border: 1px solid #dedede;
    // border-radius: 4px;
    border-collapse: collapse;
  }
  .no-packet {
    display: block !important;
    padding-bottom: 11px !important;
    .content {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .itemone {
      width: 105%;
      visibility: hidden;
      margin-top: -2px;
    }
  }
  .table-data {
    display: flex;
    justify-content: space-between;
    padding: 10px;

    .heading {
      font-weight: 500;
      font-size: 13px;
      color: #6b6b6b;
    }
    .item {
      width: 235px;
      font-weight: 600;
      font-size: 13px;
      color: #061624;
      // text-transform: uppercase;
    }
  }
  .top-left {
    border-radius: 4px 0 0 0;
  }
  .bottom-left {
    border-radius: 0 0 0 4px;
  }
  .top-right {
    border-radius: 0 4px 0 0;
  }
  .bottom-right {
    border-radius: 0 0 4px 0;
  }
  .child-packet {
    display: block;
    .item {
      width: 100%;
    }
  }

  table {
    display: flex;
  }
  table,
  th,
  tr {
    width: 100%;
  }
}
