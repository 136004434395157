.profile {
  width: 60px;
  height: 60px;
  background: #2e77d0;
  border-radius: 50%;
  color: #fff;
  font-size: 1.5rem;
  .profile-edit-img {
    position: absolute;
    top: 38px;
    left: 45px;
  }
}
