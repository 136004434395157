.vehicle-onboard-tab-container {
  margin-top: -1.5rem;

  .vehicle-onboard-create-container {
    padding: 2rem 12rem 0 12rem;
  }

  .actions-col {
    .pointer {
      margin: 10px auto 0 auto;
    }
  }

  .ant-tabs-nav {
    margin: 20px 30px 0 2px !important;
  }

  svg {
    cursor: pointer;
  }

  .ant-input-group-addon {
    border: none;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    background: #f7f7f7;
  }

  .disabled-row {
    background-color: #f4f4f4;
    .ant-table-cell {
      color: #b9b9b9 !important;
    }
    .ant-table-cell-fix-right,
    .ant-table-cell-fix-left {
      background-color: #f4f4f4;
    }

    .ant-table-cell-row-hover {
      background-color: #f4f4f4 !important;
    }

    .disable-edit {
      cursor: not-allowed;
    }
  }
}
