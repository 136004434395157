@import "../../../commonStyles/media-query.scss";
.left-card {
    border-radius: 4px 0 0 4px !important;
    .service-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px !important;
        border-bottom: 1px solid #dedede;
        font-weight: 500;
        font-size: 13px;
        line-height: 128%;
        /* identical to box height, or 17px */
        color: #6b6b6b;
        p {
            border: none !important;
            width: 215px;
            font-weight: 500 !important;
            font-size: 13px !important;
            line-height: 128% !important;
            /* identical to box height, or 17px */
            color: #061624 !important;
            text-transform: uppercase;
        }
    }
    .key {
        width: 100%;
    }
}
.right-card {
    border-radius: 0 4px 4px 0 !important;
    .service-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px !important;
        border-bottom: 1px solid #dedede;
        font-weight: 500;
        font-size: 13px;
        line-height: 128%;
        /* identical to box height, or 17px */
        color: #6b6b6b;
        p {
            border: none !important;
            width: 215px;
            font-weight: 500 !important;
            font-size: 13px !important;
            line-height: 128% !important;
            /* identical to box height, or 17px */
            color: #061624 !important;
            text-transform: uppercase;
        }
    }
    .sender-gst-component {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px !important;
        .senderGstNumber-container {
            p {
                border: none !important;
            }
            .ant-form-item-control-input-content {
                border: none !important;
            }
        }
    }
}
.non-dox-booking-summary-edit {
    .edit-input-button-container {
        display: flex;
        justify-content: space-between;
        .senderGstNumber-container {
            width: 200px;
            .ant-form-item-control-input-content {
                width: 205px;
            }
        }
        div {
            display: flex;
        }
        p {
            display: flex;
            align-items: center;
            padding: 11px 0;
        }
        button {
            border: none;
            background: transparent;
            cursor: pointer;
        }
        .ant-form-item-control-input-content {
            input {
                width: 110px;
                margin-bottom: 0 !important;
            }
        }
    }
    .payment {
        .frieght-class {
            display: flex;
            width: 100%;
            p {
                width: 50%;
            }
            p:last-child {
                text-align: right;
            }
        }
    }
    .ant-modal-content {
        .ant-modal-body {
            padding: 0;
            .modal-content {
                height: 75vh !important;
                overflow: scroll;
                overflow-x: unset;
                padding: 24px;
                padding-bottom: 0;
                .card {
                    .senderGstNumber {
                        .ant-form-item-control-input-content {
                            margin-left: 10px;
                        }
                    }
                    .third-last {
                        height: 0px;
                        position: relative;

                        .edit-input-field {
                            .ant-form-item-control-input-content {
                                margin-left: 10px;
                                .senderGstNumber {
                                    margin-left: 0;
                                }
                                .ant-input {
                                    position: absolute;
                                    width: 78%;
                                    top: -5px;
                                }
                            }
                        }
                    }
                    .sec-last {
                        margin: 0 !important;
                    }
                    .last {
                        border-bottom: transparent;
                        margin-bottom: 0%;
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .address {
        padding: 0 !important;
        // .address-edit {
        //   position: unset !important;
        //   margin-right: 10px;
        // }
        .adress-container {
            .card {
                .card-box {
                    position: relative;
                }
            }
        }
        .edit-button {
            width: 37%;
        }
        .title {
            display: flex;
            justify-content: space-between;

            .edit-button {
                position: inherit !important;
                margin-right: 10px;
                transition: transform 0.9s;
                margin-bottom: 0;
            }
            .edit-button :hover {
                transform: scale(1.2);
            }
        }

        h4 {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #000000;
            margin-bottom: 0;
        }
        .form-item-hide {
            margin-bottom: 0 !important;
            .ant-row {
                margin: 0 !important;
            }
            .ant-form-item-control-input {
                display: none;
            }
            .ant-form-item-label {
                display: none;
            }
        }

        .highlight {
            margin-bottom: 5px;
            display: none;
        }
        .highlight-receiver {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #000000;
        }
        .hide {
            display: none;
        }

        .ant-input-disabled {
            font-weight: 500;
            font-size: 14px;
            color: #626262;
        }
        .addressDetails {
            border: none;
            background: none;
        }
        .ant-col {
            width: 100%;
            .ant-row {
                width: 100%;
                margin: 20px 0 20px 0;
                .card-one {
                    float: right;
                }
                .card {
                    // width: 100%;
                    min-height: 180px !important;
                    margin: 0 !important;
                    // margin: 20px 0 20px 0 !important;
                    width: 98%;
                    .ant-input {
                        margin-bottom: 5px;
                    }
                    @include responsive(small) {
                        width: 100%;
                    }
                }
            }
        }
    }

    .ant-modal-close-icon {
        background: #f5f8fd;
        border: 1px solid #b0c0dc;
        border-radius: 3px;
        color: black;
        margin-left: -10px;
    }

    #frieght {
        background-color: #fbee78;
        color: black;

        border-radius: 5px 0 0 5px;
    }
    #frieghtValue {
        background-color: #fbee78;
        color: black;
        border-radius: 0 5px 5px 0;
    }

    .ant-modal-header {
        // position: fixed;
        // z-index: 1;
        // width: 65%;
    }
    .ant-modal-body {
        // margin-top: 5%;
    }
    .ant-modal-close-x {
        // position: fixed;
        // left: 78%;
    }

    .service {
        .card {
            .child-awb-container {
                display: flex;
                width: 470px;
                border-top: 1px solid #dedede;
                p {
                    width: 237px;
                }
            }
            .addressDetails-gst {
                padding: 0 !important;
            }
            .ant-input {
                font-weight: 600;
                font-size: 14px;
                color: black;
            }
            .ant-input-disabled {
                border: none;
                background: none;
            }
            .hide {
                display: none;
            }
            .highlight {
                margin-bottom: 5px;
                display: none;
            }
            .lastFormItem {
                display: none;
            }
            .ant-form-item-label {
                color: red;
            }
            .form-item-hide {
                margin-bottom: 0 !important;
                .ant-row {
                    margin: 0 !important;
                }
                .ant-form-item-control-input {
                    display: none;
                }
                .ant-form-item-label {
                    display: none;
                }
            }
        }
    }
    .submit-edit {
        margin: 20px;
        background: #2e77d0;
        border-radius: 4px;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #ffffff;
    }
    .phone-num-cls {
        .ant-form-item-row {
            flex-flow: unset !important;
            margin: 0 !important;
            align-items: center;
        }
    }
    .senderGstNumber {
        .ant-form-item-control-input-content {
            border-bottom: 1px solid #dedede;
        }
    }
    .edit-input-field {
        margin-bottom: 0px !important;
        //

        .ant-form-item-control-input-content {
            height: 43px;
            display: flex;
            align-items: center;
            margin-left: 10px;
            input {
                width: 75%;
            }
        }
        .ant-input {
            width: 78%;
        }
    }
    .button-footer {
        display: flex;
        justify-content: flex-end;
        width: 99%;
        button {
            margin: 20px 20px 20px 0;
        }
        .ant-btn[disabled],
        .ant-btn[disabled]:hover,
        .ant-btn[disabled]:focus,
        .ant-btn[disabled]:active {
            color: rgba(0, 0, 0, 0.25) !important;
            border-color: #d9d9d9;
            background: #f5f5f5 !important;
            text-shadow: none;
            box-shadow: none;
        }
    }
    .ant-form-item-explain-error {
        margin-left: 10px;
    }
}

.service-summary {
    width: 100%;
    margin-top: 20px;
    .ant-row{
        width: 100%;
    }
    td {
        background: #ffffff;
        border: 1px solid #dedede;
        // border-radius: 4px;
        border-collapse: collapse;
    }
    .no-packet {
        display: block !important;
        .content {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
        .itemone {
            width: 105%;
            visibility: hidden;
            margin-top: -2px;
        }
    }
    .table-data {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        .address-edit {
            border: none;
            background: transparent;
            cursor: pointer;
            height: 20px;
        }
        .heading {
            font-weight: 500;
            font-size: 13px;
            color: #6b6b6b;
        }
        .item {
            width: 235px;
            font-weight: 600;
            font-size: 13px;
            color: #061624;
            // text-transform: uppercase;
        }
    }
    .eway-field-nondox {
        .heading {
            width: 100px;
        }
        .ant-form-item {
            margin-bottom: 0;
            .ant-form-item-control-input-content {
                margin-left: 8px;
                input {
                    width: 125px;
                }
            }
        }
        .item-eway {
            font-weight: 600;
            font-size: 13px;
            color: #061624;
            margin-left: -3px;
        }
        .ant-input {
        }
        .ant-form-item-explain-error {
            // margin-left: 65px;
        }
    }
    .gst-field {
        .ant-form-item {
            margin-bottom: 0;
            .ant-form-item-control-input-content {
                // margin-left: 30px;
                input {
                    width: 150px;
                }
            }
        }
        .item-gst {
            width: 130px;
            font-weight: 600;
            font-size: 13px;
            line-height: 128%;
            color: #061624;
            margin-left: 2px;
        }
        .ant-input {
        }
        .ant-input-status-error {
            margin-bottom: 0 !important;
            // margin-left: 10px;
        }
        .ant-form-item-explain-error {
            margin-left: 0 !important;
        }
    }
    .top-left {
        border-radius: 4px 0 0 0;
    }
    .bottom-left {
        border-radius: 0 0 0 4px;
    }
    .top-right {
        border-radius: 0 4px 0 0;
    }
    .bottom-rght {
        border-radius: 0 0 4px 0;
    }
    .child-packet {
        display: block;
        .item {
            width: 450px;
        }
    }

    table {
        display: flex;
    }
    table,
    th,
    tr {
        width: 100%;
    }
}
