@import "../../../commonStyles/button.scss";
.truck-container {
  background: none;
  box-shadow: none;
  justify-content: space-between;
  background-color: transparent !important;
  width: 98%;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 10px;
  .left {
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(107, 107, 107, 0.25);
    border-radius: 5px;
    width: 50%;
    // height: 72vh;
    .header {
      align-items: center;
      justify-content: center;
      height: 56px;
      border-bottom: 1px solid #dddddd;
      width: 100%;
      .ant-col-12 {
        display: flex;
        align-items: center;
        margin-left: -31px;
        h4 {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: #061624;
          margin-bottom: 0;
          margin-left: 8px;
        }
      }
      .ant-col-5 {
        display: flex;
        align-items: center;
        h4 {
          margin-bottom: 0 !important;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: #061624;
          margin-left: 5px;
        }
        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #424242;
        }
        .num {
          width: 40px;
          height: 34px;
          background: #f3f9fe;
          border-radius: 7px;
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;
          color: #2e77d0;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 10px;
        }
      }
    }
    .middle-heading {
      height: 40px;
      background: #f3f6f9;
      align-items: center;
      border-bottom: 1px solid #dddddd;
      .head {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        .total-weight {
          margin: 0;
          span {
            margin-right: 20px;
            font-weight: 700;
            font-size: 13px;
            line-height: 24px;
            /* identical to box height, or 185% */

            color: #061624;
          }
        }
      }
      img {
        margin-right: 10px;
      }
      h4 {
        margin-left: 3%;
        margin-bottom: 0;
      }
    }
    .outer-container {
      height: 52vh;
      overflow: scroll;
      display: flex;
      justify-content: space-around;
      .main-table {
        margin-left: 0 !important;
        margin-right: 0 !important;
        width: 95%;
        justify-content: space-between;
        margin-top: 22px;
        .reactangle-greens {
          width: 98%;
        }
        .packet {
          // width: 50%;
          // margin: auto;
        }
        .shipment {
          width: 50%;
          margin-top: 0;
        }
        .truck-table-container {
          background: #5cc988;
          border-radius: 4px;
          width: 47%;
          height: 194px;
          margin: 0;
          margin-top: 10px;
          padding: 0 0 0 10px;
          .ant-col {
            width: 99.5%;
            height: 247px;
            top: 1px;
            border-radius: 4px;
            .table-row {
              background: #ffffff;
              border-radius: 5px 5px 0px 0px;
              width: 100%;
              height: 155.5px;
              justify-content: center;
              .first-line {
                display: flex;
                width: 90%;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;
                .first-row {
                  margin-top: 15px;
                  display: flex;
                  align-items: flex-end;
                  .content {
                    display: flex;
                    flex-direction: column;
                    .packet-id {
                      font-weight: 500;
                      font-size: 12px;
                      line-height: 14px;
                      letter-spacing: -0.25px;
                      color: #212529;
                    }
                  }
                  img {
                    margin-right: 10px;
                    margin-bottom: 15px;
                  }
                  p {
                    margin: 0;
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 13px;
                    letter-spacing: -0.25px;
                    color: #979797;
                  }
                  h4 {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 14px;
                    letter-spacing: -0.25px;
                    color: #212529;
                    margin-top: 5px;
                  }
                }
                .second-row {
                  display: flex;
                  margin-top: 15px;
                  .content {
                    display: flex;
                    flex-direction: column;
                  }

                  p {
                    margin: 0;
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 13px;
                    letter-spacing: -0.25px;
                    color: #979797;
                  }
                  h4 {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 14px;
                    letter-spacing: -0.25px;
                    color: #212529;
                    margin-top: 5px;
                  }
                }
                img {
                  width: 20px;
                  height: 20px;
                }
              }
              .second-line {
                width: 90%;
                position: relative;
                // width: -webkit-fill-available;
                .packet-content {
                  display: flex;
                  align-items: center;
                  img {
                    margin-right: 10px;
                  }
                }
                p {
                  margin: 0;
                  font-weight: 400;
                  font-size: 11px;
                  line-height: 13px;
                  letter-spacing: -0.25px;
                  color: #979797;
                }
                .table {
                  display: flex;
                  margin-top: 5px;
                  width: 102%;
                  flex-flow: row wrap;
                  height: 60px;
                  overflow: hidden;
                  justify-content: center;

                  h4 {
                    padding-left: 6px;
                    margin: 0;
                    margin-top: 10px;
                    font-weight: 500;
                    font-size: 11px;
                    line-height: 13px;
                    letter-spacing: -0.25px;
                    color: #4c4d52;
                    margin-left: 5px;
                    width: 97px;
                    height: 21px;
                    background: #f1f7fe;
                    border-radius: 2px;
                    display: flex;
                    align-items: center;
                    justify-self: center;
                  }
                }
                .count {
                  position: absolute !important;
                  bottom: 9px;
                  left: 256px;
                  font-weight: 500;
                  font-size: 11px;
                  line-height: 13px;
                  letter-spacing: -0.25px;
                  color: #4c4d52;
                }
              }
            }
            .table-footer {
              background: #f8f3ed;
              border-radius: 0 0px 4px 4px;
              height: 36px;
              display: flex;
              align-items: center;
              img {
                margin: 0 10px 0 10px;
              }
              p {
                margin: 0;
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;
                letter-spacing: -0.25px;
                color: #212529;
              }
            }
          }
        }
        .truck-table-container-individual {
          background: #5cc988;
          border-radius: 4px;
          width: 47%;
          height: 118px;
          margin: 0;
          margin-top: 10px;
          padding: 0 0 0 10px;
          .ant-col {
            width: 99.5%;
            height: 247px;
            top: 1px;
            border-radius: 4px;
            .table-row {
              background: #ffffff;
              border-radius: 5px 5px 0px 0px;
              width: 100%;
              height: 80px;
              justify-content: center;
              .first-line {
                display: flex;
                width: 90%;
                align-items: center;
                .first-row {
                  // margin-top: 15px;
                  display: flex;
                  // flex-direction: column;
                  align-items: flex-end;
                  .content {
                    display: flex;
                    flex-direction: column;
                  }
                  img {
                    margin-right: 10px;
                    margin-bottom: 15px;
                  }
                  p {
                    margin: 0;
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 13px;
                    letter-spacing: -0.25px;
                    color: #979797;
                  }
                  h4 {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 14px;
                    letter-spacing: -0.25px;
                    color: #212529;
                    margin-top: 5px;
                  }
                }
                .second-row {
                  // margin-top: 15px;
                  margin-left: 44px;
                  display: flex;
                  align-items: flex-end;
                  flex-direction: column;
                  .content {
                    display: flex;
                    align-items: center;
                  }
                  img {
                    margin-right: 10px;
                  }
                  p {
                    margin: 0;
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 13px;
                    letter-spacing: -0.25px;
                    color: #979797;
                  }
                  h4 {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 14px;
                    letter-spacing: -0.25px;
                    color: #212529;
                    margin-top: 5px;
                  }
                }
                img {
                  width: 20px;
                  height: 20px;
                }
              }
              .second-line {
                width: 90%;
                position: relative;
                // width: -webkit-fill-available;
                .packet-content {
                  display: flex;
                  align-items: center;
                  img {
                    margin-right: 10px;
                  }
                }
                p {
                  margin: 0;
                  font-weight: 400;
                  font-size: 11px;
                  line-height: 13px;
                  letter-spacing: -0.25px;
                  color: #979797;
                }
                .table {
                  display: flex;
                  margin-top: 5px;
                  width: 102%;
                  flex-flow: row wrap;
                  height: 50px;
                  overflow: hidden;
                  justify-content: center;

                  h4 {
                    padding-left: 6px;
                    margin: 0;
                    margin-top: 10px;
                    font-weight: 500;
                    font-size: 11px;
                    line-height: 13px;
                    letter-spacing: -0.25px;
                    color: #4c4d52;
                    margin-left: 5px;
                    width: 97px;
                    height: 21px;
                    background: #f1f7fe;
                    border-radius: 2px;
                    display: flex;
                    align-items: center;
                    justify-self: center;
                  }
                }
                .count {
                  position: absolute !important;
                  bottom: 15px;
                  left: 215px;
                  font-weight: 500;
                  font-size: 11px;
                  line-height: 13px;
                  letter-spacing: -0.25px;
                  color: #4c4d52;
                }
              }
            }
            .table-footer {
              background: #f8f3ed;
              border-radius: 0 0px 4px 4px;
              height: 36px;
              display: flex;
              align-items: center;
              img {
                margin: 0 10px 0 10px;
              }
              p {
                margin: 0;
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;
                letter-spacing: -0.25px;
                color: #212529;
              }
            }
          }
        }
      }
    }
    .space {
      height: 12vh;
    }
    // .submit-button {
    //   display: flex;
    //   justify-content: flex-end;
    //   width: 100%;
    //   top: 92%;
    //   margin-bottom: 0;

    //   .ant-btn-default {
    //     width: 164px;
    //     height: 35px;
    //     background: #2e77d0;
    //     border-radius: 4px;
    //     font-weight: 700;
    //     font-size: 12px;
    //     line-height: 16px;
    //     letter-spacing: 1px;
    //     text-transform: uppercase;
    //     color: #ffffff;
    //     margin: 20px;
    //     display: flex;
    //     justify-self: center;
    //     cursor: unset;
    //     p {
    //       margin-bottom: 0;
    //       margin: auto;
    //       width: 80px;
    //       display: flex;
    //       justify-content: space-around;
    //     }
    //   }
    // }
    .load-box {
      position: absolute;
      bottom: 20px;
      right: 20px;
      .loaded {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 164px;
        height: 35px;
        left: 558px;
        top: 652px;
        background: #2e77d0;
        border-radius: 4px;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #ffffff;
      }
      p {
        width: 85px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 0;
      }
    }
    .load-button {
      height: 110px;
    }
  }
  .right {
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(107, 107, 107, 0.25);
    border-radius: 5px;
    width: 49%;
    // height: 72vh;
    .space {
      height: 12vh;
    }
    .ant-btn[disabled] {
      background: transparent;
    }
    .header-two {
      align-items: center;
      height: 56px;
      border-bottom: 1px solid #dddddd;
      .ant-col {
        margin-left: 10px;
      }
      h4 {
        margin-bottom: 0;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #061624;
      }
    }
    .truck-row {
      height: 59vh;
      overflow: scroll;
      // border: 1px solid;
    }
    .ant-radio-group {
      width: 100%;
      .ant-form-vertical {
        width: 100%;
      }
      .truck-one {
        background: #ffffff;
        border: 1px solid #e9e9e9;
        border-radius: 4px;
        margin: auto;
        width: 95%;
        margin-top: 20px;
        .ant-select.ant-select-in-form-item {
          width: auto;
          .ant-select-selector {
            width: 115px;
            height: 30px;
            background: #f7f7f7;
            border-radius: 4px;
            border: none !important;
            margin-top: 15px;
          }
          .ant-select-arrow {
            margin-top: 2px;
          }
        }

        .middle-heading-right {
          width: 100%;
          padding: 20px;
          padding-top: 10px;
          flex-direction: column;
          display: flex;
          .input-edit {
            input {
              width: 115px;
              height: 30px;
              background: #f7f7f7;
              border-radius: 4px;
              border: none !important;
            }

            input:focus {
              outline: none !important;
            }
          }
          .truck-one-first-heading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;

            p {
              margin-bottom: 0;
            }

            input {
              border: none;
            }
            .ant-radio-wrapper {
              margin-right: 9px !important;
            }
          }
          .ant-row {
            .ant-form-vertical {
              .ant-form-item-label {
                padding: 0;
                margin-bottom: -5px;
                .ant-form-item-no-colon {
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 24px;
                  color: #b0b0b0;
                }
              }

              .ant-form-item-control {
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: #061624;
              }
            }
          }
          p {
            font-weight: 700;
            font-size: 14px;
            line-height: 24px;
            color: #707485;
          }
          .ant-btn-default {
            margin-left: 10px;
            border: none;
            padding: 0;
          }
        }
        .middle-heading-right-edit {
          width: 100%;
          min-height: 54vh;
          padding: 20px;
          padding-top: 10px;
          flex-direction: column;
          display: flex;
          .input-edit {
            input {
              margin-top: 15px;
              width: 115px;
              height: 30px;
              background: #f7f7f7;
              border-radius: 4px;
              border: none !important;
            }

            input:focus {
              outline: none !important;
            }
          }
          .truck-one-first-heading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            .edit-pen {
              border: none;
            }
            p {
              margin-bottom: 0;
            }

            input {
              border: none;
            }
            .ant-radio-wrapper {
              margin-right: 9px !important;
            }
          }
          .ant-row {
            .ant-form-item {
              margin-bottom: 21px;
            }
            .ant-form-item-label {
              padding-bottom: 0;
              .ant-form-item-no-colon {
                padding: 0;
                margin-bottom: -5px;
                font-weight: 500;
                font-size: 12px !important;
                line-height: 24px;
                color: #b0b0b0 !important;
              }
            }
            .ant-form-item-control {
              .ant-form-item-control-input-content {
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: #061624;
                margin-top: -6px;
              }
            }
            .ant-form-vertical {
              .ant-form-item-label {
                padding: 0;
                margin-bottom: -5px;
                font-weight: 500;
                font-size: 12px;
                line-height: 24px;
                color: #b0b0b0;
                .ant-form-item-no-colon {
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 24px;
                  color: #b0b0b0;
                }
              }

              .ant-form-item-control {
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: #061624;
              }
            }
          }
          p {
            font-weight: 700;
            font-size: 14px;
            line-height: 24px;
            color: #707485;
          }
          .ant-btn-default {
            margin-left: 10px;
            padding: 0;
          }
        }
      }
      .truck-img {
        width: 220px;
        position: relative;
        margin-left: auto;
        margin-top: 17px;
        .ant-progress {
          width: 90%;
          position: absolute;
          top: 2px;
          left: 48px;
          .ant-progress-outer {
            .ant-progress-inner {
              border-bottom: 1px solid #dce1f4;
              border-radius: 0%;
              .ant-progress-bg {
                height: 62px !important;
                border-radius: 0%;
                background: #f99e4c;
              }
            }
          }
          .ant-progress-text {
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
            position: absolute;
            bottom: 63px;
            left: 121px;
            color: #f18420;
            text-align: right;
          }
        }
      }

      .truck-button {
        padding: 20px;
        padding-bottom: 0;
        border-top: 1px solid #dddddd;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .back-button {
          border: 3px solid;
          @include BackButton(100px, 12px, 700);
          cursor: pointer;
          height: 35px;
          background-color: #ffffff;
        }
        .next-button {
          @include NextButton(100px, 12px, $white-color, 700);
          margin-left: 10px;
          cursor: pointer;
          height: 35px;
        }
      }
    }

    .footer-button {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      .skip-btn{
        margin: 20px 0 20px 0;
        width: 85px;
        height: 35px;
        border: #2e77d0;
        background: #2e77d0;
        border-radius: 4px;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .locked {
        margin: 20px;
        width: 174px;
        height: 35px;
        border: 1px solid #57ad13;
        border-radius: 4px;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #57ad13;
        img {
          margin-right: 7px;
        }
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .load-to-vehicle {
        margin: 20px;
        width: 174px;
        height: 35px;
        border: #2e77d0;
        background: #2e77d0;
        border-radius: 4px;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .ready-lock {
        margin: 20px;
        width: 174px;
        height: 35px;
        border: 1px solid #2e77d0;
        border-radius: 4px;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        /* or 133% */
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #2e77d0;
        img {
          margin-right: 9px;
        }
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .hide {
        display: none !important;
      }
      .ant-btn[disabled],
      .ant-btn[disabled]:hover,
      .ant-btn[disabled]:focus,
      .ant-btn[disabled]:active {
        color: rgba(0, 0, 0, 0.25) !important;
        border-color: #d9d9d9 !important;
        background: #f5f5f5 !important;
        text-shadow: none;
        box-shadow: none;
      }
    }
  }
}

.truck-minicard-container {
  display: flex;
  justify-content: flex-end;
  width: 48%;
  height: 188px;
  background: #5cc988;
  border-radius: 4px;
  padding: 1px 1px 1px 0;
  margin-top: 20px;
  .minicard-main-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 97%;
    background: #ffffff;
    border-radius: 4px;
    .first-container {
      display: flex;
      justify-content: space-between;
      padding: 16px 10px;
      border-radius: 4px;
      .left-container {
        display: flex;
        img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
        .lower-box {
          p {
            margin-bottom: 0;
            font-weight: 400;
            font-size: 11px;
            line-height: 13px;
            letter-spacing: -0.25px;
            color: #979797;
          }
          .awb-no {
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: -0.25px;
            color: #212529;
          }
        }
      }
    }
    .right-container {
      display: flex;
      padding-right: 44px;
      img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
      .lower-box {
        p {
          margin-bottom: 0;
          font-weight: 400;
          font-size: 11px;
          line-height: 13px;
          letter-spacing: -0.25px;

          color: #979797;
        }
        .awb-no {
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: -0.25px;

          color: #212529;
        }
      }
    }
    .middle-container {
      padding: 0 10px;
      .right-container {
        display: flex;
        align-items: center;
        img {
          margin-right: 8px;
        }
        p {
          margin-bottom: 0;
          font-weight: 400;
          font-size: 11px;
          line-height: 13px;
          letter-spacing: -0.25px;

          color: #979797;
        }
      }
      .box-cont {
        margin: 10px 0 10px 0;
        display: flex;
        width: 100%;
        .count {
          display: flex;
          align-items: flex-end;
          // margin-left: -20%;
          font-weight: 500;
          font-size: 11px;
          line-height: 13px;
          letter-spacing: -0.25px;
          height: 48px;
          color: #4c4d52;
        }
        .ant-row {
          margin-left: 28px !important;
          margin-right: 0 !important;
          // justify-content: center;
          margin-left: 9px;
          height: 52px;
          overflow: hidden;
          width: 205px;
          .ant-col {
            font-weight: 500;
            font-size: 11px;
            line-height: 13px;
            letter-spacing: -0.25px;
            color: #4c4d52;
            width: 47%;
            height: 21px;
            background: #f1f7fe;
            border-radius: 2px;
            padding-left: 0 !important;
            padding-right: 0 !important;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 5px;
            margin-bottom: 10px;
          }
        }
      }
    }
    .second-container {
      height: 29px;
      border-top: 1px solid #eeeeee;
      background: #f9f9f9;
      border-radius: 0 0 4px 4px;
      overflow: hidden;
      padding-left: 10px;
      .paperpin-container {
        display: flex;
        align-items: center;
        height: 29px;
        img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
        .child-packet-div {
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: -0.25px;
          color: #212529;
        }
      }
    }
  }
}

.truck-bigcard-container {
  display: flex;
  justify-content: flex-end;
  width: 48%;
  height: 188px;
  background: #5cc988;
  border-radius: 4px;
  padding: 1px 1px 1px 0;
  margin-top: 20px;
  .minicard-main-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    width: 97%;
    background: #ffffff;
    border-radius: 4px;
    .first-container {
      display: flex;
      justify-content: space-between;
      padding: 16px 10px;
      background: #ffffff;
      border-radius: 4px;
      .left-container {
        display: flex;
        img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
        .lower-box {
          p {
            margin-bottom: 0;
            font-weight: 400;
            font-size: 11px;
            line-height: 13px;
            letter-spacing: -0.25px;
            color: #979797;
          }
          .awb-no {
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: -0.25px;
            color: #212529;
            margin-top: 3px;
          }
        }
      }
      .right-container {
        display: flex;
        img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
        .surface-icon {
          margin-left: 15px;
          padding-bottom: 5px;
        }
        .lower-box {
          p {
            margin-bottom: 0;
            font-weight: 400;
            font-size: 11px;
            line-height: 13px;
            letter-spacing: -0.25px;

            color: #979797;
          }
          .awb-no {
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: -0.25px;

            color: #212529;
          }
        }
      }
    }
    .middle-container {
      padding: 0 10px;
      .right-container {
        display: flex;
        align-items: center;
        img {
          margin-right: 8px;
        }
        p {
          margin-bottom: 0;
          font-weight: 400;
          font-size: 11px;
          line-height: 13px;
          letter-spacing: -0.25px;

          color: #979797;
        }
      }
      .box-cont {
        margin: 10px 0 10px 0;
        display: flex;
        width: 100%;
        .count {
          display: flex;
          align-items: flex-end;
          // margin-left: -20%;
          font-weight: 500;
          font-size: 11px;
          line-height: 13px;
          letter-spacing: -0.25px;
          height: 48px;
          color: #4c4d52;
        }
        .ant-row {
          margin-left: 28px !important;
          margin-right: 0 !important;
          // justify-content: center;
          margin-left: 9px;
          height: 52px;
          overflow: hidden;
          width: 205px;
          .ant-col {
            font-weight: 500;
            font-size: 11px;
            line-height: 13px;
            letter-spacing: -0.25px;
            color: #4c4d52;
            width: 47%;
            height: 21px;
            background: #f1f7fe;
            border-radius: 2px;
            padding-left: 0 !important;
            padding-right: 0 !important;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 5px;
            margin-bottom: 10px;
          }
        }
      }
    }
    .second-container {
      height: 29px;
      border-top: 1px solid #eeeeee;
      background: #f8f3ed;
      border-radius: 0 0 4px 4px;
    }
  }
}
