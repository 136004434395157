@import "../../commonStyles/media-query.scss";
.booking-summary-edit {
  height: 90vh;
  .payment {
    .frieght-class {
      display: flex;
      width: 100%;
      p {
        width: 50%;
      }
      p:last-child {
        text-align: right;
      }
    }
  }
  .ant-modal-content {
    .ant-modal-body {
      padding: 0;
      .modal-content {
        height: 75vh !important;
        overflow: scroll;
        overflow-x: unset;
        padding: 24px;
        padding-bottom: 0;
        .card {
          .senderGstNumber {
            .ant-form-item-control-input-content {
              margin-left: 10px;
            }
          }
          .third-last {
            height: 0px;
            position: relative;

            .edit-input-field {
              .ant-form-item-control-input-content {
                .senderGstNumber {
                  margin-left: 0;
                }
                .ant-input {
                  position: absolute;
                  width: 78%;
                  top: -5px;
                }
              }
            }
          }
          .sec-last {
            margin: 0 !important;
          }
          .last {
            border-bottom: transparent;
            margin-bottom: 0%;
            margin-top: 0;
          }
        }
      }
    }
  }

  .address {
    padding: 0 !important;
    // .address-edit {
    //   position: unset !important;
    //   margin-right: 10px;
    // }
    .edit-button {
      width: 37%;
    }
    .title {
      display: flex;
      justify-content: space-between;

      .edit-button {
        position: inherit !important;
        margin-right: 10px;
        transition: transform 0.9s;
        margin-bottom: 0;
      }
      .edit-button :hover {
        transform: scale(1.2);
      }
    }

    h4 {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #000000;
      margin-bottom: 0;
    }
    .form-item-hide {
      margin-bottom: 0 !important;
      .ant-row {
        margin: 0 !important;
      }
      .ant-form-item-control-input {
        display: none;
      }
      .ant-form-item-label {
        display: none;
      }
    }

    .highlight {
      margin-bottom: 5px;
      display: none;
    }
    .highlight-receiver {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #000000;
    }
    .hide {
      display: none;
    }

    .ant-input-disabled {
      font-weight: 500;
      font-size: 14px;
      color: #626262;
    }
    .addressDetails {
      border: none;
      background: none;
    }
    .ant-col {
      width: 100%;
      .ant-row {
        width: 100%;
        margin: 20px 0 20px 0;
        .card-one {
          float: right;
        }
        .card {
          // width: 100%;
          min-height: 180px !important;
          margin: 0 !important;
          // margin: 20px 0 20px 0 !important;
          width: 98%;
          .ant-input {
            margin-bottom: 5px;
          }
          @include responsive(small) {
            width: 100%;
          }
        }
      }
    }
  }

  .ant-modal-close-icon {
    background: #f5f8fd;
    border: 1px solid #b0c0dc;
    border-radius: 3px;
    color: black;
    margin-left: -10px;
  }

  #frieght {
    background-color: #fbee78;
    color: black;

    border-radius: 5px 0 0 5px;
  }
  #frieghtValue {
    background-color: #fbee78;
    color: black;
    border-radius: 0 5px 5px 0;
  }

  .ant-modal-header {
    // position: fixed;
    // z-index: 1;
    // width: 65%;
  }
  .ant-modal-body {
    // margin-top: 5%;
  }
  .ant-modal-close-x {
    // position: fixed;
    // left: 78%;
  }

  .service {
    .card {
      .addressDetails-gst {
        padding: 0 !important;
      }
      .ant-input {
        font-weight: 600;
        font-size: 14px;
        color: black;
      }
      .ant-input-disabled {
        border: none;
        background: none;
      }
      .hide {
        display: none;
      }
      .highlight {
        margin-bottom: 5px;
        display: none;
      }
      .lastFormItem {
        display: none;
      }
      .ant-form-item-label {
        color: red;
      }
      .form-item-hide {
        margin-bottom: 0 !important;
        .ant-row {
          margin: 0 !important;
        }
        .ant-form-item-control-input {
          display: none;
        }
        .ant-form-item-label {
          display: none;
        }
      }
    }
  }
  .submit-edit {
    margin: 20px;
    background: #2e77d0;
    border-radius: 4px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff;
  }
  .phone-num-cls {
    .ant-form-item-row {
      flex-flow: unset !important;
      margin: 0 !important;
      align-items: center;
    }
  }
  .edit-input-field {
    margin-bottom: 0px !important;
    //

    .ant-form-item-control-input-content {
      height: 43px;
      display: flex;
      align-items: center;
      // border-bottom: 1px solid #dedede;
      input {
        width: 75%;
      }
    }
    .ant-input {
      width: 78%;
    }
  }
  .button-footer {
    display: flex;
    justify-content: flex-end;
    width: 99%;
    button {
      margin: 20px 20px 20px 0;
    }
    .ant-btn[disabled],
    .ant-btn[disabled]:hover,
    .ant-btn[disabled]:focus,
    .ant-btn[disabled]:active {
      color: rgba(0, 0, 0, 0.25) !important;
      border-color: #d9d9d9;
      background: #f5f5f5 !important;
      text-shadow: none;
      box-shadow: none;
    }
  }
}
