.packeting-guide-form {
  .ant-select-selection-overflow-item {
    width: 100%;
  }

  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(
      .ant-select-customize-input
    ):not(.ant-pagination-size-changer)
    .ant-select-selector {
    background-color: transparent !important;
  }
  .ant-input-status-error {
    background: #f7f7f7 !important;
    .ant-select-selector {
      background: #f7f7f7 !important;
    }
  }
  .cp-list-scroll {
    // border: 1px solid;
    min-height: 40px;
    max-height: 160px;

    .ant-select-selector {
      min-height: 40px;
      max-height: 160px;
      overflow-y: scroll;
      // margin-top: 25px;
      // padding-left: 14px;
      z-index: 1;
    }
  }
  .ant-select-selector {
    width: 100%;
    .ant-select-selection-overflow {
      // background: #f7f7f7;
    }
  }
  .ant-col-12 {
    margin-top: 10px;
  }
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(107, 107, 107, 0.25);
  border-radius: 5px;
  height: 100%;
  .ant-form-item {
    .ant-form-item-control-input-content {
      input {
        border-radius: 5px;
        border: none;
      }
      .ant-select-selector {
        background: #f7f7f7;
        border-radius: 5px;
        border: none;
      }
    }
    .ant-form-item-label {
      font-weight: 400;
      font-size: 14px;
      line-height: 128%;
      color: #061624;
    }
  }
  .input-section {
    padding-top: 60px;
    width: 70%;
    margin: auto;
  }
  .footer-button {
    padding: 16px 5px 16px 0;

    display: flex;
    justify-content: flex-end;
  }
}
// .addpacket-mapping-container {
//   .ant-table-container {
//     min-height: 40px;
//     max-height: 100px;
//     overflow: scroll;
//     border-radius: 5px;
//     // background: #f7f7f7;
//     .ant-table-thead {
//       display: none;
//     }
//     .ant-table-tbody {
//       .ant-table-cell {
//         background: #f7f7f7;
//         padding: 0;
//         border-radius: 5px;
//       }
//     }
//   }
// }

.add-pkt-mapping-form {
  padding: 1rem 10rem;
}
