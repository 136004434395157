body,
#root,
.ant-layout,
.ant-modal-root,
.ant-select,
.ant-modal,


.ant-picker-dropdown {
  font-family: Roboto;
}
.ant-select-options {
  font-family: Roboto;
}
.ck-editor__editable_inline {
  min-height: 200px;
}
.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
  /* border: none !important; */
  background: #F7F7F7;
}
/* .ant-spin-nested-loading > div > .ant-spin {
  max-height: 90vh !important;
} */