.full-width-height {
    height: 100vh;
    width: 100%;
  }

  .container-map {
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    height: calc(100% - 32px);
    margin: 2rem 2rem 0 2rem;
    padding: 0.5rem;
    background-color: #FCFCFC;
  }
  .circle-map{
    height: 15px;
    width: 15px;
    border-radius: 7px;
  }
  

  .no-margin {
    margin: 0;
  }

  .display-flex {
    display: flex;
  }

  .fill {
    flex: 1;
  }

  .center {
    text-align: center;
  }

  .mt16 {
    margin-top: 16px;
  }
  