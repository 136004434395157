.state-master-component{
    background-color: #FFFFFF;
    border-radius: 3px;
    box-shadow: 0px 1px 4px 0px #6B6B6B40;
    .state-master-header{
        border-bottom: 1px solid #DDDDDD;
        .state-header{
            font-weight: 500;
            font-size: 15px;
            line-height: 17px;
        }
    }
    .state-master-container{
        .pob-name{
            border: 1px solid #DDDDDD;
            .pob{
                border: 1px solid #DDDDDD;
                cursor: pointer;
                font-size: 15px;
                font-weight: 400;
                line-height: 17px;
            }
            .pob:hover{
                color: #2E77D0;
                border-right: 4px solid #2E77D0;
                font-weight: 500;
                font-size: 15px;
                line-height: 17px;
            }
            .clicked{
                color: #2E77D0;
                border-right: 4px solid #2E77D0;
                font-weight: 500;
                font-size: 15px;
                line-height: 17px;
            }
        }
        .form-header{
            font-weight: 700;
            font-size: 16px;
            line-height: 32px;
            color: #061624;
            border-bottom: 1px solid #DDDDDD;
        }
    }
    .pob-view-style{
        .ant-descriptions-item-label{
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #838383;
        }
        .ant-descriptions-item-content{
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #202124;
        }
        .ant-descriptions-title{
            font-weight: 500;
            font-size: 13px;
            line-height: 15px;
            color: #838383;
        }
        .pob-address{
            width: 400px;
        }
    }
}

