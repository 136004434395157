  .child-baggage-totalVolume {
    width: 100%;
    height: 51px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    margin-top: 20px;
    color: #061624;
  
    background: #f8f8f8;
    .child-baggage-totalVolume-col {
      padding-left: 10px;
    }
  }