.config-template {
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(107, 107, 107, 0.25);
    border-radius: 5px;
    min-height: 30vh;
}
.price-states-heading {
    color: #202124;

    font-size: 13px;
    font-style: normal;
    font-weight: 400;
}
.clear-btn-restriction {
    border-radius: 4px;
    // padding: 0 1rem;
    font-size: 12px;
    font-weight: 600;
    height: 35px;
    border: 1px solid #2e77d0;
    background: #fff;
    color: #2e77d0;
    max-width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1px;
}

.add-circle {
    width: 25px;
    height: 25px;
    background-color: #2e77d0;
    border-radius: 50%;
    margin-top: 5px;
}

.ant-picker-suffix {
    display: flex;
    flex: none;
    align-self: center;
    margin-left: 4px;
    color: rgba(0, 0, 0, 0.25);
    line-height: 1;
    pointer-events: none;
    padding-right: 7px;
}

.ant-table-filter-column .active {
    width: 18px !important;
    height: 25px !important;
}
