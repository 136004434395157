.hub-container {
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 5px;
}

.view-cage {
    width: 220px;
    margin:1rem;
}

.cage-container {
    background: #F5F5F5;
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    margin-top: 1rem;
    
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    .cage-no {
        background: #FFFFFF;
        border-color: #DDDDDD ;
        border-bottom: 1px solid #DDDDDD;
        padding: 2px;
        color: #2E77D0;
        font-weight: 500;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
       
    }
    .left-box {
        border-right: 1px solid #DDDDDD;
        border-bottom-right-radius: 4px;
        border-top-left-radius: 4px;
    }
    .right-box {
        border-left: 1px solid #DDDDDD;
        border-bottom-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    .count {
        color: #202124;
        font-weight: 500;
        font-size: 13px;
        margin-left: 1rem;
    }
    .count-circle {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #2E77D0;
        color: #fff;
        font-weight: 500;
        font-size: 11px;
    }
    
   
}
.hub-filter {
    padding: 0.5rem 1rem;
    border-bottom:  1px solid #DDDDDD;
    span {
        color: #202124;
        font-weight: 500;
        font-size: 16px;
    }
    div {
        border-radius: 3px;
        padding: 1px 2px;
    }
    div:hover {
        background: #EAEAEA;
        transition: 1s;
    }
}
.destination-title {
    font-weight: 400;
    font-size: 13px;
    color: #061624;
}
.filter-order ul .ant-dropdown-menu-item-selected{
    background: #EDF5FF;
    color: #202124;
}
.check-box-hub {
.ant-checkbox-checked .ant-checkbox-inner {
    background: #2E77D0;
    border-color: #2E77D0;
}
.ant-checkbox-wrapper {
    margin: 0.3rem 0;
}
}
.select {
    .ant-select-selector {
        background: #f7f7f7 !important;
        height: 40px !important;
        font-size: 14px !important;
        border-radius: 5px !important;
        border: 1px solid #f7f7f7 !important;
    }
    
}
.view-sorting-hub {
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    color: #202124;
    text-align: center;
    padding: 0.2rem;
    margin: 1.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}
.hub-show-modal .ant-modal-body {
    padding: 24px 4rem !important;
}
.modal-hub {
    background: #F3F3F3;
    border-radius: 4px;
    padding: 0.5rem 1rem
}
.multi-select-sort .ant-select-selector{
    min-height: 40px !important;
    background: #f7f7f7 !important;
    border-radius: 5px !important;
    border: 1px solid #f7f7f7 !important;
    .ant-select-selection-overflow-item {
        width: 100% !important;
        
        .ant-select-selection-item {
            background: #fff !important;
            border-radius: 5px;
            box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
            display: flex !important;
            justify-content: space-between !important;
        }
    }

}
.view-sort-collapse .ant-collapse-content-box {
    max-height: 300px;
    overflow: scroll;
}
.sorting-table .ant-table-thead > tr > th {
    padding: 10px 16px !important;
}
