@import "../../commonStyles/media-query.scss";

.cp-details-main-container {
  padding: 0.1rem;
  .cp-detail-wrapper {
    padding: 1rem 5rem 0 5rem;
    .cp-details-table {
      overflow-x: scroll;
      .ant-table-thead {
        .ant-table-cell {
          background-color: #d9d9d9;
        }
      }
      .ant-table-tbody {
        background: #f4f4f4;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .cp-detail-wrapper {
      padding: 0;
    }
  }
  .heading-style {
    font-weight: 500;
    line-height: 32px;
    font-size: 20px;
    color: #061624;
  }
}

.dragger-style {
  display: flex;
  border-radius: 5px;
  border-style: dashed;
  border-color: #dfdfdf;
  .ant-upload.ant-upload-drag .ant-upload-btn {
    border-radius: 5px;
  }
}

.kyc-view-wrapper {
  background: #ffffff;
  .kyc-heading {
    border: 1px solid #bfdcff;
    background: #f5faff;
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    line-height: 32px;
    border-radius: 5px;
  }
  .kyc-box-container {
    border: 1px solid #ededed;
    background: #fafafa;
    border-radius: 5px;
    .ant-descriptions-item {
      padding-bottom: 10px;
    }
    .ant-descriptions-item-label {
      color: #333333;
      font-size: 13px;
      font-weight: 400;
      line-height: 15px;
    }
    .ant-descriptions-item-content {
      color: #202124;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
    }
  }
}
