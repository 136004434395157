@import "../../../commonStyles/font.scss";
@import "../../../commonStyles/button.scss";
@import "../../../commonStyles/color.scss";

.dragger-modal {
  .ant-modal-close {
    right: 24px !important;
  }
  .ant-modal-body {
    max-height: 400px;
    overflow: scroll;
    .dragger-heading {
      width: 310px;
      margin: auto;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: 0.01em;
      color: #061624;
      margin-bottom: 9px;
    }

    .ant-upload-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 10px !important;
      justify-content: space-between;
      .ant-upload-list-text-container {
        margin: 12px;
        width: 140px;
      }
    }
    .vline {
      border-left: 1px solid #cecece;
    }
    .ant-upload.ant-upload-drag {
      width: 310px;
      height: 88px;
      background: #f7f7f7;
      border: 2px dashed #dddddd;
      border-radius: 5px;
      margin: auto;
      .ant-col-7 {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .ant-col-16 {
        p {
          width: 185px;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          letter-spacing: -0.3px;

          color: #4c4c4c;
          span {
            font-weight: 500;
            font-size: 13px;
            line-height: 18px;
            letter-spacing: -0.3px;
            text-decoration-line: underline;
            color: #4c4c4c;
          }
        }
      }
    }
    .ant-upload-hint {
      font-weight: 400;
      font-size: 11px;
      line-height: 18px;
      letter-spacing: -0.3px;
      color: #4c4c4c;
      width: 307px;
      margin: auto;
    }
    .ant-upload-list {
      width: 70%;
      margin: auto;
      .ant-tooltip-arrow {
        display: none;
      }
      .ant-tooltip-inner {
        display: none;
      }
      .ant-upload-list-item {
        margin: auto;
        height: 88px;
        max-width: 150px;
        background: #ffffff;
        border: 1px solid #dddddd;
        border-radius: 5px;
        margin-top: 5px;
        margin-left: 10px;
        .ant-upload-text-icon {
          margin-left: 10px;
        }
        .ant-upload-list-item-card-actions {
          margin: 0 0 60px 15px;
        }
        .ant-upload-list-item-name {
          text-align: center;
        }
      }
    }
  }
  .ant-modal-footer {
    padding: 10px 24px;
    .cancelButton {
      @include BackButton(140px, 12px, 700);
    }
    .uploadButton {
      @include NextButton(140px, 12px, $white-color, 700);
    }
    .uploadButtonDisable {
      min-width: 140px;
      height: 40px;
      border-radius: 4px;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 1px;
      height: 40px;
      border-radius: 4px;
      text-transform: uppercase;
    }
  }
}
