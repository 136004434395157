@import "../../commonStyles/font.scss";

.wrapper::-webkit-scrollbar {
  display: block;
  width: 5px;
}
.green {
  color: #288a1f;
  font-weight: 700;
  font-size: 11px;
  line-height: 128%;
  /* identical to box height, or 18px */
}
.orange {
  color: #ff9900;
  font-weight: 700;
  font-size: 11px;
  line-height: 128%;
  /* identical to box height, or 18px */
}
.red {
  color: red;
  font-weight: 700;
  font-size: 11px;
  line-height: 128%;
  /* identical to box height, or 18px */
}

.newRateCart {
  font-family: $default-font-family;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(150, 146, 146, 0.25);
  border-radius: 5px;
  .ant-checkbox-group {
    width: 100%;
  }

  .newRateCart-scrvice {
    width: 100%;
    height: 40px;
    padding: 0 5px;
    border-radius: 5px 5px 0px 0px;
    .newRateCart-scrvice-heading {
      font-weight: 500;
      font-size: 16px;
      line-height: 128%;
      color: #ffffff;
    }
  }
  .blue {
    background: #2e77d0;
  }
  .orange {
    background-color: #f2a128;
  }
  .green {
    background-color: #70c36e;
  }
  .newRateCart-eed {
    width: 100%;
    height: 60px;
    .newRateCart-edd-date {
      width: 150px;
      height: 26px;
      color: #1e8e29;
      background: #e1fcd7;
      border-radius: 33px;
      display: flex;
      justify-content: center;
      align-items: center;
      .newRateCart-edd-label {
        font-weight: 500;
        font-size: 12px;
      }
      .newRateCart-edd-month {
        font-weight: 400;
        font-size: 12px;
      }
    }
  }
  // s
  .newRateCart-base-price {
    padding: 10px 10px 10px 20px;
    row-gap: 20px;
    align-items: center;
    .newRateCart-base-label {
      font-weight: 500;
      font-size: 13px;
      line-height: 128%;
      color: #1c5e98;
    }
    .newRateCart-base-price-value {
      font-weight: 500;
      font-size: 13px;
      line-height: 128%;
      color: #1c5e98;
      &:focus {
        border: 3px solid #000;
        box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
      }
    }
    .newRateCart-base-estimated-label {
      font-weight: 500;
      font-size: 14px;
      line-height: 128%;
      color: #869099;
    }
    .newRateCart-base-estimated-price {
      font-weight: 500;
      font-size: 14px;
      line-height: 128%;
      padding-left: 50px;
      color: #061624;
    }
  }
  .newRateCart-estimated-base-price {
    height: 27px;
    .newRateCart-base-estimated-label {
      font-weight: 500;
      font-size: 14px;
      line-height: 128%;
      padding-left: 20px;
      color: #061624;
    }
    .newRateCart-base-estimated-price {
      font-weight: 500;
      font-size: 14px;
      line-height: 128%;
      padding-right: 26px;

      color: #061624;
    }
  }
  .newRateCart-line {
    border: 1px solid #dddddd;
  }
  .newRateCart-choose-box {
    width: 100%;
    height: 67px;

    background: #f2f2f2;
    border-radius: 5px 5px 0px 0px;
    // transform: matrix(1, 0, 0, -1, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    .newRateCart-choose-box-button {
      width: 170px;
      height: 30px;

      // background: #2e77d0;
      background-color: #ffffff;
      border-radius: 5px;
      &:focus {
        border: 2px solid #ddd;
        box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
      }
      .newRateCart-choose-box-button-text {
        color: #43454a;
        .newRateCart-choose-box-button-text-img {
          margin-right: 5px;
        }
      }
    }
  }
}

.border-blue {
  border: 2px solid #2e77d0;
  border-radius: 5px;
  height: auto;
}
.border-orange {
  border: 2px solid #f2a128;
  border-radius: 5px;
  height: auto;
}
.border-green {
  border: 2px solid #7dbb00;
  border-radius: 5px;
  height: auto;
}
.border-transparent {
  border: 2px solid transparent;
  border-radius: 5px;
  height: auto;
}
.view-weight {
  &:focus {
    border: 2px solid #ddd;
    box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  }
}
.edit-base-price {
  &:focus {
    border: 3px solid #ddd;
    box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  }
}
