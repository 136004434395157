@import "../../commonStyles/color.scss";
@import "../../commonStyles/app.scss";
@import "../../commonStyles/media-query.scss";
@import "../../commonStyles//font.scss";
@import "../../commonStyles/media-query.scss";

.dashboard-container {
  font-family: $default-font-family;
  font-style: $default-font-style;
  width: 95%;
  margin: auto;

  .dashboardHeading {
    padding-top: 30px;
    padding-left: 5px;
    font-family: $default-font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */

    letter-spacing: -0.25px;

    color: #061624;
  }
  .dashboard-container-row {
    margin-top: 20px;
    .dashboard-container-col {
      display: flex;
      flex-direction: column;
      row-gap: 30px;
      .ant-row {
        .cash-booking {
          transition: transform 0.2s;
        }
        .cash-booking:hover {
          transform: scale(1.05);
        }
      }
      .dashboard-container-col-innercol {
        border-radius: 10px;
        padding-bottom: 11px;
        background-color: #ffffff;
        // height: 200px;
        box-shadow: 0px 0px 1px 1px rgba(107, 107, 107, 0.06);
        .ant-radio-button-wrapper:first-child {
          border-left: 1px solid #d9d9d9;
          border-radius: 10px 0 0 10px;
        }
        .ant-radio-button-wrapper:last-child {
          border-radius: 0 10px 10px 0;
        }
        .dashboard-container-col-innercol-trackOrder {
          display: flex;
          margin: 0% 2% 1% 1%;
          color: #061624;
          font-weight: 500;
          font-size: 16px;
          justify-content: space-between;
          @include responsive(small) {
            font-size: 13px;
          }
          .dashboard-container-col-innercol-trackOrder-name {
            padding-left: 5px;
          }
        }
        .dashboard-container-col-div {
          width: 80%;
          flex-direction: column;
          margin: 3% auto 0;
          display: flex;
          row-gap: 20px;
          .dashboard-container-col-radio-grp {
            display: flex;
            column-gap: 10%;
            .dashboard-container-col-radio-grp-radiobtn {
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              color: #061624;
              @include responsive(small) {
                font-size: 12px;
              }
              // .dashboard-container-col-radio-grp-radiobtn-name {
              //   color: #2e77d0;
              // }
            }
          }
          .dashboard-container-col-div-inputbtn {
            // display: flex;
            // column-gap: 10px;
            .dashboard-container-col-div-inputbtn-input {
              height: 40px;
              border: none;
              background: #f7f7f7;
              border-radius: 5px;
            }
            .dashboard-container-col-div-inputbtn-btn {
              width: 120px;
              height: 40px;

              background: #2e77d0;
              border-radius: 4px;
              font-weight: 700;
              font-size: 12px;
              line-height: 16px;

              letter-spacing: 1px;
              text-transform: uppercase;

              color: #ffffff;
            }
          }
        }
      }
    }
  }
  .ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
    padding: 12px 0;
  }
  .dashboard-container-card-div {
    margin-top: 13px;
    width: 100%;
    box-shadow: 0px 0px 1px 1px rgba(107, 107, 107, 0.06);
    border-radius: 10px;
    border-top: 40px;
    border-left: 40px;
    border-right: 40px;
    .dashboard-container-card-div-icon {
      margin-right: 10px;
      margin-bottom: 5px;
    }
    .ant-pagination-disabled .ant-pagination-item-link,
    .ant-pagination-disabled:hover .ant-pagination-item-link {
      border: none !important;
    }
    .ant-pagination-next .ant-pagination-item-link {
      border: none !important;
    }
    .ant-pagination-prev .ant-pagination-item-link {
      border: none !important;
    }

    .announcement-text {
      font-family: $default-font-family;
      font-style: normal;
      font-weight: 550;
      font-size: 18px;
      line-height: 32px;
      // padding: 0 12px;
      /* identical to box height, or 178% */

      letter-spacing: -0.25px;

      color: #061624;
    }
  }
  .ant-card-small > .ant-card-head {
    min-height: 36px;
    padding: 0 13px;
    font-size: 14px;
    background: $sky-blue;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .dashboard-container-card-div-messageIcon {
    margin: 0px 10px 0px 15px;
  }
  .listTitle {
    font-family: $default-font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    color: #282525;
  }
  .listDiscription {
    margin-left: 3.5rem;
    font-weight: 400;
    font-size: 12px;
  }
  .shipmentStatusContainer {
    // width: 20%;
    height: 90px;
    // background-color: #fff7d9;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // border: 1px solid #ffe9bb;
    .textNumber {
      // color: #c59f0c;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .custom-carousel {
    position: relative;
    // width: 90%; /* Set your desired width here */
    margin: 0 auto; /* Center the carousel horizontally */
  }
  .ant-carousel .slick-dots li button {
    position: relative;
    display: block;
    width: 100%;
    height: 3px;
    padding: 0;
    color: transparent;
    font-size: 0;
    background: #1e34d6;
    border: 0;
    border-radius: 1px;
    outline: none;
    cursor: pointer;
    opacity: 0.3;
    transition: all 0.5s;
  }
  .ant-carousel .slick-dots li.slick-active button {
    background: #170101;
    opacity: 1;
  }
  .ant-carousel .slick-next::before {
    content: ">";
    display: none;
  }
  .ant-carousel .slick-prev::before {
    content: "<";
    display: none;
  }
}
.autoAdjust {
  max-width: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 1rem;
}
