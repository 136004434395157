@import "../../../commonStyles/color.scss";
@import "../../../commonStyles/font.scss";

@import "../../../commonStyles/media-query.scss";

.forgot-container {
  font-family: $default-font-family;
  font-style: $default-font-style;
  background: white;

  .img-parent {
    background-color: #ebf1fa;
  }
  .img-div {
    position: relative;
    background-image: url("../../../assests//images/SVG/forgotBg.svg");
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    background-position: 50%;
    background-size: contain;
    margin-right: 89px;

    .img-login-text {
      position: absolute;
      width: 100%;
      height: 31px;
      font-weight: 700;
      font-size: 24px;
      line-height: 128%;
      color: #2f2483;
    }

    img {
      height: 100vh;
    }
  }

  .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
    position: absolute;
    top: 70%;
    left: 50%;
    margin: -10px;
  }

  .right-container {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
    overflow: scroll;
  }

  .form-div {
    // margin-top: 7rem;
    padding: 0 30px;
    // display: flex;
    // justify-content: center;
    // background-color: white;
    // width: 100%;

    .confirm-password {
      text-align: center;
      // width: 85%;
      // margin: auto;
      .first-div {
        margin: 30px 0;
        margin-bottom: 80px;
      }
      button {
        width: 70%;
      }
    }

    .new-password {
      padding: 0;
      margin-top: 30px;
    }

    .ant-form-item-explain-error {
      max-width: 450px;
      margin: 2px 0;
    }
  }

  .inner-content {
    // margin-left: 50px;
    max-width: 410px;
  }
  .first-div {
    margin-bottom: 50px;
  }

  .wlc-div {
    font-weight: 700;
    font-size: 24px;
    color: #061624;
    @include responsive(small) {
      font-size: 18px;
      margin-top: 15px;
    }
  }

  .msg {
    font-size: 15px;
    color: $login-text-color;
    font-weight: 400;
  }

  .error {
    color: $red-color;
    margin-top: 10px;
  }

  .email-div {
    font-size: 14px;
    font-weight: 400;
    font-size: small;
    margin-top: 22px;
    // line-height: 2px;
    margin-bottom: 3px;
    color: #061624;
    p {
      font-size: 14px;
      margin: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }
  }

  .input-style {
    border-radius: 5px;
    padding: 10px;
    // background: #F7F7F7;
    background: rgba(247, 247, 247, 1);

    .ant-input {
      background: rgba(247, 247, 247, 1);
    }
  }

  .footer {
    display: flex;
    justify-content: center;
  }

  .chkbox-color {
    color: $login-text-color;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #061624;

    .forgot {
      /* identical to box height, or 20px */
      // padding-top: 20px;
      color: #2e77d0;
    }
  }

  .formChildElements {
    display: flex;
    justify-content: space-between;
  }

  .loginBtn {
    background: $blue-color;
    border-radius: 4px;
    margin-top: 35px;

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 128%;
      text-transform: uppercase;
      color: #ffffff;
      // font-family: $default-font-family;
    }
  }

  .footer-content {
    position: relative;
    // bottom: 0;
    top: 110px;
    padding: 20px 0;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #868686;
    // margin-top: 20%;
  }
}

.misMatchError {
  color: red;
}
