$white-color: #ffffff;
$black-color: #000000;
$color-gry: #d6d6d6;
$primary-color: #146fc2;
$save-btn-color: #034985;
$title-color: #093577;
$blue-color: #2e77d0;
$dark-blue-color: #061624;
$login-text-color: #8c8b8c;
$red-color: #ff4d4f;
$error-border-red: #df4949;
$sky-blue: #c8e6fc;
$disabled-grey: #717171;
$grey-backgroud-color: #f7f7f7;
$dark-grey-color: #36373C;
