.print-drs-container2 {
    border: 1px solid #dddddd;
    width: 80%;
    margin: auto;
    margin-top: 2rem;
    background: #ffffff;
    border-radius: 5px;
    .header {
        width: 100%;
        margin: "auto";
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #dddddd;
    }

    .ant-btn {
        border: 1px solid #2e77d0;
        border-radius: 5px;
        width: 90px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #2e77d0;
        img {
            margin-right: 5px;
        }
    }

    .main-div {
        margin: auto;
        // width: 80%;
        border: 1px solid #dddddd;
        background: #ffffff;
        .heading {
            background-color: #f5f8fd;
            border: 1px solid #c8dffb;
            border-radius: 5px;
        }
        .ant-table {
            overflow-x: scroll;
            margin-top: 30px;
            .ant-table-thead {
                background: #eeeeee;
                .ant-table-cell {
                    border-top: 1px solid #c2c1c1;
                    border-bottom: 1px solid #c2c1c1;
                    color: #212529;
                }
            }
        }
    }
}
