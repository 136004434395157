@import "../../../commonStyles/media-query.scss";

.lcr-inscan-container .ant-table-filter-column .active {
  width: 18px !important;
  height: 25px !important;
}

.consolidated-manifest-cntr {
  .select-type {
    .ant-select-selector {
      height: 40px;
    }
  }
}

.ant-table-filter-dropdown {
  border-radius: 10px;
  padding: 10px 28px 3px 15px;
}

.lcr-inscan-container {
  .ant-table {
    th:last-child {
      padding: 16px 35px 16px 16px;
    }
    .ant-table-thead .ant-table-cell {
      white-space: normal;
    }
  }
}

.consolidated-tab-cntr {
  .ant-tabs-nav {
    margin: 0 !important;
  }

  padding: 50px;

  @include responsive(small) {
    padding: 1rem;
  }
  .ant-table-filter-column .active {
    width: 18px !important;
    height: 25px !important;
  }
}
.cp-wise-manifest-cntr {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(107, 107, 107, 0.25);
  border-radius: 5px;
  overflow-x: scroll;

  .ant-btn {
    padding: 0;
    border: none;
    background: none;
  }
  .header {
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: -0.25px;
    color: #061624;
    padding: 1rem;
    border-bottom: 1px solid #dddddd;
  }
  .ant-tabs-tab {
    // width: 50px;
    overflow: hidden;
    background: #fafafa;
    border: 1px solid #ebebeb;
    border-radius: 8px 8px 0px 0px;
    .ant-tabs-tab-btn {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #202124;
    }
  }
  .cp-tab {
    margin-top: 20px;

    .ant-tabs-tab {
      border-radius: 10px 10px 0 0 !important;
      margin-right: 5px;
      margin-left: 20px;
    }
  }
  .ant-table-filter-column {
    .active {
      position: relative;
      display: flex;
      align-items: center;
      margin: -4px -8px -4px 4px;
      padding: 0 4px;
      color: #bfbfbf;
      font-size: 12px;
      border-radius: 2px;
      cursor: pointer;
      transition: all 0.3s;
    }
  }
}
.consolidated-manifest-cntr {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(107, 107, 107, 0.25);
  border-radius: 5px;
  overflow-x: scroll;

  .ant-picker {
    padding: 8px;
    border-radius: 5px;
  }
  .header {
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: -0.25px;
    color: #061624;
    padding: 1rem;
    border-bottom: 1px solid #dddddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .ant-btn {
        margin-left: 10px;
        display: flex;
        align-items: center;
        background: #ffffff;
        border: 1px solid #2e77d0;
        border-radius: 4px;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #2e77d0;

        img {
          margin-right: 5px;
        }
      }
    }
  }
}

.print-consolidated-manifest {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(107, 107, 107, 0.25);
  border-radius: 5px;
  padding-bottom: 2rem;
  .heading-lcr {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #061624;
    border-bottom: 1px solid #dddddd;
  }
  .lcr-report-wrapper {
    .download-btn {
      display: flex;
      justify-content: right;
      align-items: center;
      border: none;
      width: 70%;
      margin: auto;
      padding: 30px 0;
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 91px;
        height: 30px;
        background: #ffffff;
        border: 1px solid #2e77d0;
        border-radius: 4px;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #2e77d0;
        img {
          margin-right: 5px;
        }
      }
    }
    .lcr-report {
      margin: auto;
      width: 70%;
      height: auto;
      background: #ffffff;
      border: 1px solid #dddddd;

      .table-lable {
        margin-top: 10px;
        margin-bottom: 5px;
      }

      .ant-table-wrapper {
        border-radius: 0%;
        th {
          border-top: 1px solid #dddddd;
          border-bottom: 1px solid #dddddd;
        }
      }
    }
  }
}
