.status {
  width: 100px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 3px;
  color: rgba(255, 255, 255, 0.94);

  background: #f2c114;
}
.inprogress {
  background: #f2c114;
}
.pending {
  background: #f2c114;
}
.rejected {
  background: #f27474;
}
.approve {
  background: #5fd455;
}
