@import "./color.scss";

@mixin NormalButton($width, $bg-clr, $txt-clr, $font-size, $font-weight) {
  width: fit-content;
  min-width: $width;
  padding: 0 25px;
  background: $white-color;
  box-sizing: border-box;
  border-radius: 40px;
  color: $black-color;
  height: 50px;
  border: none;
  font-size: $font-size;
  font-weight: $font-weight;
  margin: 10px 10px 0px 0px;
}

@mixin important {
  color: red;
  font-size: 25px;
  font-weight: bold;
  border: 1px solid blue;
}

.danger {
  @include important;
  background-color: green;
}

@mixin BackButton($width, $font-size, $font-weight) {
  min-width: $width;
  height: 40px;
  border-radius: 4px;
  border: 1px solid $blue-color;
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $blue-color;
}

@mixin NextButton($width, $font-size, $color, $font-weight) {
  min-width: $width;
  height: 40px;
  border-radius: 4px;
  border: 1px solid $blue-color;
  color: $color;
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: 16px;
  letter-spacing: 1px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid $blue-color;
  text-transform: uppercase;
  background: $blue-color;
}

// @include NormalButton(100px, $button, $white-color, 14px, 700, 5px);
