@import "../../../commonStyles//media-query.scss";
.audit-tab-container {
    margin-top: -1.5rem;

    .actions-col {
        .pointer {
            margin: 10px auto 0 auto;
        }
    }

    .ant-tabs-nav {
        margin: 20px 30px 0 30px !important;
    }
    .audit-input-container {
        padding: 2rem 12rem 0 12rem;
    }
    .accpickupPincode {
        background: #f7f7f7;
        border: 1px solid #f7f7f7;
        border-radius: 5px !important;
    }
    .accpickupPincodeDisable {
        background-color: #e6e6e6 !important;
        border-color: #e6e6e6 !important;
        border-radius: 5px !important;
    }

    .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(
            .ant-input-affix-wrapper-borderless
        ).ant-input-affix-wrapper,
    .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(
            .ant-input-affix-wrapper-borderless
        ).ant-input-affix-wrapper:hover {
        background: #f7f7f7;
        border-color: #ff4d4f;
    }
}

.dateTime {
    background: #f4f6f9;
    border-radius: 4px;
    // width: 162px;
    height: 34px;
    padding: 10px;
    margin: 10px;
}

.master-main-container-key {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.25px;

    color: #202124;
}

.master-main-container-value {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.25px;

    color: #202124;
}
.small-screen-width {
    width: auto;
}

.indepentedInput {
    width: 100%;
    min-height: 40px;
    background: #f7f7f7;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 128%;
    color: #211f1f;
    border: 1px solid #f7f7f7;
    margin-top: 5px;
}

@include responsive(small) {
    .small-screen-width {
        width: 100%;
    }
    .cancel-btn {
        border-radius: 4px;
        padding: 0 1rem;
        font-size: 12px;
        font-weight: 600;
        height: 35px;
        border: 1px solid #2e77d0;
        background: #fff;
        color: #2e77d0;
        min-width: 34% !important;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 1px;
    }
}
.parent-heading {
    font-weight: 600;
}
.accpickupPincodeParent {
}
