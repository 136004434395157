@import "../../../commonStyles/font.scss";
@import "../../../commonStyles/media-query.scss";
.ant-tooltip {
  @include responsive(tablet) {
    display: none;
  }
}
.ant-table-thead .ant-table-cell {
  background-color: #f3f6f9;
  font-family: $default-font-family;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  text-decoration: none !important;
  letter-spacing: -0.25px;

  color: #4a4b4d;
}
.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background: none;
}
.ant-table-tbody {
  .ant-table-cell {
    .vbooking-icons {
      transition: transform 0.2s;
      margin-bottom: 0;
    }
    .vbooking-icons:hover {
      transform: scale(1.2);
    }
  }
}
.viewBooking {
  font-family: $default-font-family;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  /* identical to box height, or 200% */
  letter-spacing: -0.25px;
  color: #061624;
}
.table-container {
  padding: 25px 20px;
  margin: 0px 40px 40px 40px;
  background-color: white;
  box-shadow: 0px 1px 4px rgb(107, 107, 107, 0.25);
  border-radius: 2px;
  .row-one {
    padding: 0 4px;
  }
}

.label {
  font-weight: 700;
  font-size: 15px;
}

.filter-container {
  margin: 5px 0 30px 0;
  flex-wrap: nowrap;
  // margin-left: 0 !important;

  .ant-col {
    // min-width: 100px;
    div {
      // margin-top: 7px;
      span {
        font-weight: 700;
        font-size: 13px;
        margin-left: 7px;
      }

      .span-1 {
        margin: 4px;
      }
    }
    // padding: 10px 0;
    // margin: 0 5px;

    img {
      width: 24px;
    }
  }

  .standard {
    background: #ffffff;
    border: 1px solid #77b6f0;
    box-shadow: inset 0px 0px 11px #a9c6e1;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: 2.5rem;
  }

  .fast-track {
    background: #ffffff;
    border: 1px solid #f8d47a;
    box-shadow: inset 0px 0px 11px #f1deac;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: 2.5rem;
  }

  .stand-fast {
    background: #ffffff;
    border: 1px solid #c5e1b0;
    box-shadow: inset 0px 0px 11px #c3e0c3;
    border-radius: 4px;
    padding: 15px 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 62%;
    width: 10%;
    height: 2.5rem;

    // @include responsive(tablet) {
    //   width: 59%;
    // }
  }

  .select-type {
    width: 25%;
  }

  .search-box {
    width: -webkit-fill-available;
    img {
      width: 14px !important;
    }
  }

  .ant-picker {
    border-radius: 5px;
  }
  .ant-select {
    width: 75%;
    // width: -webkit-fill-available;
    .ant-select-selector {
      position: relative !important;
      // background-color: white !important;
      // border-radius: 5px !important;
      padding-top: 5px !important;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      height: 40px !important;
      // width: 50%;

      // min-width: 115px !important;
      border-radius: 5px;
      border: 1px solid #e4e4e4;
    }
  }
}

.ant-pagination {
  // justify-content: center;
  font-family: Roboto;

  .ant-pagination-item {
    a {
      color: black;
      font-weight: 500;
    }
  }

  .ant-pagination-item-active {
    background-color: #1a70b8;

    a {
      color: white;
    }
  }

  .ant-pagination-prev {
    border: 1px solid #dddddd;
    border-radius: 4px;
    padding: 0 15px;

    a {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: black;
    }
  }
  .ant-pagination-next {
    border: 1px solid #dddddd;
    border-radius: 4px;
    padding: 0 15px;

    a {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: black;
    }
  }
}

// .ant-picker {
//   .ant-picker-input {
//     position: relative !important;
//     background-color: white !important;
//     border-radius: 5px !important;
//     padding-top: 5px !important;
//     transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
//     height: 30px !important;
//     width: 180px !important;
//   }
// }

.ant-input-search {
  .ant-input-group {
  }
  input {
    width: 400px !important;
    background: #ffffff;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    padding: 8px;
  }
}

.filterLabel {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #1a70b8;
  margin-top: 13px;
}
.ant-input-search-button {
  height: 40px;
}

.view-table {
  .awbNumber {
    text-decoration: underline;
  }
  .ant-pagination-options {
    display: none;
  }
}

.stat-green {
  color: #52bd70;
}
.blue {
  color: #4390ef;
}
.darkBlue {
  color: #0b5ba5;
}
.purple {
  color: #972ebc;
}
.yellow {
  color: #f19f00;
}

.generate-manifest {
  text-align: end;
  .ant-btn-primary[disabled] {
    background-color: #8d8d8d;
    color: white;
    font-weight: 700;
    font-size: 12px;
  }
}

.addPacketBtn {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: white;
  height: 38px;
  border-radius: 5px;
  background: #2e77d0;
}

.addPacketBtn:hover {
  background: #2e77d0;
}

.table-container {
  .ant-picker {
    padding: 8px;
    border-radius: 5px;
  }
  .filter-select {
    .ant-select-selector {
      height: 39px !important;
    }
  }
}
