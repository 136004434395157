.credit-child-drawer {
  .ant-drawer-header-title {
    font-weight: 600;
    margin-left: 20px;
    flex-direction: row-reverse;
  }

  .ant-drawer-body {
    padding: 15px 10px !important;
  }

  .child-drawer-container {
    // background: red;
    // background-color: red;
    .ant-row {
      justify-content: space-between;
      padding: 0 10px;
    }
    .ant-table-thead .ant-table-cell {
      font-size: 12px;
    }
    .ant-form-item-label > label {
      font-size: 12px;
    }
    .global-form .global-form-row Input {
      font-size: 12px;
      min-height: 35px;
      background: white;
      // border: 1px solid #919191;
    }
    .global-form .global-form-row .ant-form-item-explain-error {
      font-size: 12px;
    }
    .ant-space {
      margin: 0 2px;
    }
  }

  .child-save-btn {
    border-radius: 4px;
    margin-top: 14px;
    font-size: 12px;
    font-weight: 600;
    background: #2e77d0;
    color: #fff;
    border: 1px solid #2e77d0;
    &:hover {
      background: #2e77d0 !important;
      color: #fff !important;
    }
    &:focus {
      background: #2e77d0 !important;
      border: 2px solid #ddd;

      color: #fff !important;
      box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
    }
  }
  .child-cancel-btn {
    border-radius: 4px;
    margin-top: 14px;
    font-size: 12px;
    border: 1px solid #2e77d0;
    background: #fff;
    color: #2e77d0;
    &:hover {
      border: 2px solid #ddd;
      box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
    }
    &:focus {
      border: 2px solid #ddd;
      box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
    }
  }
}
