.action-column {
    display: flex;
    align-items: center;
    gap: 6px;
  
    .action-icon {
        font-size: 20px;
        transition: color 0.3s, opacity 0.3s;
        
        &.active {
          color: #0886ed;
          cursor: pointer;
        }
      
        &.disabled {
          color: #9e9e9e;
          cursor: not-allowed;
          opacity: 0.5;
          pointer-events: none;
        }
      }
  
    .confirm-popover {
      .ant-popover-inner-content {
        text-align: center;
      }
    }
  }