@import "../../../../commonStyles/font.scss";
@import "../../../../commonStyles/color.scss";
@import "../../../../commonStyles/button.scss";

.retail-booking-success {
  margin: 30px 0;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .add-btn:focus {
    border: 2px solid #ddd !important;
    box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  }

  .done-btn:focus {
    border: 2px solid #ddd !important;
    color: #fff !important;
    box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  }

  .success-container {
    padding: 20px 20px;
    width: 40%;
    height: auto;
    // max-height: 400px;
    background: $white-color;
    box-shadow: 0px 1px 4px rgba(107, 107, 107, 0.25);
    border-radius: 2px;

    .content {
      //   display: flex;
      //   justify-content: center;
      text-align: center;
      .order-id {
        width: 240px;
        height: 36px;
        background: #e8fbf0;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 13px;
        line-height: 30px;
        /* identical to box height, or 231% */

        text-align: center;

        color: #293642;
        span {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
        }
      }
      img {
        width: 50%;
        height: auto;
      }
      .booking_invoice_details {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        text-align: left;
        img {
          width: 16px;
          height: 16px;
        }
        p {
          font-weight: 400;
          font-size: 13px;
          line-height: 30px;
          /* identical to box height, or 231% */
          text-align: center;
          color: #293642;
          align-items: center;
          justify-content: center;
          margin-bottom: 0;
        }
      }
      .invoice-details {
        margin-top: 0;
      }
      .deactivate {
        color: #e85555;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        text-align: left;
        img {
          width: 16px;
          height: 16px;
        }
        p {
          font-weight: 400;
          font-size: 13px;
          line-height: 30px;
          /* identical to box height, or 231% */

          text-align: center;

          color: #e85555;
          align-items: center;
          justify-content: center;
          margin-bottom: 0;
        }
      }
      .btn-cont {
        margin-top: 30px;

        .back-button {
          @include BackButton(140px, 12px, 700);
        }

        .done {
          @include NextButton(140px, 12px, $white-color, 700);
          margin-left: 15px;
        }
      }
      .message {
        margin-top: 30px;
        font-family: $default-font-family;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
      }
    }
  }
}
