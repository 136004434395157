.tracking-container {
    .tracking-container-step {
        width: 100%;
        // height: 913px;

        background: #ffffff;
        box-shadow: 0px 1px 4px rgba(107, 107, 107, 0.25);
        border-radius: 5px;
        .head {
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            /* identical to box height */

            color: #202124;
        }
        .subHead {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;

            color: #868687;
        }

        .title {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            /* identical to box height */

            color: #202124;
        }

        .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
            margin-top: -10px;
            width: 2px;
            height: 196%;
        }
    }
    .heading-line {
        width: 100%;
        height: 0px;
        left: 104px;
        top: 175px;
        border: 1px solid #dddddd;
    }
    .tracking-container-step-name {
        font-weight: 500;
        font-size: 13px;
        line-height: 32px;
        /* identical to box height, or 246% */

        letter-spacing: -0.25px;

        color: #000000;
        padding: 8px 18px;
    }
    .tracking-container-step-num {
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
        // color: rgba(0, 0, 0, 0.85);
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        /* identical to box height */

        color: #202124;
    }
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
        background-color: #20b74c;
    }
    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
        background: #20b74c;
    }
    .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
        background: #20b74c;
    }
}
