.alert-class {
  text-align: center;
  // align-items: center;
  // justify-content: center;
  // padding: 8rem;
  .alert-class .ant-modal-close {
    display: none !important;
  }
}
.alert-message {
  margin-bottom: 2.5rem;
  font-weight: 500;
  font-size: 16px;
}

.alert-image {
  padding: 2.5rem;
}
