.vehicleContainer {
    width: 100%;
    background-color: #fff;
    height: 20%;
    border-radius: 5px;
    padding: 20px;
    .vehicleNumberHeader {
        color: #475467;
        font-weight: 600;
        font-size: 18px;
    }
    .vehicleNumber {
        color: #000000;
        font-weight: 600;
        font-size: 20px;
    }
    .vehicleNumberStatus {
        // border: 1px solid red;
        padding: 8px 20px;
        border-radius: 20px;
        background-color: #219653;
        color: white;
        font-size: 10px;
        margin-left: 10px;
        // margin-bottom: 2px;
    }
    .vehicleContainerLabel {
        font-weight: 400;
        font-size: 14px;
        color: #475467;
    }
    .vehicleContainerValue {
        font-weight: 500;
        font-size: 14px;
        color: #000000;
    }
}
.container {
    .ant-collapse-header {
        background: #ffffff !important;
        padding: 10px 16px !important;
    }
    .ant-collapse-content > .ant-collapse-content-box {
        padding: 0px;
    }
}
.vehicleNumberStatusRecived {
    // border: 1px solid red;
    padding: 5px 15px;
    border-radius: 20px;
    background-color: #c5f3d9;
    font-size: 10px;
    margin-left: 10px;
    // margin-bottom: 2px;
}

.vehicleContainerLabel {
    font-weight: 400;
    font-size: 14px;
    color: #475467;
}
.vehicleContainerValue {
    font-weight: 500;
    font-size: 14px;
    color: #475467;
}
.container {
    background-color: white;
    margin: 30px;
    padding-bottom: 0;
}
.divContaner {
    max-width: 1200px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(215px, 2fr));
    column-gap: 1rem;
    // row-gap: 10px;
}
// .lcrContainer {
//     background-color: white;
//     margin: 30px;
// }
// lcrContainer
// .truncated-text {
//     width: 200px; /* Adjust the width according to your need */
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
// }
