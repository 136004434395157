@import "../../../commonStyles/button.scss";
@import "../../../commonStyles/media-query.scss";

.pincode-container {
  .ant-popover-inner-content {
    width: 20px;
  }

  .pincode-table-container {
    padding: 25px 20px;
    background-color: white;
    box-shadow: 0px 1px 4px rgb(107, 107, 107, 0.25);
    border-radius: 2px;

    .ant-typography {
      margin-bottom: 0;
    }

    .filter-container {
      img {
        width: 18px;
      }

      span {
        margin: 0 8px;
      }
    }

    .col-actions {
      text-align: center !important;
    }

    .row-one {
      padding: 0 4px;
    }

    .add-pincode-btn {
      button {
        @include NextButton(140px, 12px, $white-color, 700);
      }
    }
    .pincode-download-btn {
      button {
        @include BackButton(90, 12px, 700);
      }
    }

    .upload-btn {
      text-align: center;
      button {
        @include BackButton(90, 12px, 700);
        // max-width: 100px;
      }
    }

    .print-btn {
      button {
        @include BackButton(100, 12px, 700);
        max-width: 100px;
      }
    }
  }
  .disabled-row {
    background-color: #f4f4f4;
    .ant-table-cell {
      .ant-typography {
        color: #b9b9b9 !important;
      }
      color: #b9b9b9 !important;
    }
    .ant-table-cell-fix-right,
    .ant-table-cell-fix-left {
      background-color: #f4f4f4;
    }

    .ant-table-cell-row-hover {
      background-color: #f4f4f4 !important;
    }

    svg {
      cursor: not-allowed;
    }
  }

  //DRAWER STYle
}

//master modal style
.pincode-master-modal {
  .ant-modal-body {
    height: 70vh;
    overflow: scroll;
  }
  .area-list-box {
    max-height: 140px;
    overflow: scroll;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    border: 1px solid #dddddd;
    border-radius: 3px;
    display: flex;
    flex-wrap: wrap;

    .area-list-item {
      padding: 10px 13px;
      background: #ecf3fb;

      margin: 5px;
      border-radius: 4px;
      // margin: 0 10px;
    }
  }

  .tag-items {
    .ant-select-selector {
      overflow: scroll;
      // min-height: 40px;
      max-height: 100px;
    }
  }

  .ant-modal-body {
    form {
      padding: 20px 30px;
    }
  }
}

// .ant-popover-content {
//   width: 80%;
// }
.area-list-box {
  max-height: 140px;
  overflow: scroll;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  border: 1px solid #dddddd;
  border-radius: 3px;
  display: flex;
  flex-wrap: wrap;

  .area-list-item {
    padding: 10px 13px;
    background: #ecf3fb;

    margin: 5px;
    border-radius: 4px;
    // margin: 0 10px;
  }
}

.upload-pincode-drawer {
  .ant-drawer-title {
    text-align: center;
    font-size: 20px !important;
  }
  .ant-drawer-header {
    padding: 16px 5px !important;
    box-shadow: 0px 3px 20px -4px rgba(0, 0, 0, 0.13);
  }
  .ant-drawer-header-title {
    font-weight: 600;
    flex-direction: row-reverse;
    padding: 10px 0;
  }

  .ant-drawer-body {
    padding: 2.5rem !important;
    .upload-btn {
      @include NextButton(140px, 12px, $white-color, 700);
      margin-bottom: 20px;
    }
    .content-box {
      background: #f9f9f9;
      border: 1px solid #bdbdbd;
      border-radius: 6px;
      padding: 2rem;
      text-align: center;

      h3 {
        font-weight: 600;
        font-size: 20px;
      }

      .label {
        font-weight: 400;
        font-size: 14px;
        color: #afafaf;
      }
      .sample-link {
        color: #498dd4;
        cursor: pointer;
      }
      .remove-btn {
        border: none;
        background: none;
      }

      .file-name {
        align-items: baseline;
      }
    }
    .footer {
      .btn-blue {
        @include NextButton(130, 12px, $white-color, 700);
        margin-left: 10px;
      }
      .btn-white {
        @include BackButton(130, 12px, 700);
      }
      margin-top: 20px;
      text-align: center;
      margin-left: 10px;
    }
  }
}
