.newcontainer {
    // display: flex;
    // flex-direction: column;
    gap: 10px;
    margin: 20px;
}

.item {
    display: flex;
    // justify-content: space-evenly;
}
.returnTxt {
    font-size: 14px;
    border: 1px solid #f54;
    color: #f54;
    padding: 5px;
    border-radius: 6px;
}
.tooltipClass {
    .ant-tooltip {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: "tnum", "tnum";
        position: absolute;
        z-index: 1070;
        display: block;
        width: -moz-max-content;
        width: 263px;
        width: intrinsic;
        /* max-width: 228px; */
        visibility: visible;
    }
}

.label {
    // font-weight: bold;
    flex-basis: 150px; /* Adjust the width to ensure alignment */
    text-align: left;
    padding-right: 10px;
}

.value {
    flex-grow: 1;
}

.bagInfoContainer {
}
