@mixin responsive($breakpoint) {
  @if $breakpoint == Xlarge {
    @media (min-width: 1400px) {
      @content;
    }
  }
  @if $breakpoint == large {
    @media (max-width: 1320px) {
      @content;
    }
  }
  @if $breakpoint == tablet {
    @media (max-width: 992px) {
      @content;
    }
  }
  @if $breakpoint == medium {
    @media (max-width: 768px) {
      @content;
    }
  }

  @if $breakpoint == small {
    @media (max-width: 480px) {
      @content;
    }
  }
}
