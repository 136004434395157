.eway-drawer-container {
  // background: red;
  // background-color: red;
  .ant-row {
    justify-content: space-between;
    padding: 0 10px;
  }
  .ant-table-thead .ant-table-cell {
    font-size: 12px;
  }
  .ant-form-item-label > label {
    font-size: 12px;
  }
  .ant-table-cell {
    font-size: 12px;
  }
  .global-form .global-form-row Input {
    font-size: 12px;
    min-height: 35px;
    background: white;
    border: 1px solid #919191;
  }
  .global-form .global-form-row .ant-form-item-explain-error {
    font-size: 12px;
  }
  .ant-table-tbody > tr > td {
    padding: 12px 12px;
  }
  .ant-space {
    margin: 0 2px;
  }

  .eway-save-btn {
    border-radius: 4px;
    margin-top: 14px;
    font-size: 12px;
    font-weight: 600;
    background: #2e77d0;
    color: #fff;
    border: 1px solid #2e77d0;
    &:hover {
      background: #2e77d0 !important;
      color: #fff !important;
    }
    &:focus {
      background: #2e77d0 !important;
      border: 2px solid #ddd;

      color: #fff !important;
      box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
    }
  }
  .eway-cancel-btn {
    border-radius: 4px;
    margin-top: 14px;
    font-size: 12px;
    border: 1px solid #2e77d0;
    background: #fff;
    color: #2e77d0;
    &:hover {
      border: 2px solid #ddd;
      box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
    }
    &:focus {
      border: 2px solid #ddd;
      box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
    }
  }
}

.credit-custom-styled-drawer {
  .ant-drawer-header {
    padding: 8px;
  }
  .ant-drawer-header-title {
    font-weight: 600;
    margin-left: 20px;
    flex-direction: row-reverse;
  }

  .ant-drawer-body {
    padding: 8px 8px !important;
  }
}

.custom-top-right-drawer-notification {
  top: -11px !important;
  right: 60px !important;

  width: 100%;
  padding: 3px 10px 10px 10px;
  .ant-notification-notice-with-icon .ant-notification-notice-description {
    margin-left: 35px !important;
  }
  .anticon-check-circle {
    font-size: 19px;
  }
  .ant-notification-notice-description {
    font-size: 13px;
  }
  .ant-notification-notice-close {
    display: none;
  }
}
