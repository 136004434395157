.rto-container {
  background-color: white;
  padding: 1rem 100px 1rem 100px;
  @media (min-width: 700px) {
    padding:1rem;
  }
  @media (min-width: 300px) {
    padding:1rem;
  }
  .modal-content {
    p {
      font-weight: 500;
      font-size: 14px;
      color: #626262;
      margin: 0;
    }
    .highlight {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      margin: 0;
      color: black;
      .ant-form-item-label{
        label{
        font-weight: 500;
        font-size: 14px;
        color: #626262;
        }
      }
    }
    .title {
      width: 100%;
      background: #f5f8fd;
      border: 1px solid #c8dffb;
      border-radius: 4px;
      h4 {
        padding: 10px;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        margin: 0;
      }
    }

    .booking-details {
      padding: 20px;
      .col {
        margin-bottom: 10px;
      }
      .responsive-field{
        @media (min-width: 300px) {
          display: flex;
        }
      }
    }

    .address {
      .card {
        background: #ffffff;
        border: 1px solid #dedede;
        border-radius: 4px;
        padding: 30px 30px;
        min-height: 170px;
        margin: 10px 5px;
        // height: 170px;
      }
    }

    .service,
    .payment {
      .card {
        margin: 20px 0;
        background: #ffffff;
        border: 1px solid #dedede;
        border-radius: 4px;
      }
      p {
        // margin: 10px 0;
        min-height: 43px;
        border: none;
        padding: 10px;
        border-bottom: 1px solid #dedede;
      }
      .value {
        p {
          font-weight: 600;
          font-size: 14px;
          color: black;
        }
      }
    }
  }
  .child-container{
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    .child-lable{
      color: #7E7E7E;
      font-weight:500;
      font-size: 13px;
      line-height: 16px;
    }
  }
}
.ant-modal-footer {
  border-top: 1px solid #dedede;
}
//   }
// }
