
  .blink-soft {
    animation: blinker 1.8s linear infinite;
  }
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
  .vehicle-container{
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(107, 107, 107, 0.25);
    border-radius: 2px;
    .ant-picker-range{
        background: #FAFAFA !important;
        border-radius: 5px !important;
        margin-left: 0.5rem;
    }
  }