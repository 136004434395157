.view-manifest-container {
  margin: 0 40px 40px 40px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(107, 107, 107, 0.25);
  padding: 15px 20px;
  border-radius: 2px;
  .search-div {
    margin: auto;
    width: 90%;
    height: 70px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .ant-input-group-wrapper {
      width: 40% !important;
      .ant-input-group {
        width: 95%;
        .ant-input {
          border-right: none;
          background: #f7f7f7;
          border-color: transparent;
          border-radius: 4px 0 0 4px;
        }
        .ant-input-group-addon {
          .ant-btn {
            border-left: none;
            background: #f7f7f7;
            border-color: transparent;
            border-radius: 0 4px 4px 0;
          }
        }
      }
    }
  }
}
.manifest-table-container {
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    background-color: transparent;
  }
  .ant-table-content{
    // overflow-x: scroll;
  }
}
