.notification-container{
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    .notification-div {
        padding: 0.8rem 1rem 0 1rem;
        border-bottom: 1px solid #DDDDDD;
        .date-color {
            color: #2E77D0;
        }
    }
}


