.cp-eway-container {
  margin: 10px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(107, 107, 107, 0.25);
  border-radius: 2px;
  .ant-input {
    background: #fafafa;
    border-radius: 5px;
    height: 40px;
  }
  .ant-select-selector {
    background: #fafafa !important;
    border-radius: 5px !important;
  }
}
.view-drs-container {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(107, 107, 107, 0.25);
  border-radius: 2px;
}

.ant-popover-content {
  left: -50px;
}
.ant-popover-inner-content {
  width: auto;
}
