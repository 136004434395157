.profile-wrapper{
    .profile-heading{
        margin-bottom: 0;
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        color: #061624;
    }
    .ant-tabs{
        .ant-tabs-nav{
            margin: 5px 30px 0 30px !important;
        }
    }
}