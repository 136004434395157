.service-summary {
    width: 100%;
    margin-top: 20px;
    td {
        background: #ffffff;
        border: 1px solid #dedede;
        // border-radius: 4px;
        border-collapse: collapse;
    }
    .table-data {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        .heading {
            font-weight: 500;
            font-size: 13px;
            color: #6b6b6b;
        }
        .item {
            width: 235px;
            font-weight: 600;
            font-size: 13px;
            color: #061624;
            text-transform: uppercase;
        }
    }
    .top-left {
        border-radius: 4px 0 0 0;
    }
    .bottom-left {
        border-radius: 0 0 0 4px;
    }
    .top-right {
        border-radius: 0 4px 0 0;
    }
    .bottom-right {
        border-radius: 0 0 4px 0;
        div {
            visibility: hidden;
        }
    }
    table {
        display: flex;
    }
    table,
    th,
    tr {
        width: 100%;
    }
}
