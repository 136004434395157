.onboarding-steps-container {
  padding: 54px 26px;

  .ant-steps {
    .ant-steps-item-container {
      height: 4.8rem !important;
    }
    .ant-steps-item-title {
      font-weight: 700;
      margin-top: -4px;
    }
    .ant-steps-item-description {
      color: #444444 !important;
    }

    .ant-steps-item-wait {
      .ant-steps-item-title {
        margin-top: -4px;
      }
      .ant-steps-item-icon {
        color: #939292;
        font-weight: 500;
        background-color: #f0f0f0;
        border: none;
      }
      .ant-steps-item-description {
        color: #939292 !important;
      }
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
      background: #2e77d0;
    }
  }
}

.ant-table-thead {
  white-space: nowrap;
}

.ant-upload-list-item {
  cursor: pointer;
}

.staff-onboard-modal {
  .ant-modal-close {
    right: 14px !important;
  }
}

// .view-all-staff {
//   .ant-popover {
//     background-color: red !important;
//   }
// }
