.order-container {
    background-color: white;
    .filter-input {
        height: 40px;
        width: 100%;
        background: #f7f7f7;
        border-radius: 5px;
        .ant-select-selector,
        Input,
        textarea {
            width: 100%;
            min-height: 100%;
            background: #f7f7f7;
            border-radius: 5px;
            font-weight: 400;
            font-size: 14px;
            line-height: 128%;
            color: #211f1f;
            border: 1px solid #f7f7f7;
        }
    }

    .btn-add-category {
        width: 141px;
        height: 36px;

        background: #2e77d0;
        border-radius: 5px;
        color: #ffffff;
    }

    .order-table-container {
        overflow-x: scroll;
        background-color: white;
        box-shadow: 0px 1px 4px rgb(107, 107, 107, 0.25);
        border-radius: 2px;
    }

    .order-green {
        background-color: #58b510;
        border: 1px solid #58b510;
        border-radius: 5px;
        color: white;
        padding: "5px 0";
    }
    .order-blue {
        background-color: #428ae1;
        border: 1px solid #428ae1;
        border-radius: 5px;
        color: white;
        padding: "5px 0";
    }
    .order-pink {
        background-color: #f57474;
        border: 1px solid #f57474;
        border-radius: 5px;
        color: white;
        padding: "5px 0";
    }
    .order-yellow {
        background-color: #f3b960;
        border: 1px solid #f3b960;
        border-radius: 5px;
        color: white;
        padding: "5px 0";
    }
}
.order-details-modal {
    div {
        background: #fafafa;
        padding: 0.2rem 0.4rem;
    }
    .party-info {
        display: flex;
        flex-direction: column;
    }
}
.order-details-table {
    th {
        background-color: #f3f3f3 !important;
    }
}
