.create-shedule-container {
  padding: 2rem 1rem 0 1rem;
  // background-color: red;
  // margin-top: 50px;
  .ant-form-item-label {
    font-weight: 500;
  }

  .arrival-container {
    label {
      width: 100%;
    }
    .arrival-label-container {
      width: 100% !important;
      justify-content: space-between !important;
    }
  }
  .ant-popover-message-icon {
    display: none !important;
  }

  .app-container {
    display: flex;
    flex-direction: column;
    // align-items: center;
    // padding: 20px;
    .ant-row {
      align-items: center;
    }
  }

  .custom-input {
    width: 120px;
    margin: 10px;
  }
}

// .ant-popover-message-icon {
//   display: none;
// }

.actions-col {
  .pointer {
    margin: 10px auto 0 auto;
  }
}

.ant-tabs-nav {
  margin: 0px 30px 0 30px !important;
}

// svg {
//   cursor: pointer;
// }

.disabled-row {
  background-color: #f4f4f4;
  .ant-table-cell {
    color: #b9b9b9 !important;
  }
  .ant-table-cell-fix-right,
  .ant-table-cell-fix-left {
    background-color: #f4f4f4;
  }

  .ant-table-cell-row-hover {
    background-color: #f4f4f4 !important;
  }

  .disable-edit {
    cursor: not-allowed;
  }
}
