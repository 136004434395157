.master-container {
  padding: 3rem 7rem;
}
.tat-table-fm {
  thead {
    position: sticky;
    top:0;
    z-index: 1;
  }
}
