.profile-container {
    .broadcast-message{
        color: #2E77D0;
        font-size: 13px;
        font-weight: 600;
        cursor: pointer;
        text-transform: capitalize;
    }
    .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
        border: none !important;
    }
}
