.box-no {
    background: #FFFFFF;
    border: 1px solid #B7D4F7;
    border-radius: 4px;
    height: 30px;
    width: 30px;
}
.hub-count-circle {
    background: #68B966;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    color: #fff;
    font-size: 12px;
}
.shipment-show-div { 
    // display: -webkit-box;
    // -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 100%;
    flex-wrap: wrap;
}
.d-hub {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100px;
}
@media only screen and (min-width: 600px) {
    .sort-class {
        height: 65vh;
        overflow-X: scroll;
    }
  }
@media only screen and (min-width: 1000px) {
    .sort-class {
        height: 50vh;
        overflow-X: scroll;
    }

  }
 
@media only screen and (max-width: 600px) {
    .sort-class {
        //width: 135vw;
        overflow-X: scroll;
    }
  }
 .focused-div {
    transform :scale(1.1);
    box-shadow :rgb(48 131 224 / 40%) 0px 0px 0px 2px, rgb(41 114 195 / 65%) 0px 4px 6px -1px, rgb(206 201 201 / 8%) 0px 1px 0px inset;
 }