/* HubToPincodeMapping.css */

/* Container for the entire component */
.hub-pincode-container {
    margin: 20px;
    height: 90%;
  }
  
  /* Flex container for both Add & Mapped Pincodes cards */
  .hub-pincode-wrapper {
    display: flex;
    gap: 20px;
    margin: 20px;
  }
  
  /* Ensure cards take equal width */
  .hub-pincode-card {
    flex: 1;
    min-width: 300px;
    
  }
  
  /* Input & button container */
  .pincode-input-container {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
  }
  
  /* Input field */
  .pincode-input {
    width: 70%;
  }
  
  /* Container for mapped pincodes */
  .pincode-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
  }
  
  /* Individual pincode box */
  .pincode-item {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 10px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    background: #f5f5f5;
  }
  
  /* Delete icon styling */
  .delete-icon {
    color: red;
    cursor: pointer;
  }
  
  /* Empty state text */
  .empty-text {
    color: #999;
  }
  