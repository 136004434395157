.bag-design {
   border: 1px solid #dddddd;
   border-radius: 5px;
   height: 320px;
   margin: 0.2rem;
}
.bagging-div {
   height: 70vh;
   overflow-x: scroll;
}
.bagging-head {
   display: flex;
   justify-content: space-between;
   width: 75%;
}
.delete-img {
   cursor: pointer;
   width: 1.3rem;
   height: 1.3rem;
}
.section-container {
   border: 1px solid #ececf0;
   border-radius: 5px;
   box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.bag-num-input {
   border-radius: 5px;
   height: 40px;
   width: 250px;
   margin-left: 10px;
}
.eligible-content-wrapper {
   border-bottom: 1px solid #dddddd;
   padding: 0.5rem;
}
.eligible-list-wrapper {
   height: 230px;
   overflow: auto;
}
.dark-border-bottom {
   border-bottom: 1px solid #dddddd;
}
.finalise-div {
   position: absolute;
   z-index: 100;
   width: 100%;
   height: 100;
   top: 10%;
}
.bag-barcode-num {
   background: #f7f7f7;
   border-radius: 5px;
   width: 50%;
   margin: 5px;
}
.bag-weight-input {
   background: #f7f7f7;
   border-radius: 5px;
   width: 60%;
}
.bag-search-input {
   border-radius: 5px !important;
   height: 40px !important;
   width: 250px !important;
   margin-left: 10px !important;
}
.notification-type-info {
   width: auto;
   // background-color: #1890ff;
}
.ant-notification {
   box-sizing: border-box;
   margin: 0;
   padding: 0;
   color: rgba(0, 0, 0, 0.85);
   font-size: 14px;
   font-variant: tabular-nums;
   line-height: 1.5715;
   list-style: none;
   font-feature-settings: "tnum", "tnum";
   position: fixed;
   z-index: 1010;
   margin-right: 24px;
   width: auto;
}
@media only screen and (max-width: 600px) {
   .bagging-div {
      height: 80vh;
   }
   .bagging-head {
      display: block;
   }
}
@media only screen and (max-width: 1000px) {
   .bagging-head {
      width: 95%;
      display: block;
   }
}
