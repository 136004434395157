.form-container {
  border: 1px solid #cfcfcf;
  border-radius: 9px;
  padding: 25px;
  margin: 0 15px;
}

.retail-address-container {
  .add-eway-btn {
    color: #2e77d0;
    font-size: 1.17em;
    padding: 0 10px;
    height: 40px;
    border: none;
    border-radius: 8px;
  }
  .add-eway-btn:focus {
    border: 2px solid #2e77d0;
  }
}

.retail-service-container {
  border: 1px solid #cfcfcf;
  border-radius: 9px;
  padding: 30px;
  margin: 0 15px;
  .ant-form-item .ant-form-item-control {
    padding-right: 10px;
  }
}
