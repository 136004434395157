.sidemenu-container {
  position: fixed;
  .hamburger {
    margin: 15px 16px;
    background: transparent;
    border: none;
    box-shadow: none !important;
  }

  .appLogo {
    margin-left: 20px;
  }

  ul {
    background: transparent;
    width: 80px;
  }
  ul {
    margin-top: 10px;
    padding: 0 11px;
    border-bottom: 2px solid rgba(216, 220, 234, 1);

    li {
      margin: 18px 0 !important;
      border-radius: 17px;
      //padding: 6px calc(50% - 16px / 2) !important;
    }

    ::after {
      display: none;
    }
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #d6e2fb;
    border-radius: 17px;
    padding-left: 26px !important;
  }
}
.expanded-side-container .ant-menu-inline.ant-menu-root .ant-menu-item:hover {
  background-color: #dadce0;
  color: #000;
  transition: 1s !important;
}
.sidemenu-container .ant-menu-inline.ant-menu-root .ant-menu-item:hover {
  background-color: #dadce0;
  border-radius: 17px;
  color: #000;
  transition: 1s !important;
}
