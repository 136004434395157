.ant-modal-close {
  right: 28px;
  .ant-modal-close-x {
    width: 24px !important;
    height: 24px !important;
    .ant-modal-close-icon {
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}
