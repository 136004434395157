.filter-input {
    height: 40px;
    width: 60%;
    background: #f7f7f7;
    border-radius: 5px;
    .ant-select-selector,
    Input,
    textarea {
        width: 100%;
        min-height: 100%;
        background: #f7f7f7;
        border-radius: 5px;
        font-weight: 400;
        font-size: 14px;
        line-height: 128%;
        color: #211f1f;
        border: 1px solid #f7f7f7;
    }
}

.reactangle-box {
    // width: 51px;
    // height: 40px;

    padding: 8px 16px;
    background: #2e77d0;
    border-radius: 3px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.25px;

    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flter-drop-down {
    width: 85px;
    height: 40px;
    left: 1226px;
    top: 167px;

    background: #f7f7f7;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cpName {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;

    color: #202124;
}
