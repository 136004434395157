.add-packet {
  padding: 40px;
  ::-webkit-scrollbar-thumb {
    background-color: #d2d2d2;
    height: 10px;
  }

  .container {
    background-color: white;
    font-family: Roboto;
    // height: 580px;
    .content-header {
      font-weight: 700;
      font-size: 16px;
      padding: 18px 30px 0px 30px;
      margin-top: 14px;
      border-bottom: 1px solid #dddddd;

      span {
        background: #f3f9fe;
        border-radius: 7px;
        width: 50px;
        padding: 10px 12px;
        margin-left: 10px;
      }
    }

    .content-body {
      margin-top: 15px;

      .ant-form {
        width: 32%;
      }
      .ant-row-center {
        // justify-content: space-evenly;
      }
      .no-packets {
        // color: red;
        padding: 30px;
        font-weight: 600;
      }
      img {
        margin: 0 5px;
        // width: 20px;
      }
      //   text-align: center;
      .active-cards {
        border: 2px solid #3fd34b !important;
        border-left: 7px solid #51da66 !important;
      }

      .cards {
        max-width: 100%;
        height: 100%;
        margin: 4px;

        // background: #d67171;
        border-radius: 5px;
        padding: 15px;
        border: 2px solid #daa351;
        border-left: 7px solid #daa351;
        // cursor: pointer;
        .ant-row {
          margin: 5px 0;
        }
        .row-one {
          color: #979797;
          font-size: 11px;
          flex-wrap: nowrap;

          img {
            width: 18px;
            align-self: self-start;
          }
        }
        .guide-name {
          min-width: 141px;
        }
        .row-two {
          margin-left: 30px;
          font-size: 12px;
          // width: 165px;
          font-weight: 500;
          .ant-form-item {
            margin-bottom: 5px;
          }
        }
        .ant-input-affix-wrapper {
          border-radius: 4px;
          input {
            border: none;
          }
        }
        input {
          border: 1px solid #dddddd;
          border-radius: 4px;
        }

        .content-table-header {
          background: #f1f7fe;
          color: #4c4d52;
          font-size: 11px;
          font-weight: 500;
        }
        .content-items {
          .awb-col {
            // padding-right: 33px;
            padding-left: 13px;
          }
        }
        .awb-destiny-row {
          max-height: 180px;
          overflow: scroll;
        }
        .awb-col-items {
          padding-left: 8px;
          p {
            font-size: 12px;
            // width: 170px;
            // text-align: center;
            padding: 5px;
          }
        }
        .destination-col-items {
          p {
            padding: 5px 0;
            font-size: 12px;
          }
        }
        .selected-btn {
          justify-content: flex-end;
          button {
            text-align: end;
            border-radius: 4px;
          }
        }
      }
      .close {
        float: right;
        margin-bottom: 12px;
        button {
          background: #2e77d0;
          border-radius: 4px;
          height: 35px;
          font-size: 10px;
          font-weight: 500;
        }
        .ant-btn-primary[disabled],
        .ant-btn-primary[disabled]:hover,
        .ant-btn-primary[disabled]:focus,
        .ant-btn-primary[disabled]:active {
          color: rgba(0, 0, 0, 0.25);
          border-color: #d9d9d9;
          background: #f5f5f5;
          text-shadow: none;
          box-shadow: none;
          height: 35px;
        }
      }
      .submit {
        justify-content: flex-end;
        margin-right: 38px;
        margin-top: 30px;
        padding-bottom: 20px;

        button {
          background: #2e77d0;
          border-radius: 4px;
          height: 35px;
        }
        .ant-btn-primary[disabled],
        .ant-btn-primary[disabled]:hover,
        .ant-btn-primary[disabled]:focus,
        .ant-btn-primary[disabled]:active {
          color: rgba(0, 0, 0, 0.25);
          border-color: #d9d9d9;
          background: #f5f5f5;
          text-shadow: none;
          box-shadow: none;
          height: 35px;
        }
      }
    }
  }
}

//modal
.unscanned-modal {
  .ant-modal-body {
    padding: 85px 60px 40px 60px;
    text-align: center;
  }
  p {
    font-weight: 600;
  }
}
