.cp-lead-gen-container {
  .card {
    border: 1px solid #dddddd;
    border-radius: 10px;
    padding: 20px;
    margin: 15px 0;

    .area-list-box {
      max-height: 140px;
      width: 98%;
      min-height: 40px;
      background: #f7f7f7;

      overflow: scroll;
      border-radius: 5px;
      font-weight: 400;
      font-size: 14px;
      border: 1px solid #dddddd;
      border-radius: 3px;
      display: flex;
      flex-wrap: wrap;

      .area-list-item {
        padding: 10px 13px;
        background: #ecf3fb;

        margin: 5px;
        border-radius: 4px;
        // margin: 0 10px;
      }
    }

    .allArea-box {
      .area-list-item {
        padding: 0px 13px;
        background: #fff0e1;
        color: #e59243;

        margin: 5px;
        border-radius: 4px;
      }

      svg {
        path {
          stroke: #e59243;
        }
      }
    }

    .delivery-box {
      .area-list-item {
        padding: 0px 13px;
        background: #f3fff4;
        color: #149d11;
        margin: 5px;
        border-radius: 4px;
      }
    }
    .ant-select-selection-item {
      background: #f3fff4;
      .ant-select-selection-item-content {
        color: #149d11;
      }
    }

    svg {
      path {
        stroke: #149d11;
      }
    }
  }
}
