.tat-table {
    //border: 1px solid rgb(238, 236, 236) !important;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    th {
        background: #e7f0fe !important;
    }
    thead {
        position: sticky;
        top: 20;
        z-index: 1;
    }
}
.tat-table1 {
    thead {
        position: sticky;
        top: 0;
        z-index: 1;
    }
}