@import "../../../commonStyles/button.scss";
@import "../../../commonStyles/media-query.scss";

.profile-container .profile-details-container .form-inputs {
  margin-top: 10px;
}
.expanded-hub-screen {
  .expanded-hub-wrapper {
    padding: 20px 46px 20px 46px;
    height: 250px;
    overflow: scroll;
    h2 {
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0.03em;
      color: #000000;
      margin-bottom: 1.5em;
    }
    .expanded-hub-header {
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0.03em;
      color: #000000;
      margin-bottom: 10px;
    }
    hr {
      margin-bottom: 25px;
    }
    .expanded-hub {
      .expanded-hub-lable {
        font-weight: 400;
        font-size: 14px;
        line-height: 128%;
        color: #555555;
        margin-bottom: 6px;
      }
      .expanded-hub-value {
        font-weight: 500;
        font-size: 13px;
        line-height: 128%;
        color: #202124;
        margin-bottom: 24px;
        width: 200px;
        word-wrap: break-word;
      }
    }
  }
}
.hub-onboard-buttons {
  display: flex;
}
.gstin-style {
  .ant-input-affix-wrapper {
    background: #f7f7f7 !important;
    border-radius: 5px !important;
    border: none;
  }
}
.download-btn {
  @include BackButton(90, 12px, 700);
}
// .nav-dropdown-overlay {
//   min-width: 200px !important;
//   .ant-dropdown-menu {
//     background-color: #EEF5FF !important;
//     box-shadow: 0px 4px 12px rgba(210, 210, 210, 0.25) !important;
//     border-radius: 5px !important;
//     margin-top: 9px !important;
//   }
//   .ant-dropdown-menu-title-content {
//     padding-left: 5px !important;
//   }
// }
.action-column {
  display: flex !important;
  align-items: center !important;
  gap: 6px !important;

  .action-icon {
    font-size: 20px !important;
    transition: color 0.3s, opacity 0.3s;
    color: #0886ed !important;

    &.active {
      color: #0886ed !important;
      cursor: pointer !important;
    }
  }
  .custom-drawer .ant-drawer-content .ant-drawer-body {
    padding: 0 !important;
}
}