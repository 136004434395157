.modalStyle {
    .modalHeader {
        background: #f1f1f1;
        color: #5e5f61;
        font-size: 13px;
        font-weight: 500;
        line-height: normal;
        padding: 5px;
        margin-bottom: 10px;
        .modalHeaderText {
            padding-left: 8px;
        }
    }
}
.modalcontaner {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        position: relative;
        background-color: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
}
