@import "../../commonStyles//font.scss";

.ant-layout-footer {
  padding: 2px 0 !important;
  color: #868686;
  font-size: 13px;
  background: white !important;
  bottom: 0;
  width: -webkit-fill-available;
  text-align: center;
  border-top: 1px solid #e9e9e9;
  position: sticky;
  bottom: 0;
  z-index: 100;
}

.footer {
  font-family: $default-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #868686;

  p {
    margin-top: 1em;
  }
}
