.new-retail-container {
  .global-form {
    .global-form-row .ant-form-item {
      margin-bottom: 5px !important;
    }
    .paymentMode {
      .ant-form-item-label {
        white-space: nowrap !important;
      }
    }
    .global-form-row .ant-select-selector,
    .global-form-row Input,
    .global-form-row textarea {
      width: 100%;
      min-height: 30px;
      background: white;
      border: 1px solid #919191;
      border-radius: 5px;
      font-weight: 400;
      font-size: 12px;
      line-height: 128%;
      color: #211f1f;
      border-radius: 5px;
    }
    .ant-select-selector {
      min-height: 30px;
    }

    .ant-input-affix-wrapper {
      input {
        border: none;
      }
      background: white;
      border: 1px solid #919191;
    }

    .ant-input-affix-wrapper > .ant-input:not(textarea) {
      background: white;
    }

    .ant-input-status-error:not(.ant-input-disabled):not(
        .ant-input-borderless
      ).ant-input {
      background-color: white !important;
    }

    .ant-select-status-error.ant-select:not(.ant-select-disabled):not(
        .ant-select-customize-input
      ):not(.ant-pagination-size-changer)
      .ant-select-selector {
      background: white !important;
    }
  }

  .ant-form-vertical .ant-form-item-label,
  .ant-col-24.ant-form-item-label,
  .ant-col-xl-24.ant-form-item-label {
    padding: 0px 0 0px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }

  .ant-form-item-label > label {
    position: relative;
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    height: 22px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 12px;
  }

  .global-form .global-form-row .ant-form-item-explain-error {
    font-size: 12px;
    padding: 0 !important;
  }

  .ant-empty-normal {
    margin: 10px;
  }

  .summary-items:last-child {
    border-top: 2px grey dotted;
  }
}

.retail-return-drawer {
  .ant-drawer-content {
    .global-form {
      .global-form-row .ant-form-item {
        margin-bottom: 5px !important;
      }
      .global-form-row .ant-select-selector,
      .global-form-row Input,
      .global-form-row textarea {
        width: 100%;
        min-height: 30px;
        background: transparent;
        border: 1px solid #919191;
        border-radius: 5px;
        font-weight: 400;
        font-size: 12px;
        line-height: 128%;
        color: #211f1f;
        // border: 1px solid #f7f7f7;
        border-radius: 5px;
      }
      .ant-select-selector {
        min-height: 30px;
        input {
          border: none;
        }
      }
      .ant-input-affix-wrapper {
        input {
          border: none;
        }
        background: white;
        border: 1px solid #919191;
      }
    }
    .ant-form-vertical .ant-form-item-label,
    .ant-col-24.ant-form-item-label,
    .ant-col-xl-24.ant-form-item-label {
      padding: 0px 0 0px;
      line-height: 1.5715;
      white-space: initial;
      text-align: left;
    }

    .ant-form-item-label > label {
      position: relative;
      display: inline-flex;
      align-items: center;
      max-width: 100%;
      height: 22px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 12px;
    }

    .global-form .global-form-row .ant-form-item-explain-error {
      font-size: 12px;
      padding: 0 !important;
    }
    .ant-select-show-search.ant-select:not(.ant-select-customize-input)
      .ant-select-selector
      input {
      height: 30px !important;
    }
  }
}
