.ant-table-thead {
  border-top: 1px solid #dddddd;
}
.drs-generation-container {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(107, 107, 107, 0.25);
  border-radius: 2px;
  .ant-input {
    background: #fafafa;
    border-radius: 5px;
  }
  .ant-select-selector {
    background: #fafafa !important;
    border-radius: 5px !important;
    width: 100%;
    min-height: 40px;
    font-weight: 400;
    font-size: 14px;
    line-height: 128%;
    color: #211f1f;
  }
  .ant-table {
    overflow-x: scroll;
  }
}
.view-drs-container {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(107, 107, 107, 0.25);
  border-radius: 2px;
  .ant-table {
    overflow-x: scroll;
  }
  .ant-picker-range {
    background: #fafafa !important;
    border-radius: 5px !important;
    margin-left: 0.5rem;
  }
  .ant-tag-green {
    width: 100px;
    background: #4eab4c;
    border-radius: 3px;
    color: #ffffff;
    text-align: center;
  }
  .ant-tag-volcano {
    width: 100px;
    color: #ffffff;
    text-align: center;
    background: #f66c6c;
    border-radius: 3px;
  }
}
.drs-detial-container {
  .ant-modal {
    border: 1px solid #dddddd;
    border-radius: 5px;
  }
  .ant-tag-green {
    width: 100px;
    background: #4eab4c;
    border-radius: 3px;
    color: #ffffff;
    text-align: center;
  }
  .ant-tag-volcano {
    width: 100px;
    color: #ffffff;
    text-align: center;
    background: #f66c6c;
    border-radius: 3px;
  }
  .ant-table {
    overflow-x: scroll;
  }
  .ant-table-wrapper {
    background: #ffffff;
  }
}
.print-drs-container {
  .ant-btn {
    border: 1px solid #2e77d0;
    border-radius: 5px;
    width: 90px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2e77d0;
    img {
      margin-right: 5px;
    }
  }

  .main-div {
    margin: auto;
    width: 90%;
    border: 1px solid #dddddd;
    background: #ffffff;
    .ant-table {
      overflow-x: scroll;
      margin-top: 30px;
      .ant-table-thead {
        background: #eeeeee;
        .ant-table-cell {
          border-top: 1px solid #c2c1c1;
          border-bottom: 1px solid #c2c1c1;
          color: #212529;
        }
      }
      .ant-table-tbody > tr > td {
        border-bottom: 1.5px solid #9a9999;
        transition: background 0.3s;
      }
    }
  }
}
.receiver-box {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border: 1px solid rgb(215, 221, 215);
  padding: 0.5rem 1.2rem;
  border-radius: 10px;
  margin-top: 1rem;
}
