.onboarding-steps-container {
  padding: 54px 26px;

  .ant-steps {
    .ant-steps-item-container {
      height: 4.8rem !important;
    }
    .ant-steps-item-title {
      font-weight: 700;
      margin-top: -4px;
    }
    .ant-steps-item-description {
      color: #444444 !important;
    }

    .ant-steps-item-wait {
      .ant-steps-item-title {
        margin-top: -4px;
      }
      .ant-steps-item-icon {
        color: #939292;
        font-weight: 500;
        background-color: #f0f0f0;
        border: none;
      }
      .ant-steps-item-description {
        color: #939292 !important;
      }
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
      background: #2e77d0;
    }
  }
  .tag-items {
    .ant-select-selector {
      overflow: scroll;
      // min-height: 40px;
      max-height: 100px;
    }
  }
  .area-list-box {
    max-height: 140px;
    overflow: scroll;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    border: 1px solid #dddddd;
    border-radius: 3px;
    display: flex;
    flex-wrap: wrap;

    .area-list-item {
      padding: 10px 13px;
      background: #ecf3fb;

      margin: 5px;
      border-radius: 4px;
      // margin: 0 10px;
    }
  }
}
.rate-card-container {
  background: #f9f9f9;
  border: 1.5px dashed #dddddd;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
  flex-direction: column;
  margin-top: 0.9rem;
  p {
    margin-bottom: 5px;
    font-size: 1rem;
  }
}
.pin-code-count {
  width: 22px !important;
  height: 22px !important;
  background: #a2cbfd;
  border: 0.5px solid #9cb7d8;
  border-radius: 50%;
  font-weight: 500;
  font-size: 8px;
  line-height: 9px;
  letter-spacing: -0.25px;
  color: #212529;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-left: 4px;
}
.accpickupPincodeParent .accpickupPincode {
  background: #f7f7f7;
  border: 1px solid #f7f7f7;
  border-radius: 5px !important;
}
.pickup-time-style {
  .ant-picker-now-btn {
    color: #2e77d0 !important;
  }
}
