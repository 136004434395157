.map_container {
    min-height: 300px !important;
    overflow: hidden !important;
    margin-top: 20px !important;
  }
  .image_container {
    width: 200px;
    height: 140px;
    border-radius: 10px;
    padding: 0.5rem;
    border: 1px solid #c9c5ca;
    background-color: #fff;
    object-fit: contain;
    text-align: center;
    margin-right: 1rem;
  }