@import "./commonStyles/media-query.scss";
@import "./commonStyles/font.scss";
.ant-layout {
  @include responsive(small) {
    // width: auto !important;
    min-height: 100vh;
  }
}
.element::-webkit-scrollbar {
  width: 0 !important;
}
.ant-layout-header {
  position: sticky;
  top: 0;
  z-index: 100;
  nav {
    //position: sticky;
    padding: 0;
    //width: 97%;
    //z-index: 5;
    height: 75px !important;
    background-color: #dae8fc !important;
    box-shadow: rgb(0 0 0 / 15%) 0px 0px 4px 0px;
    @include responsive(small) {
      width: 100%;
    }
    @include responsive(tablet) {
      width: 100%;
    }
  }
}

@include responsive(tablet) {
  // aside {
  //   display: none;
  // }
}

.ant-layout-content {
  background: #f5f8fd;
}

.ant-layout-sider {
  background-color: #ebf1fa;
  display: inherit;
}

.ant-layout-sider-children {
  background-color: #ebf1fa;
  box-shadow: inset -4px 2px 4px rgb(99 126 165 / 10%);
  .ant-menu.ant-menu-inline-collapsed {
    width: 80px;
  }
}

.ant-btn-lg {
  height: 50px !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #aeaeae;
  border-radius: 20px;
  border: 1px solid transparent;
  background-clip: content-box;
}

.hidden {
  display: none;
}

.ant-drawer-mask {
  background: rgba(0, 0, 0, 0.2);
}

.ant-menu-item-selected {
  color: black;
  background-color: none !important;
  font-weight: 600;
}

////// START: PAYMENT MODAL ////////////
.ant-modal {
  top: 10px !important;
}

.ant-modal-content {
  font-family: $default-font-family;
  font-style: normal;
  .ant-modal-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  }
  .ant-modal-body {
    .modal-content {
      p {
        font-weight: 500;
        font-size: 14px;
        color: #626262;
        margin: 0;
      }
      .highlight {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        margin: 0;
        color: black;
      }
      .title {
        width: 100%;
        background: #f5f8fd;
        border: 1px solid #c8dffb;
        border-radius: 4px;
        h4 {
          padding: 10px;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          margin: 0;
        }
      }

      .booking-details {
        padding: 20px;
        .col {
          margin-bottom: 10px;
        }
      }

      .address {
        .card {
          background: #ffffff;
          border: 1px solid #dedede;
          border-radius: 4px;
          padding: 30px 30px;
          min-height: 170px;
          margin: 10px 5px;
          // height: 170px;
        }
      }

      .service,
      .payment {
        .card {
          margin: 20px 0;
          background: #ffffff;
          border: 1px solid #dedede;

          border-radius: 4px;
        }
        p {
          // margin: 10px 0;
          min-height: 43px;
          border: none;
          padding: 10px;
          border-bottom: 1px solid #dedede;
        }
        .value {
          p {
            font-weight: 600;
            font-size: 14px;
            color: black;
          }
        }
      }
    }
  }
  .ant-modal-footer {
    border-top: 1px solid #dedede;
  }
}

////// END: PAYMENT MODAL ////////////
// .ant-popover-inner-content {
//   text-transform: lowercase;
// }
.value {
  p::first-letter {
    text-transform: capitalize;
  }
}

.ant-tabs-nav {
  margin: 10px !important;

  .ant-tabs-tab-btn {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: -0.25px;
    color: #6d7379;
  }

  .ant-tabs-tab-active {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    .ant-tabs-tab-btn {
      color: #175eb6 !important;
    }
  }
}

// Tooltip
.ant-tooltip {
  margin: -16px 0 !important;
  left: 63px;
}
.ant-menu-inline.ant-menu-root .ant-menu-item {
  justify-content: center;
}
// .alert-class .ant-modal-close {
//   display: none !important;
// }
.alert-class .ant-modal-content {
  margin-top: 20% !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

//master css
.global-input {
  input {
    width: 100%;
    height: 40px;
    background: #f7f7f7;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #211f1f;
    border: 1px solid #f7f7f7;
  }
}

.ant-input-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 0px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  // background: #f7f7f7;
  background-image: none;
  // background: #f7f7f7;
  border-radius: 2px;

  transition: all 0.3s;
  display: inline-flex;
}

.ant-input-affix-wrapper > .ant-input:not(textarea) {
  background: rgb(247, 247, 247);
}

//input drop Down
// .input-drop-down {
//   .ant-select .ant-select-selector {
//     // position: relative !important;
//     background-color: #f7f7f7 !important;
//     border-radius: 5px !important;
//     // padding-top: 5px !important;
//     border-color: #f7f7f7 !important;
//     // transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
//     height: 40px !important;
//     width: 100% !important;
//   }
//   .ant-select:not(.ant-select-customize-input)
//     .ant-select-selector
//     .ant-select-selection-search-input {
//     margin: 4px 0 0 0;
//   }
//   .input-select {
//     // padding-top: 5px;
//     font-size: 13px;
//   }
//   .dropDown {
//     font-weight: 400;
//     font-size: 13px;
//     line-height: 128%;

//     color: #061624;
//   }
//   .ant-select-arrow {
//     // padding-top: 5px;
//   }
// }

// .global-input-disable {
//   color: rgba(0, 0, 0, 0.25);
//   border-color: #d9d9d9;
//   background: #f5f5f5;
//   text-shadow: none;
//   box-shadow: none;
// }
.global-button-disable {
  height: 40px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}
.ant-table-pagination.ant-pagination {
  margin: 16px !important;
}

.ant-select-selector {
  display: flex !important;
  align-items: center !important;
}
.rate-card-select .ant-select-selector {
  border: 1px solid #ebebeb !important;
  border-radius: 5px !important;
  color: #4a4a4b !important;
  font-size: 12px !important;
  width: 120px;
}

.img-disable {
  background-color: gray;
  opacity: 0.8;
  pointer-events: none;
}

//input field
.input-field {
  height: 40px;
  background: #f7f7f7;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.ant-input-affix-wrapper-disabled {
  background: #e6e6e6;
}
.multiple-input-field {
  min-height: 40px;
  background: #f7f7f7;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.master-card-coll :hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  height: 36px;
}

.ant-modal-close-x svg {
  width: 16px !important;
  height: 16px !important;
}

.app-selector {
  .ant-select-selector {
    width: 100% !important;
    height: 40px !important;
    align-items: center !important;
    background: #f7f7f7 !important;
    border: 1px solid #f7f7f7 !important;
    border-radius: 5px !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #211f1f !important;
  }
  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
      .ant-pagination-size-changer
    )
    .ant-select-selector {
    background-color: #f7f7f7 !important;
    border: #ff4d4f !important;
  }

  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
    background-color: #f7f7f7 !important;
    border-color: #f7f7f7 !important ;
  }
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  background-color: #f7f7f7 !important;
}

.booking-details-container .form-inputs .ant-select-selector,
.booking-details-container .form-inputs Input {
  border: 1px solid #f7f7f7;
}
// .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
//   height: 40px !important;
// }

// SPACING AND CONTAINING CSS START

.f-12 {
  font-size: 12px;
}
.f-13 {
  font-size: 13px;
}
.f-14 {
  font-size: 14px;
}
.f-16 {
  font-size: 16px;
}
.f-18 {
  font-size: 18px;
}
.f-20 {
  font-size: 20px;
}
.f-21 {
  font-size: 21px;
}
.f-23 {
  font-size: 23px;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.pointer {
  cursor: pointer;
}
.pointer-disable {
  cursor: no-drop;
}
.m-auto {
  margin: auto;
}

.m-0 {
  margin: 0;
}
.m-05 {
  margin: 0.5rem;
}

.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}
.mt-03 {
  margin-top: 0.3rem;
}

.mt-05 {
  margin-top: 0.5rem;
}

.mt-1 {
  margin-top: 1rem;
}
.mt-1-5 {
  margin-top: 1.5rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mt-3 {
  margin-top: 3rem;
}
.mb-05 {
  margin-bottom: 0.5rem;
}
.ml-1 {
  margin-left: 0.5rem;
}
.ml-05 {
  margin-left: 0.5rem;
}
.ml-1-5 {
  margin-left: 1.5rem;
}
.ml-2 {
  margin-left: 2rem;
}
.ml-3 {
  margin-left: 3rem;
}
.ml-4 {
  margin-left: 4rem;
}
.mr-1 {
  margin-right: 0.5rem;
}
.mr-1-5 {
  margin-right: 1.5rem;
}
.mr-2 {
  margin-right: 2rem;
}
.mr-3 {
  margin-right: 3rem;
}
.mr-4 {
  margin-right: 4rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.mb-1-5 {
  margin-bottom: 1.5rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.mb-3 {
  margin-bottom: 3rem;
}
.p-2-tab {
  padding: 2rem 2rem 0.5rem 2rem;
}
.p-5-box {
  padding: 1rem 5rem 0 5rem;
}
.p-2 {
  padding: 2rem;
}

.p-05 {
  padding: 0.5rem;
}
.pl-05 {
  padding-left: 0.5rem;
}
.p-1 {
  padding: 1rem;
}
.p-1-2 {
  padding: 1.2rem;
}
.p-0-5 {
  padding: 0.5rem;
}
.pt-05 {
  padding-top: 0.5rem;
}
.pt-1 {
  padding-top: 1rem;
}
.pt-1-5 {
  padding-top: 1.5rem;
}
.pt-2 {
  padding-top: 2rem;
}

.pl-1 {
  padding-left: 1rem;
}

.pl-1-5 {
  padding-left: 1.5rem;
}

.pl-2 {
  padding-left: 2rem;
}
.pl-3 {
  padding-left: 3rem;
}
.pr-3 {
  padding-left: 3rem;
}

.pl-4 {
  padding-left: 4rem;
}
.pr-4 {
  padding-right: 4rem;
}
.p-1-5 {
  padding: 1.5rem;
}
.pr-05 {
  padding-right: 0.5rem;
}
.pr-1 {
  padding-right: 1rem;
}
.pr-2 {
  padding-right: 2rem;
}
.pr-3 {
  padding-right: 3rem;
}
.pb-05 {
  padding-bottom: 0.5rem;
}
.pb-1 {
  padding-bottom: 1rem;
}

.d-flex {
  display: flex;
}
.d-block {
  display: block;
}
.d-none {
  display: none;
}

.align-center {
  align-items: center;
  display: flex;
}
.justify-center {
  display: flex;
  justify-content: center;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.flex-start {
  display: flex;
  justify-content: flex-start;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flex-col-gap {
  column-gap: 5px;
}
.row-gap {
  row-gap: 10px;
}
.row-gap3 {
  row-gap: 3px;
}
.col-gap-10px {
  column-gap: 10px;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.space-between-start {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.vertical-space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.space-around {
  display: flex;
  justify-content: space-around;
}
.align-baseline {
  align-items: baseline;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left !important;
}
.pointer {
  cursor: pointer;
}
.title-color {
  color: #061624;
}
.w-10 {
  width: 10%;
}
.w-13 {
  width: 13%;
}
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}
.w-30 {
  width: 30%;
}
.w-40 {
  width: 40%;
}
.w-45 {
  width: 45%;
}
.w-33 {
  width: 33px;
}
.w-50 {
  width: 50%;
}
.w-60 {
  width: 60%;
}
.w-70 {
  width: 70%;
}
.w-80 {
  width: 80%;
}
.w-90 {
  width: 90%;
}
.w-100 {
  width: 100%;
}
.row-gap {
  row-gap: 10px;
}
.color-black {
  color: #000;
}
.color-white {
  color: #fff;
}
.underline {
  text-decoration: underline;
}
.master-title {
  color: #061624;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  span {
    padding-left: 0.5rem;
  }
}
.master-title-head {
  color: #061624;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  align-items: center;
  span {
    padding-left: 0.5rem;
  }
}
// SPACING AND CONTAINING CSS END
//INPUT || SELECT || MULTISLECT STYLE
.input-field-style {
  min-height: 40px;
  background: #f7f7f7;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  .ant-select-selector {
    background-color: inherit !important;
    border: none !important;
    width: 100% !important;
    .ant-select-selection-item {
      background-color: inherit;
    }
  }
  .ant-input {
    background: inherit;
  }
}
//
// MAIN CONTAINER START
.master-main-container {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(107, 107, 107, 0.25);
  border-radius: 5px;
  .ant-steps-item-title {
    font-size: 18px;
  }
}
// MAIN CONTAINER END

// INPUT BOXES START
.global-form {
  .global-form-row .ant-select-selector,
  .global-form-row Input,
  .global-form-row textarea {
    width: 100%;
    min-height: 40px;
    background: #f7f7f7;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 128%;
    color: #211f1f;
    // border: 1px solid #f7f7f7;
    border-radius: 5px;
  }

  // multiple input
  .mul-input .ant-select-selection-item {
    background: #d9d9d9;
    border-radius: 4px;
    justify-content: space-between;
  }
  .mul-input .ant-select-selection-overflow-item {
    width: 100%;
  }
  .cp-list-scroll {
    // border: 1px solid;
    min-height: 40px;
    max-height: 160px;

    .ant-select-selector {
      min-height: 40px;
      max-height: 160px;
      overflow-y: scroll;
      // margin-top: 25px;
      // padding-left: 14px;
      z-index: 1;
    }
  }
  //
  .global-form-row .ant-form-item-explain-error {
    padding: 4px 0px !important;
    font-size: 11px;
  }
  .ant-select-multiple .ant-select-selection-item-content {
    color: #000;
  }
  .global-form-row .ant-picker {
    padding: 0 !important;
    border: 1px solid #f7f7f7 !important;
    width: 100%;
    height: 40px;
    background: #f7f7f7 !important;
    border-radius: 5px !important;
  }
  .global-form-row {
    .ant-picker-disabled {
      background-color: #e6e6e6 !important;

      input {
        background-color: #e6e6e6 !important;
        border-color: #e6e6e6 !important;
      }
    }
  }
  .global-form-row .ant-form-item {
    margin-bottom: 15px !important;
  }
  .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
    border-color: #f7f7f7 !important;
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: black !important;
    background: #e6e6e6 !important;
  }
  .ant-select-selection-overflow-item {
    width: 100% !important;
  }
  .ant-select-selection-item {
    display: flex;
    justify-content: space-between;
  }
  .ant-select-multiple .ant-select-selection-item {
    box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px;
    border-radius: 5px;
    background: #fff;
  }

  //--Date Picker--//

  .ant-picker-input {
    padding: 0 10px !important;
    border-radius: 5px !important;
  }
  .ant-picker-status-error .ant-picker-input {
    border: 1px solid #ff4d4f !important;
  }
  .ant-picker-clear {
    margin-right: 17px !important;
  }
  //--Date Picker End--//
  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
      .ant-pagination-size-changer
    )
    .ant-select-selector {
    background: #f7f7f7 !important;
  }
}
// INPUT BOXES END

// BUTTON START
.btn-style {
  border-radius: 4px;
  padding: 0 1rem;
  font-size: 12px;
  font-weight: 600;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 10%;
}
.save-btn {
  border-radius: 4px;
  padding: 0 1rem;
  font-size: 12px;
  font-weight: 600;
  height: 35px;
  background: #2e77d0;
  color: #fff;
  border: 1px solid #2e77d0;
  min-width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 1px;
  &:hover {
    background: #2e77d0 !important;
    color: #fff !important;
  }
  &:focus {
    background: #2e77d0 !important;
    border: 3px solid #2e77d0;

    color: #fff !important;
    box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  }
}
.save-btn[disabled]:hover {
  color: rgba(0, 0, 0, 0.25) !important;
  border-color: #d9d9d9 !important;
  background: #f5f5f5 !important;
}
.cancel-btn {
  border-radius: 4px;
  padding: 0 1rem;
  font-size: 12px;
  font-weight: 600;
  height: 35px;
  border: 1px solid #2e77d0;
  background: #fff;
  color: #2e77d0;
  min-width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 1px;
}

.divider {
  border-bottom: 1px solid #dedede;
  border-top: none;
}

.disable-color {
  background-color: #e6e6e6 !important;
  color: #656363 !important;
  border-color: #e6e6e6 !important;
}
.disable-popup {
  .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
    pointer-events: none;
    height: 35px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
  }
}
// BUTTON END
.active {
  width: 10px !important;
  height: 10px !important;
  background: transparent !important;
  border-radius: 0px !important;
}

//--Text Transform--//
.upper-case {
  text-transform: uppercase;
}
.lower-case {
  text-transform: lowercase;
}
.capitalize {
  text-transform: capitalize;
}

//Tabs container styling ---//
.global-card-tab-container {
  margin-top: -1.5rem;
  .ant-tabs-nav {
    margin: 5px 15px 0 15px !important;
  }
}

//----//

//SWAROOP's STYLE
.cp-list-scroll {
  // border: 1px solid;
  min-height: 40px;
  max-height: 160px;
  .ant-select-selector {
    min-height: 40px;
    max-height: 160px;
    overflow-y: scroll;
    // margin-top: 25px;
    // padding-left: 14px;
    z-index: 1;
  }
}

.ant-menu-inline {
  border-right: none !important;
}

//onboarding

.global-steps-container {
  padding: 54px 26px;
  background: #f7f9fd;
  border-radius: 5px;
  height: auto;

  .ant-steps {
    .ant-steps-item-container {
      height: 4.8rem !important;
    }
    .ant-steps-item-title {
      font-weight: 700;
      margin-top: -4px;
    }
    .ant-steps-item-description {
      color: #444444 !important;
      font-size: 12px !important;
    }

    .ant-steps-item-wait {
      .ant-steps-item-title {
        margin-top: -4px;
      }
      .ant-steps-item-icon {
        color: #939292;
        font-weight: 500;
        background-color: #f0f0f0;
        border: none;
      }
      .ant-steps-item-description {
        color: #939292 !important;
      }
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
      background: #2e77d0;
    }
  }
}
.ant-popconfirm {
  width: max-content !important;
}
::-webkit-scrollbar-thumb {
  min-height: 25vh;
}
.ant-select-selection-search {
  display: flex;
  align-items: center;
}

.disabled-row {
  background-color: #f4f4f4;
  .ant-table-cell {
    .ant-typography {
      color: #b9b9b9 !important;
    }
    color: #b9b9b9 !important;
  }
  .ant-table-cell-fix-right,
  .ant-table-cell-fix-left {
    background-color: #f4f4f4;
  }

  .ant-table-cell-row-hover {
    background-color: #f4f4f4 !important;
  }

  svg {
    cursor: not-allowed;
  }
}

.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
  margin: 0 !important;
}
.select-over-hide .ant-select-selection-item {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  display: inline-block !important;
}
@media only screen and (max-width: 600px) {
  .master-title-head {
    font-size: 16px;
  }
  .ant-layout-sider {
    display: none;
  }
}

/* Heading Component CSS */
.next-btn {
  align-items: center;
  appearance: button;
  background-color: #1b71dd;
  border-radius: 8px;
  border-style: none;
  box-shadow: rgba(255, 255, 255, 0.26) 0 1px 2px inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: "RM Neue", sans-serif;
  line-height: 1.15;
  margin: 0;
  padding: 10px 15px;
  text-align: center;
  // transition: color .13s ease-in-out,background .13s ease-in-out,opacity .13s ease-in-out,box-shadow .13s ease-in-out;
  // user-select: none;
  // -webkit-user-select: none;
  // touch-action: manipulation;
}

.next-btn:active {
  background-color: #1b71dd;
}

.next-btn:hover {
  background-color: #1b7bf0;
}
/* End */
.ant-tree-node-content-wrapper {
  user-select: auto !important;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background: transparent !important;
}
.table-border thead tr .ant-table-cell {
  border-right: 1px solid #dddddd !important;
  border-bottom: 1px solid #dddddd !important;
}
.table-border tr .ant-table-cell {
  border-right: 1px solid #dddddd !important;
  border-bottom: 1px solid #dddddd !important;
}
.table-border table {
  border: 1px solid #dddddd;
}
.ant-radio-disabled .ant-radio-inner::after {
  background-color: #1890ff !important;
}
.ant-tree .ant-tree-node-content-wrapper {
  cursor: auto !important;
}

.table-head-sticky {
  .ant-table {
    overflow: auto !important;
    height: 60vh !important;
  }
  .ant-table-thead {
    position: sticky !important;
    top: 0 !important;
    z-index: 5 !important;
  }
}
.ant-carousel .slick-dots {
  margin-bottom: -25px;
}

.select-without-form .ant-select-selector {
  width: 260px !important;
  min-height: 40px !important;
  border-radius: 5px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 128% !important;
  color: #211f1f !important;
  border: 1px solid #d9d9d9 !important;
}
.select-without-form .ant-select-selector,
.input-without-form {
  width: 260px !important;
  min-height: 40px !important;
  border-radius: 5px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 128% !important;
  color: #211f1f !important;
  border: 1px solid #d9d9d9 !important;
}

.dragger-style {
  display: flex;
  border-radius: 5px;
  border-style: dashed;
  border-color: #dfdfdf;
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled) .ant-upload-btn {
  border: 2.5px dotted #d9d9d9;
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled) .ant-upload-btn:hover {
  border: 2.5px dotted #1890ff;
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled) .ant-upload-btn:focus {
  border: 2.5px dotted #1890ff;
}

// file lists
.ant-upload-list-picture .ant-upload-list-item:focus-within {
  border: 2px solid #1890ff !important;
}

.ant-upload-list-picture .ant-upload-list-item:hover {
  border: 2px solid #1890ff !important;
}

.ant-upload-list-item-card-actions-btn:focus {
  border: 2px solid #1890ff !important;
}

.view-details-header {
  height: 35px;
  background: #f5faff;
  border: 1px solid #bfdcff;
  border-radius: 5px;
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: -0.25px;
  color: #061624;
  padding-left: 17px;
}
.row {
  display: flex;
  justify-content: space-between;
}
.column {
  // flex: 1;
  padding: 20px;
  /* box-sizing: border-box; */
  // border: 1px solid #ccc;
  // min-width: 18%;
}
.view-details-gride {
  height: auto;
  background: #fafafa;
  border: 1px solid #ededed;
  // padding: 0px 0px 5px 44px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin: 10px 30px;
}
.column-content {
  // text-align: center;
  row-gap: 25px;
  padding: 10px 0 12px 0;
  flex: 1;
  // padding: 10px;
  box-sizing: border-box;
  .view-details-gride-name {
    font-weight: 400;
    font-size: 14px;
    color: #333333;
  }
  .view-details-gride-hub {
    font-weight: 500;
    font-size: 14px;
    line-height: 128%;
    max-width: 200px;
    word-wrap: break-word;
    color: #202124;
  }
}

.CPlead_inprogress {
  color: #e9850f;
  border: 1px solid #e9850f;
}
.CPlead_approved {
  color: #26961c;
  border: 1px solid green;
}
.CPlead_rejected {
  color: red;
  border: 1px solid red;
}
.CPlead_pending {
  color: #2e77d0 !important;
  border: 1px solid #2e77d0;
}

.ant-input[disabled] {
  color: black;
}

.formitem-component .ant-select-selector {
  background-color: #f7f7f7 !important;
  border-radius: 5px !important;
  height: 40px !important;
  //padding-top: 5px !important;
  position: relative !important;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100% !important;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: none;
}
.ant-collapse-header {
  &:focus {
    border: 3px solid #ddd;
    box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  }
}
.span-link {
  color: #007bff;
  cursor: pointer;
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: #f3f6f9;
}

.ant-table-filter-dropdown-btns {
  .ant-btn-link {
    text-transform: uppercase !important;
  }
}

.ant-popover {
  .ant-popover-buttons {
    .ant-btn-sm {
      text-transform: uppercase !important;
    }
  }
}

.exception-alert {
  padding: 10px 25px;
  width: 450px !important;
  h4 {
    font-size: 16px;
  }
  a {
    font-size: 16px;
  }
}

.track-err-msg {
  margin-top: 50px;
  // background-color: red !important;
}
