@import "../../commonStyles/color.scss";
@import "../../commonStyles/font.scss";
@import "../../commonStyles/media-query.scss";

.login-main-container {
  background-color: #ffffff;
  position: relative;

  .img-parent {
    background-color: #ebf1fa;
  }

  .login-parent {
    margin: 0 auto;
    position: relative;
    background-color: #ffffff;
  }
  .img-div {
    position: relative;
    background-image: url("../../assests/images/SVG/forgotBg.svg");
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    background-position: 50%;
    background-size: contain;
    margin-right: 89px;
  }

  // img {
  //   height: 100vh;
  // }

  .logibg-container-text {
    position: absolute;
    top: 35%;
    left: 45%;
    transform: translate(-40%, -40%);
    font-family: $default-font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 128%;
    color: #2f2483;
  }

  @include responsive(small) {
    height: 40vh;
    .img-parent {
      display: none;
    }
    .login-parent {
      // margin: 0 auto;
      // position: relative;
      // background-color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .small-screen-logo {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @include responsive(medium) {
    height: 40vh;
    .small-screen-logo {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .img-parent {
      display: none;
    }
  }

  .logibg-container-bgImage {
    object-fit: cover;
    width: 100%;
    height: 100%;

    @include responsive(medium) {
      height: 0vh;
      display: none;
      background-image: url("../../assests/images/mobileLoginBg.svg");
      width: 200px;
      height: 75px;
      .img-div {
        position: relative;
        background-image: url("../../assests/images/SVG/forgotBg.svg");
        background-repeat: no-repeat;
        background-position: center;
        height: 100vh;
        background-position: 50%;
        background-size: contain;
        margin-right: 0px;
      }
    }
  }
}
.small-screen-logo {
  display: none;
}

.login-container {
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  justify-content: center;
  height: 80vh;
  // @include responsive(medium) {
  //   height: 100vh;
  //   margin: 0;
  //   width: 100%;
  //   // margin-top: 60px;
  //   justify-content: flex-start;
  //   padding-top: 53px;
  // }
  @include responsive(small) {
    height: 100vh;
    margin-bottom: 3vh;
    margin-top: 0px;
    row-gap: 10px;
  }

  @include responsive(medium) {
    height: 100vh;
    margin-bottom: 3vh;
    // margin-top: 60px;
  }

  @include responsive(small) {
    height: 100vh;
    margin-bottom: 3vh;
    row-gap: 10px;
    margin: 0 10%;
  }

  .login-container-headding {
    font-family: $default-font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 128%;
    margin-top: 20%;
    /* identical to box height, or 31px */

    color: #061624;
  }

  .login-container-subheadding {
    font-family: $default-font-family;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
    /* identical to box height, or 21px */

    color: #8c8b8c;
  }

  .login-container-email .login-container-password {
    font-family: $default-font-family;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 128%;
    color: #061624;
  }

  .login-container-email-input {
    height: 40px;
    background: #f7f7f7;
    border-radius: 4px;
  }

  .login-container-remember {
    display: flex;
    justify-content: space-between;

    // .ant-checkbox-inner {
    //   background-color: #f7f7f7;
    //   border-color: #f7f7f7;
    // }

    // .ant-checkbox-checked .ant-checkbox-inner {
    //   background-color: #2e77d0;
    // }

    // .rememberMe {
    //   font-weight: 400;
    //   font-size: 14px;
    //   /* identical to box height */

    //   color: #061624;
    // }
  }

  .login-container-btndiv {
    display: flex;
    width: "100%";

    .login-container-btn {
      background: #2e77d0;
      border-radius: 4px;
      height: 50px;
      display: flex;
      width: 100%;
      text-align: center;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
      font-family: $default-font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 128%;
      // border: 2px solid red;
      /* or 20px */

      text-transform: uppercase;
      border: none;
      color: #ffffff;
    }
  }

  .login-container-forgot {
    font-family: $default-font-family;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */
    cursor: pointer;
    color: #2e77d0;
  }
}

.logisdn-Container {
  font-family: $default-font-family;
  font-style: $default-font-style;
  background: white;

  .img-div {
    // position: relative;
    background: #ebf1fa;

    .img-responsive {
      // .img-login {
      border: 2px solid red;

      @include responsive(small) {
        display: none;
        // }
      }

      @include responsive(medium) {
        display: none;
        // }
      }
    }

    .img-small-screen {
      @include responsive(large) {
        display: none;
      }

      @include responsive(tablet) {
        display: none;
      }

      @include responsive(Xlarge) {
        display: none;
      }

      @include responsive(medium) {
        display: flex;
        justify-content: center;
        align-items: center;
        // width: 390px;
        height: 194px;
        // position: absolute;
        background: #ebf1fa;

        .img-login {
          // width: 179.13px;
          height: 47.62px;
        }
      }

      @include responsive(small) {
        display: flex;
        justify-content: center;
        align-items: center;
        // width: 390px;
        height: 194px;
        // position: absolute;
        background: #ebf1fa;

        .img-login {
          // width: 179.13px;
          height: 47.62px;
        }
      }
    }

    .img-login-text {
      position: absolute;
      width: 100%;
      height: 31px;
      font-weight: 700;
      font-size: 24px;
      line-height: 128%;
      color: #2f2483;
      // border: 5px solid red;
      top: 35%;
      left: 50%;
      transform: translate(-50%, -50%);

      .img-login-text-p {
        display: flex;
        justify-content: center;
        font-weight: 700;
        font-size: 24px;
        line-height: 128%;
        /* identical to box height, or 31px */

        color: #2f2483;
      }
    }

    img {
      height: 100vh;
      border: 2px solid green;
    }
  }

  .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
    position: absolute;
    top: 70%;
    left: 50%;
    margin: -10px;
  }

  .right-container {
    background-color: white;
  }

  .form-div {
    margin-top: 9rem;
    display: flex;
    justify-content: center;
    background-color: white;
    width: 100%;

    @include responsive(medium) {
      margin-top: 2rem;
      display: flex;
      justify-content: center;
      background-color: white;
      width: 100%;
    }
  }

  .first-div {
    margin-bottom: 50px;
  }

  .form {
    // width: 500px;
    @include responsive(small) {
      width: 50%;
    }

    @include responsive(large) {
      width: 500px;
    }

    @include responsive(tablet) {
      width: 400px;
    }

    @include responsive(Xlarge) {
      width: 500px;
    }
  }

  .wlc-div {
    font-weight: 700;
    font-size: 24px;
    // line-height: 128%;
    /* identical to box height, or 31px */

    color: #061624;
  }

  .msg {
    font-size: 15px;
    color: $login-text-color;
    font-weight: 400;
  }

  .error {
    color: $red-color;
    margin-top: 10px;
  }

  .email-div {
    font-size: 14px;
    font-weight: 400;
    font-size: small;
    margin-top: 22px;
    // line-height: 2px;
    margin-bottom: 3px;
    color: #061624;
  }

  .input-style {
    border-radius: 5px;
    padding: 10px;
    // background: #F7F7F7;
    background: rgba(247, 247, 247, 1);

    .ant-input {
      background: rgba(247, 247, 247, 1);
    }
  }

  .futDiv {
    display: flex;
    justify-content: center;
  }

  .chkbox-color {
    color: $login-text-color;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #061624;

    .forgot {
      /* identical to box height, or 20px */
      // padding-top: 20px;
      color: #2e77d0;
    }
  }

  .formChildElements {
    display: flex;
    justify-content: space-between;
  }

  .loginBtn {
    background: $blue-color;
    border-radius: 4px;
  }

  .loginFooter {
    position: fixed;
    bottom: 0;
    padding: 20px 0;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #868686;
  }
}
.login-img {
  height: 70px;
  // width: 110%;
}
@include responsive(tablet) {
  .login-img {
    height: 70px;
    // width: 110%;
  }
}
@include responsive(medium) {
  .login-img {
    height: 100px;
    // width: 110%;
  }
}
.error {
  color: $red-color;
  margin-top: 10px;
}
.login-main-container .ant-spin-nested-loading > div > .ant-spin {
  max-height: 90vh !important;
}
