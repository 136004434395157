@import "../../../commonStyles/color.scss";
@import "../../../commonStyles/font.scss";
@import "../../../commonStyles/media-query.scss";

.check-mail {
  max-width: 475px;
  .openMail {
    font-weight: 500;
    margin-bottom: 0;
  }
}
.form-label {
  margin: 20px 0 100px 0;
  @include responsive(small) {
    margin: 40px 0;
  }
}
