.retail-discount {
  .ant-table-thead {
    .ant-table-cell {
      background: #ebf1fa;
    }
    .text-wrap {
      white-space: normal;
    }
    // white-space: wrap;
  }

  .ant-table-tbody > tr > td {
    padding: 12px 12px;
    overflow-wrap: normal;
  }

  .ant-table-row:last-child {
    //   background-color: red;
    font-weight: 800;
  }
}
