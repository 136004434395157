@import "../../../../commonStyles/color.scss";
.rate-card-table {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(107, 107, 107, 0.25);
  width: 100%;
  border-bottom: 1px solid #E2E4E6;
  th {
    position: sticky;
    top: 0;
    z-index: 100;
    border-right:1px solid #d7d7d9;
    border-bottom:1px solid #d7d7d9 !important;
    background-color: #e9eaec;
    padding: 1rem 0.5rem;
    color: #707485;
    font-weight: 700;
    text-align: left;
    font-size: 14px;
    white-space: nowrap;
    box-shadow: 0px 1px 4px rgba(107, 107, 107, 0.25);
  }
  th td {
    border-bottom:1px solid red !important;
  }
  tbody tr td {
    border: 1px solid #E2E4E6;
    padding: 1rem;
    text-align: center;
  }
  .span-color-tbl {
    color: $dark-grey-color;
    font-weight: 700;
    background-color: #F7F7F7;
    padding: 0.7rem;
    img{
        width: 20px;
        height: 20px;
        margin-right: 0.3rem;
    }
  }
  .td-input {
    background: #F7F7F7;
    border-radius: 5px;
    padding: 0.6rem 1rem;
    border: none;
  }
  .tr-hover:hover, .tr-hover.clicked {
    background-color: #FCFBFB !important;
    transition: 0.5s;
  }
  .tr-animation {
    animation: tranimation 1s;
    transition: 1s;
    td:first-child {
      color: #333333;
      font-weight: 500;
    }
  }
  @keyframes tranimation {
    0%   {background-color: #F7F7F7;}
    50%  {background-color: #FDFCFC;}
    100% {background-color: #fff;}
  }
}
