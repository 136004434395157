.hub-cp-container {
    margin: 20px;
    height: auto;
}

.hub-cp-title {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 10px;
}

/* Two-column layout */
.hub-cp-layout {
    display: flex;
    gap: 10px;
}

/* Left Section: Hub Name & CPs */
.hub-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 60%;
}

/* Right Section: Channel Partner */
.channel-partner-section {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 40%;
}

.cp-card {
    width: 100%;
    margin-bottom: 12px;
    height: 290px; /* Set fixed height */
    overflow-y: auto; /* Enable vertical scrolling */
}
.cp-card .ant-card-head {
    position: sticky;
    top: 0;
    background: white;
    z-index: 10;
    border-bottom: 1px solid #ddd;
    padding: 0px 10px;
}
.cp-list {
    max-height: 150px;
    overflow-y: auto;
    padding-right: 10px;
}
.recent-tags {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.button-group {
    display: flex;
    justify-content: right;
    margin-top: 15px;
    gap: 10px;
}

.comma-separated-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 10px;
    // background-color: #f8f9fa;
    border-radius: 8px;
}

.cp-item {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: #dae8fc !important;;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    flex: 1 1 200px;  // Ensures equal width, min 200px
    max-width: 250px; // Limits max width for better 
    justify-content: space-between; // Ensures text is on the left & icon on the right
}

.cp-label {
    font-weight: bold;
    color: #333;
}

.delete-icon {
    cursor: pointer;
    color: red;
    transition: color 0.2s ease-in-out;

}

.delete-icon:hover {
    color: darkred;
}

.separator {
    margin-left: 5px;
    font-weight: bold;
}

.no-cp {
    font-style: italic;
    color: gray;
}