.sort-to-cp {
  .content-body {
    padding: 20px;
  }
  .card {
    border: 1px solid #ddd;
    background-color: #fffbf4;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1), 0 0 1px 0 rgba(0, 0, 0, 0.1);
    margin-top: 1rem;
    margin: 10px;
    // height: 300px;
  }
  .barcode-list {
    height: 100px;
    overflow: scroll;
    padding-right: 5px;
    align-content: flex-start;
  }
  .title {
    margin-bottom: 10px;
  }
  .sub-title {
    font-weight: 800;
    margin-right: 10px;
  }
  h3 {
    margin: 0;
  }
  .list-container {
    // background: rgb(165, 214, 241);
  }
}
