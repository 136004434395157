@import "../../commonStyles/media-query.scss";

.ant-layout-header {
  padding: 0;
  color: black;
}

.custom-search {
  width: 200px;
  border-radius: 10px; /* Adjust the width as needed */
}

.header {
  // width: -webkit-fill-available;
  width: inherit;
  margin: auto;

  .username {
    .ant-dropdown-button {
      width: 100%;
      display: flex;
      // justify-content: center;
    }
  }
  .ant-row {
    // justify-content: space-around;
    @include responsive(small) {
      .username {
        display: none;
      }
    }
    @include responsive(medium) {
      .username {
        display: none;
      }
    }
    @include responsive(tablet) {
      .username {
        display: none;
      }
    }
  }

  a {
    color: #fff;
    // background: #2e77d0;
    // padding: 6px;
    // border-radius: 14px;
  }
  .bell-icon {
    text-align: center;
    img {
      width: 16px;
    }
  }

  .letter {
    position: relative;
    // max-width: 3%;
    // text-align: center;
    span {
      margin: 4px;
      color: white;
    }
    img {
      position: absolute;
      top: 35px;
      left: 19px;
    }
  }

  .time {
    background: #fbfbfb;
    border: 1px solid #dddddd;
    border-radius: 33px;
    padding: 0.5rem 1rem;
    img {
      width: 12px;
      margin-top: -2px;
      margin-right: 4px;
    }

    span {
      padding: 2px 5px;
      line-height: 0.5;
      // background: #fbfbfb;
      // border: 1px solid #dddddd;
      // border-radius: 33px;
      cursor: default;
      font-weight: 400;
      font-size: 12px;
      color: #353535;
      // pointer-events: none;
    }
  }

  .username {
    font-weight: 400;
    margin: auto 0;
    font-size: 14px;
    color: #061624;

    button {
      background: none;
      padding: 0;
      border: none;
    }

    img {
      width: 12px;
    }
  }
}
.ant-drawer-header-title .ant-btn {
  box-shadow: none;
}
.username .ant-btn {
  box-shadow: none;
}
.nav-dropdown-overlay {
  min-width: 200px !important;
  .ant-dropdown-menu {
    box-shadow: 0px 4px 12px rgba(210, 210, 210, 0.25) !important;
    border-radius: 5px !important;
    margin-top: 9px !important;
  }
  .ant-dropdown-menu-title-content {
    padding-left: 5px !important;
  }
}
.nav-dropdown-overlay-notification {
  min-width: 300px !important;
}
.nav-dropdown-a {
  padding: 10px 1px;
  svg {
    width: 14px !important;
    height: 14px !important;
  }
}

.avatar {
  height: 35px;
  border: 1.65px solid #2e77d0;
  border-radius: 20px;
  margin: 0 10px;
  padding: 0 2px;
  background: white;
  cursor: pointer;
}

.profile1 {
  width: 30px;
  height: 30px;
  background: #2e77d0;
  border-radius: 50%;
  color: #fff;
  font-size: 0.9rem;
  flex-direction: inherit;
  flex-shrink: 0;
  .profile-edit-img1 {
    position: absolute;
    margin-top: 2.2rem;
  }
  .cart-count {
    background: #f55a5a;
    width: 23px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #fff;
    position: absolute;
    margin-top: -1.6rem;
    margin-left: 1.3rem;
    span {
      font-size: 12px;
    }
  }
  .notification-div {
    background: #ffaa06;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;
    margin-top: -2.6rem;
    margin-left: 0.7rem;
  }
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 4px;
  margin-left: 48px;
  font-size: 16px;
  font-weight: 700;
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 48px;
  font-size: 18px;
}

.ant-notification-notice {
  position: relative;
  width: 650px;
  max-width: calc(100vw - 24px * 2);
  margin-bottom: 16px;
  margin-left: auto;
  padding: 16px 24px;
  overflow: hidden;
  line-height: 1.5715;
  word-wrap: break-word;
  background: #fff;
  border-radius: 2px;
  box-shadow:
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.time {
  display: flex;
}
.web-logo {
  display: initial;
}
.mobile-logo {
  display: none;
}
.notifiaction-viewed {
  height: 7px;
  width: 7px;
  border-radius: 50%;
}
@media only screen and (max-width: 700px) {
  .time {
    span {
      font-size: 10px !important;
    }
  }
}
@media only screen and (max-width: 610px) {
  .web-logo {
    display: none;
  }
  .mobile-logo {
    display: initial;
  }
  .time {
    display: none !important;
  }
}
@media only screen and (min-width: 610px) {
  .ham-mobile {
    display: none;
  }
}
